import React, { FunctionComponent } from 'react';
import { ReactComponent as InvalidIcon } from 'assets/svg/generic/validation_error.svg';
import classNames from 'common/utils/classNames';
import { createUUID } from 'utils';
import { HelperText } from 'components/design-system/HelperText';

export type TextAreaProps = {
  label?: string;
  labelHidden?: boolean;
  error?: string | boolean;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea: FunctionComponent<TextAreaProps> = (props: TextAreaProps) => {
  const { label, labelHidden = false, error, ...rest } = props;

  const inputClasses = classNames([
    'w-full border rounded-md focus:ring-0 focus:outline-none py-3',
    !!error
      ? 'border-lena2021-corail-dark focus:border-lena2021-corail-dark text-lena2021-corail-dark'
      : 'border-lena-lightgray2 focus:border-lena-gray',
    rest.className,
  ]);

  const id = `textarea.${createUUID()}`;

  const renderAsterik = () => {
    const text = rest.required ? 'obligatoire' : 'optionnel';

    return (
      <span className="ml-1 font-extrabold">
        {rest.required && <i aria-hidden="true">*</i>}
        <i className="sr-only">({text})</i>
      </span>
    );
  };

  return (
    <div className="w-full">
      {label && !labelHidden && (
        <label className="text-lena2021-blue-dark font-bold mr-6 select-none" htmlFor={id}>
          {label}
          {renderAsterik()}
        </label>
      )}

      <div className={`relative flex justify-center items-center ${label && 'mt-1'}`}>
        <textarea
          {...rest}
          id={id}
          className={inputClasses}
          aria-label={label}
          aria-invalid={Boolean(error)}
          aria-describedby={`helperText.${id}`}
        />

        <div className="absolute inset-y-0 right-2 flex items-center justify-center">
          {error && <InvalidIcon height={12} width={12} className="fill-current text-lena2021-corail-dark" />}
        </div>
      </div>
      {error && (
        <HelperText
          id={id}
          helperText={{
            type: 'error',
            text: error,
          }}
        />
      )}
    </div>
  );
};

export default TextArea;
