import React, { FunctionComponent, ReactNode } from 'react';
import { Listbox } from '@headlessui/react';
import { useAppFlavor } from 'hooks';
import { AppFlavor } from 'contexts/AppFlavorContext';
import appEnvironment from 'config/environment';
import { useLocation } from 'react-router-dom';

function FlavorDropdown() {
  const { flavor, setFlavor } = useAppFlavor();

  return (
    <div className="flex items-center justify-center">
      <div className="w-full max-w-xs mx-auto">
        <Listbox as="div" className="space-y-1" value={flavor} onChange={setFlavor}>
          <div className="relative">
            <Listbox.Button className="relative w-full focus:outline-none">{flavor} ↓</Listbox.Button>
            <Listbox.Options className="bg-lena2021-blue-dark shadow-md rounded-sm divide-y divide-lena-lightgray overflow-auto scroll-thin absolute z-100 border-lena2021-blue-dark">
              {Object.values(AppFlavor).map((v) => (
                <Listbox.Option key={v} value={v}>
                  {({ selected }) => (
                    <div className="text-white cursor-pointer select-none py-1 px-4 hover:bg-lena-lightgray hover:text-lena2021-blue-dark">
                      <span className={`${selected ? 'font-semibold text-lena2021-corail' : 'font-normal'}`}>{v}</span>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
      </div>
    </div>
  );
}

const AppDebugBar: FunctionComponent = () => {
  if (!appEnvironment.REACT_APP_DEBUG_BAR) return null;

  const { defaultFlavor } = useAppFlavor();

  /////////////////////////////////////////////////////////
  // If the route is used by backend, we hide this bar
  // (affects PDF generation)
  const location = useLocation();
  if (location.pathname.includes('/_server/')) return null;
  /////////////////////////////////////////////////////////

  const renderTitle = (title: string) => {
    return <span className="text-lena2021-corail-dark uppercase">{title}</span>;
  };

  const renderItem = (children: ReactNode, title?: string) => {
    if (!children) return null;

    return (
      <div className="flex items-center gap-2 py-1 px-4">
        {title && renderTitle(title)}
        {children}
      </div>
    );
  };

  const { REACT_APP_BRANCH_NAME, REACT_APP_COMMIT_ID, REACT_APP_ENVIRONMENT } = appEnvironment;

  const formatBranchName = () => {
    if (REACT_APP_BRANCH_NAME && REACT_APP_COMMIT_ID) return `${REACT_APP_BRANCH_NAME}/${REACT_APP_COMMIT_ID}`;
    return REACT_APP_BRANCH_NAME || null;
  };

  const renderBranchItem = () => {
    const branchName = formatBranchName();
    if (!branchName) return null;

    return (
      <div className="inline-flex items-center space-x-2">
        <div>{formatBranchName()}</div>
      </div>
    );
  };

  if (defaultFlavor !== AppFlavor.Default) return null;

  return (
    <>
      <div className="fixed w-full top-1 text-white text-xs font-marianne z-100 flex justify-center pointer-events-none">
        <div className="bg-lena2021-blue-dark bg-opacity-90 px-2 rounded-sm  pointer-events-auto">
          <div className="flex items-center divide-x divide-lena-lightgray2">
            {renderItem(renderBranchItem(), 'Branch')}
            {renderItem(`${REACT_APP_ENVIRONMENT}`, 'Env')}
            {renderItem(<FlavorDropdown />, 'Flavor')}
          </div>
        </div>
      </div>
    </>
  );
};

export default AppDebugBar;
