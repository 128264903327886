import React, { Suspense, ComponentType, ComponentProps } from 'react';

export default function <T extends ComponentType<any>>(path: () => Promise<{ default: T }>) {
  const Component = React.lazy(path);

  return function (props: ComponentProps<T>) {
    return (
      <Suspense fallback={<div />}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...props} />
      </Suspense>
    );
  };
}
