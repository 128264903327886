import { ReactComponent as PictoCommunication } from 'assets/svg/modules/selfeval/communication.svg';
import { ReactComponent as PictoOrganisation } from 'assets/svg/modules/selfeval/organisation.svg';
import { ReactComponent as PictoReflexion } from 'assets/svg/modules/selfeval/reflexion.svg';

export const getCompetenceGroup = (name: string) => {
  switch (name) {
    case 'organizational':
      return {
        label: "Je m'organise",
        color: 'bg-[#FFEBE5]',
        picto: PictoOrganisation,
      };
    case 'communication':
      return {
        label: 'Je communique',
        color: 'bg-[#DBF7F2]',
        picto: PictoCommunication,
      };
    case 'reflective':
      return {
        label: 'Je réfléchis',
        color: 'bg-[#DEF2FA]',
        picto: PictoReflexion,
      };
    default:
      return null;
  }
};
