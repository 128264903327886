import React, { useEffect, useState } from 'react';

import classNames from 'common/utils/classNames';

const getEmojiURL = (code: string) => {
  const cp = code.codePointAt(0);
  return 'https://cdn.jsdelivr.net/npm/emoji-datasource-twitter/img/twitter/64/' + cp?.toString(16) + '.png';
};

const ReactionsEmojiWithIntensity = ({ questionId, suggestions, handleClick }: any) => {
  const [selectedEmoji, setSelectedEmoji] = useState(null);

  useEffect(() => {
    setSelectedEmoji(null);
  }, [questionId]);

  return (
    <div>
      <div>
        {suggestions?.map((_: any) => (
          <button
            key={_}
            type="button"
            className={classNames(
              _ === selectedEmoji ? 'opacity-100' : selectedEmoji ? 'opacity-30' : 'opacity-100',
              'text-lg p-1 py-2',
            )}
            onClick={() => setSelectedEmoji(_)}
          >
            <img width={24} alt={_} src={getEmojiURL(_)} />
          </button>
        ))}
      </div>

      {selectedEmoji && (
        <div className="mt-4">
          <p className="text-sm font-bold">À quelle intensité ?</p>

          <div>
            {[...Array(6)].map((x, i) => (
              <button
                key={`${selectedEmoji}_${i}`}
                type="button"
                className="p-1 py-2"
                onClick={() => {
                  handleClick({ answer: [selectedEmoji], intensity: i });

                  setSelectedEmoji(null);
                }}
              >
                <img width={24 + 4 * i} alt={selectedEmoji} src={getEmojiURL(selectedEmoji)} />
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReactionsEmojiWithIntensity;
