import React from 'react';

export const Picture = React.lazy(() => import('./Picture'));
export const Job = React.lazy(() => import('./Job'));
export const Description = React.lazy(() => import('./Description'));
export const General = React.lazy(() => import('./General'));
export const Formations = React.lazy(() => import('./Formations'));
export const Keywords = React.lazy(() => import('./Keywords'));
export const ExperiencePersonal = React.lazy(() => import('./ExperiencePersonal'));
export const ExperiencePro = React.lazy(() => import('./ExperiencePro'));
export const ExperienceSportAmateur = React.lazy(() => import('./ExperienceSportAmateur'));
export const TechnicalSkills = React.lazy(() => import('./TechnicalSkills'));
export const TransversalSkills = React.lazy(() => import('./TransversalSkills'));
export const Informations = React.lazy(() => import('./Informations'));
export const Color = React.lazy(() => import('./Color'));
