import React from 'react';
import { PreviewA4 } from 'containers/ProfileContainer/SkillCardV3/components/editor';
import { Button } from 'components/design-system';
import { ReactComponent as DownloadIcon } from 'assets/svg/generic/download.svg';
import InitializeSelfevalCard from 'containers/SelfevalContainer/components/InitializeSelfevalCard';
import { useGetSelfevalDataForCompetenceCardQuery } from 'containers/SelfevalContainer/requests/GetSelfevalDataForCompetenceCard';
import { useDownloadSelfevalCardMutation } from 'containers/SelfevalContainer/requests/DownloadSelfevalCard';

const PreviewSelfevalCard = () => {
  const [callDownload, downloadState] = useDownloadSelfevalCardMutation();
  const selfevalQuery = useGetSelfevalDataForCompetenceCardQuery({
    variables: { userId: null },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const handleDownload = () => {
    callDownload().then((r) => {
      if (r.data?.downloadSelfeval) {
        const a = document.createElement('a');
        a.href = `data:application/pdf;base64,${r.data.downloadSelfeval}`;
        a.download = `Ma carte de compétences transversales.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    });
  };

  const globalCompetences = selfevalQuery.data?.publicSelfeval.globalCompetences;
  const mySkills = selfevalQuery.data?.publicSelfeval.skills;
  const user = selfevalQuery.data?.publicSelfeval.user;

  if (selfevalQuery.loading || !selfevalQuery.called) return null;

  return (
    <div className="flex flex-col space-y-8">
      <h2 className="font-bold text-2xl bg-lena-lightgray text-center py-8">Carte de compétences transversales</h2>
      <div className="flex items-center justify-center flex-1 w-full pb-8">
        <div style={{ width: '21cm', minHeight: '29.7cm' }} className="p-4 bg-lena-lightgray space-y-4">
          <Button
            variant="white-shadow"
            size="sm"
            padding="sm"
            iconLeft={<DownloadIcon height={16} />}
            onClick={handleDownload}
            disabled={downloadState.loading}
            loading={downloadState.loading}
          >
            Télécharger ma carte de compétences
          </Button>
          <PreviewA4 allowOverflow={true}>
            <InitializeSelfevalCard globalCompetences={globalCompetences} user={user} skills={mySkills} />
          </PreviewA4>
        </div>
      </div>
    </div>
  );
};

export default PreviewSelfevalCard;
