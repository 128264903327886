import React, { useCallback, createContext, FunctionComponent, PropsWithChildren } from 'react';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PuceCheckSvg from 'assets/svg/generic/success.svg';

type Type = 'info' | 'success' | 'warning' | 'error';

const ToastContext = createContext({
  sendToast: (msg: string, type?: Type, options?: ToastOptions) => {},
});

export default ToastContext;

export const ToastContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const addToast = useCallback(function (msg: string, type?: Type, options?: ToastOptions) {
    const optionsOverride = {
      autoClose: 3000,
      hideProgressBar: true,
      ...options,
    };

    switch (type) {
      case 'info':
        toast.info(msg, optionsOverride);
        break;
      case 'success':
        toast.success(msg, {
          icon: <img alt="" src={PuceCheckSvg} />,
          progressStyle: { backgroundColor: '#4DC5AF' },
          ...optionsOverride,
        });
        break;
      case 'warning':
        toast.warning(msg, optionsOverride);
        break;
      case 'error':
        toast.error(msg, optionsOverride);
        break;
      default:
        toast.info(msg, optionsOverride);
    }
  }, []);

  return (
    <ToastContext.Provider value={{ sendToast: addToast }}>
      {children}
      <ToastContainer limit={3} />
    </ToastContext.Provider>
  );
};
