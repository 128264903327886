import React, { FunctionComponent } from 'react';
import { ReactComponent as LogoDiagoSvg } from 'assets/logos/diagoriente2.svg';
import { Button } from 'components/design-system';

const AppErrorBoundary: FunctionComponent = () => {
  return (
    <div className="bg-lena-lightgray flex flex-1 h-screen flex-col">
      <div className="container max-w-3xl flex flex-1 flex-col items-center justify-center text-center space-y-8">
        <a href="/">
          <LogoDiagoSvg height={32} />
        </a>

        <div className="space-y-2 bg-white px-4 py-8 rounded-md">
          <h1 className="text-3xl font-bold">Oops !</h1>
          <div className="text-lg">
            <p>Une erreur est survenue, nous travaillons à la résolution du problème !</p>
            <p>
              Si le problème persiste, vous pouvez nous contacter à{' '}
              <a href="mailto:contact@diagoriente.beta.gouv.fr" className="font-bold">
                contact@diagoriente.beta.gouv.fr
              </a>{' '}
              ou compléter le formulaire de rapport d'incident.
            </p>
          </div>
        </div>

        <Button variant="secondary" size="lg" onClick={() => window.location?.reload()}>
          Réessayer
        </Button>
      </div>
    </div>
  );
};

export default AppErrorBoundary;
