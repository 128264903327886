import React, { FunctionComponent, PropsWithChildren } from 'react';
// @ts-ignore
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

export type Props = {
  gutter?: number | string;
  breakpoints?: Partial<{
    base: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
    xxl: number;
  }>;
};

const MasonryWrapper: FunctionComponent<PropsWithChildren<Props>> = ({ gutter, breakpoints, children }) => {
  // If provided with a integer, convert to TailwindCSS units
  const gutterSize = Number.isInteger(gutter) ? `${Number(gutter) * 0.125}rem` : gutter;

  return (
    <div className="w-full">
      <ResponsiveMasonry
        columnsCountBreakPoints={{
          1: breakpoints?.base || 1,
          640: breakpoints?.sm || breakpoints?.base,
          768: breakpoints?.md || breakpoints?.sm,
          1024: breakpoints?.lg || breakpoints?.md || breakpoints?.sm,
          1280: breakpoints?.xl || breakpoints?.lg || breakpoints?.md || breakpoints?.sm,
          1536: breakpoints?.xxl || breakpoints?.xl || breakpoints?.lg || breakpoints?.md || breakpoints?.sm,
        }}
      >
        <Masonry gutter={gutterSize}>{children}</Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default MasonryWrapper;
