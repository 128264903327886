import React, { useContext, useState } from 'react';
import CvLayout from 'layouts/CVLayout/CVLayout';
import CreateV4StepAvatar from 'containers/SkillCardV4Container/CreateV4StepAvatar';
import CreateV4StepTemplate from 'containers/SkillCardV4Container/CreateV4StepTemplate';
import UserContext from 'common/contexts/UserContext';
import { useCreateCvNew } from 'containers/SkillCardV4Container/requests/cv.request';
import { useNavigate } from 'react-router-dom';

const SkillCardV4CreateContainer = () => {
  const { user } = useContext(UserContext);
  const [callCreate, createState] = useCreateCvNew();
  const [stepNum, setStepNum] = useState(0);
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState<string>();
  const [avatarFile, setAvatarFile] = useState<File | undefined>();

  const handleSubmitAvatar = (avatar: string, file?: File) => {
    setAvatar(avatar);
    setAvatarFile(file);
    setStepNum(1);
  };

  const handleCreate = (templateId: string, color: string, variant: string) => {
    const title = `CV ${user?.firstName} ${user?.lastName} n°${(user?.cvNews?.length || 0) + 1}`;
    callCreate({
      variables: { input: { template: templateId, variant, accentColor: color, title, avatar: avatarFile } },
    }).then((r) => {
      const cvId = r.data?.createCvNew.id;
      navigate(`/mes-cv/editeur/${cvId}`);
    });
  };

  const STEPS = [
    { component: <CreateV4StepAvatar onSkip={() => setStepNum(1)} onSubmit={handleSubmitAvatar} /> },
    { component: <CreateV4StepTemplate avatar={avatar} onPrev={() => setStepNum(0)} onSubmit={handleCreate} /> },
  ];

  return (
    <CvLayout>
      <div className="container divide-y divide-lena-lightgray2 flex flex-col p-4">
        <div className="py-4 font-bold text-2xl text-center">
          CV {user?.firstName} {user?.lastName} n°{(user?.cvNews?.length || 0) + 1}
        </div>
        <div className="py-4">{STEPS[stepNum].component}</div>
      </div>
    </CvLayout>
  );
};

export default SkillCardV4CreateContainer;
