import React, { useRef, useMemo } from 'react';
import { InitializeTemplateProps } from '../types/template';
import Base from './models/standard/Base';
import staticTemplateList from './staticTemplateList';
import appEnvironment from 'config/environment';
import CvRenderContext from 'containers/SkillCardV4Container/contexts/CvRenderContext';
import DEFAULT_FONTS from 'containers/SkillCardV4Container/constants/fonts';

const InitializeTemplate = React.forwardRef<HTMLDivElement, InitializeTemplateProps>(
  ({ theme, data, editorData, config, customizationOptions }, ref) => {
    const templateRef = useRef<HTMLDivElement>(null);

    const fontFamily = useMemo(() => {
      return customizationOptions?.font
        ? DEFAULT_FONTS.find((v) => v.key === customizationOptions.font)?.family
        : undefined;
    }, [customizationOptions]);

    const renderTemplate = () => {
      const staticHost =
        appEnvironment.NODE_ENV === 'production'
          ? 'https://static.diagoriente.beta.gouv.fr/'
          : 'https://static.diagoriente.fr/';
      const avatarUrl = data?.photo || '';
      const fixupUrl = avatarUrl.replace(/^(undefined)/, staticHost);

      const Component = staticTemplateList.find((v) => v.key === theme)?.component || Base;

      const skillsCustom = useMemo(() => {
        const skills = [...(data?.skills || [])];
        const skillsFilter: any = [];

        skills.map((s) => {
          const d =
            s.data?.activities?.filter?.((e: any) => s.activities?.find((m: any) => e.id === m.activityId)) ||
            undefined;

          skillsFilter.push({
            ...s,
            data: {
              ...s.data,
              activities: typeof s.activities === 'undefined' ? s.data.activities : d,
            },
          });
        });

        return skillsFilter;
      }, [data]);

      return (
        <div style={{ height: '100%', fontFamily: fontFamily }} ref={templateRef}>
          <div className="h-full" ref={ref}>
            <Component
              data={{
                ...data,
                skills: skillsCustom,
                contentOrder: data?.contentOrder?.filter((_) => _.visible),
                photo: fixupUrl,
              }}
              editorData={editorData}
              customizationOptions={customizationOptions || { variant: 'trait', accentColor: '' }}
              config={config}
            />
          </div>
        </div>
      );
    };

    const configObject = { print: config?.print || false, preview: config?.preview || false };
    return <CvRenderContext.Provider value={{ config: configObject }}>{renderTemplate()}</CvRenderContext.Provider>;
  },
);

export default InitializeTemplate;
