import React from 'react';
import GraduationCriteriaTable from '../components/GraduationCriteriaTable';
import { Critere } from 'common/requests/types';
import CardWithLevelSelector from 'containers/SelfevalContainer/components/CardWithLevelSelector';
import MasonryWrapper from 'components/MasonryWrapper';

type Props = {
  critereJson: Critere[];
  criteriaLevels: Record<string, number>;
  onUpdateCriteriaRating: (criteriaId: string, level: number) => void;
};

const EvaluationCriteria: React.FC<Props> = ({ critereJson, criteriaLevels, onUpdateCriteriaRating }) => {
  return (
    <div className="space-y-8 flex flex-col">
      <div className="">
        <div className="font-bold text-xl">
          Dans ce tableau, sélectionnez les niveaux qui correspondent, selon vous, à cette expérience.
        </div>
        <div>Pour chaque ligne, cliquez sur la case la plus proche de votre expérience</div>
      </div>

      <div className="w-full">
        <div className="hidden xl:block">
          <GraduationCriteriaTable
            maxLevel={7}
            criteria={critereJson}
            criteriaLevels={criteriaLevels}
            onSetCriteriaLevel={onUpdateCriteriaRating}
          />
        </div>

        <div className="block xl:hidden space-y-4 w-full">
          <MasonryWrapper breakpoints={{ base: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }} gutter={4}>
            {critereJson.map((c) => (
              <CardWithLevelSelector
                key={c.id}
                title={
                  <>
                    <div>{c.title}</div>
                    {c.subtitle && <div className="text-sm">{c.subtitle}</div>}
                  </>
                }
                levels={c.levels.map((v) => ({ value: v.level, description: v.content }))}
                onSelectLevel={(level) => onUpdateCriteriaRating(c.id, level)}
                selectedLevel={criteriaLevels[c.id]}
                window={4}
              />
            ))}
          </MasonryWrapper>
        </div>
      </div>
    </div>
  );
};

export default EvaluationCriteria;
