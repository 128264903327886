import React, { FunctionComponent, useContext, useState } from 'react';
import AppDrawerJO2024 from 'layouts/AppLayout/jo2024/AppDrawerJO2024';
import UserContext from 'common/contexts/UserContext';
import { ReactComponent as BurgerMenuSvg } from 'assets/svg/generic/menu.svg';
import { AppUserMenu } from 'layouts/AppLayout/AppUserMenu';

const AppHeaderJO2024Mobile: FunctionComponent<{ title?: React.ReactNode; withOnlyBack?: boolean }> = () => {
  const { user } = useContext(UserContext);
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <div className="sticky top-0 shadow z-50 h-14">
      <div className="py-2 px-4 bg-white flex flex-row items-center justify-between h-full">
        <div>
          <BurgerMenuSvg onClick={() => setShowDrawer(!showDrawer)} />
        </div>
        <div className="font-bold text-lena-blue-dark text-xl">Tous et toutes volontaires</div>
        {user && <AppUserMenu />}
      </div>
      <AppDrawerJO2024 open={showDrawer} onClose={() => setShowDrawer(false)} />
    </div>
  );
};

export default AppHeaderJO2024Mobile;
