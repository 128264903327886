import React, { FunctionComponent, Suspense } from 'react';
import useEditor from 'hooks/useEditor';
import { Button } from 'components/design-system';
import { ReactComponent as LoaderDotsSvg } from 'assets/svg/loader-dots.svg';

import { useToastContext } from 'hooks';
import Stepper from './Stepper';

type Props = {
  hideSteps?: boolean;
};

/**
 * EditorView
 * @description The entire view on the rght of the screen, which allows you to edit the resume.
 */
const EditorView: FunctionComponent<Props> = ({ hideSteps = false }) => {
  const {
    activeStep,
    editorTabs,
    steps,
    currentStep,
    setCurrentStep,
    prevEditorPage,
    nextEditorPage,
    toggleShowColumnEditor,
  } = useEditor();
  const { sendToast } = useToastContext();

  const getStep = editorTabs?.find((_) => _.key === activeStep.tab)?.items[currentStep];

  const handleSend = () => {
    toggleShowColumnEditor(false);
    setCurrentStep(0);
    sendToast('Bravo ! Vous pouvez encore affiner votre CV, le relire et le partager', 'success');
  };

  if (!getStep) return null;

  return (
    <>
      {!hideSteps && <Stepper />}

      <div className="divide-y mx-4 lg:mx-0 overflow-auto scroll-thin pt-4 lg:pt-0 space-y-8 px-4">
        <Suspense fallback={<LoaderDotsSvg height={64} />}>
          {getStep.assistant && <div className="text-sm">{getStep.assistant}</div>}
          {getStep.children && <div className="py-4">{getStep.children()}</div>}

          {/* Navigation prev or next */}
          <div className="w-full">
            <div className="my-4 max-w-sm text-center m-auto space-y-4">
              {currentStep !== steps.length - 1 ? (
                <Button onClick={() => nextEditorPage()} fullWidth type="button" variant="assistant">
                  Suivant
                </Button>
              ) : (
                <Button onClick={handleSend} fullWidth type="button" variant="assistant">
                  Valider
                </Button>
              )}

              {currentStep !== 0 && (
                <button type="button" className="cursor-pointer" onClick={() => prevEditorPage()}>
                  Précédent
                </button>
              )}
            </div>
          </div>
        </Suspense>
      </div>
    </>
  );
};

export default EditorView;
