import React, { FunctionComponent, useRef, useState } from 'react';
import useEditor from 'hooks/useEditor';
import { ReactComponent as ArrowDownSvg } from 'assets/svg/generic/arrow_down.svg';

import useOnClickOutside from 'common/hooks/useOnClickOutside';
import clsx from 'clsx';

type Props = {
  isMobile?: boolean;
  onActionSave?: () => void;
};

const Stepper: FunctionComponent<Props> = ({ isMobile, onActionSave }) => {
  const { steps, currentStep, setCurrentStep } = useEditor();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => {
    if (dropdownOpen) setDropdownOpen(false);
  });

  const handleClickStep = (id: number) => {
    onActionSave?.();

    setCurrentStep(id);
    setDropdownOpen(false);
  };

  return (
    <div className="flex h-full gap-1 justify-between md:h-12 md:mb-4">
      <div ref={dropdownRef} className="relative w-full">
        <button
          className={clsx(
            'w-full h-full flex items-center justify-center bg-white gap-2',
            !isMobile && 'rounded-md shadow-sm p-4',
            steps.length > 1 ? 'cursor-pointer' : 'cursor-default',
          )}
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <span className="contents font-sans select-none font-bold text-lg whitespace-nowrap overflow-hidden text-ellipsis">
            {steps[currentStep]?.label}
          </span>

          {steps.length > 1 && (
            <ArrowDownSvg className={`${dropdownOpen && 'transform -rotate-180'} transition`} height={14} width={14} />
          )}
        </button>

        {steps.length > 1 && dropdownOpen && (
          <div className="flex w-full absolute justify-center mt-1">
            <div className="absolute max-h-72 overflow-auto scroll-thin bg-white shadow-md rounded-md z-30 space-y-1 py-2 text-center w-full">
              {steps.map((step, i) => (
                <span
                  key={step.key}
                  onClick={() => handleClickStep(i)}
                  className={clsx(
                    `flex mx-2 rounded-md justify-center select-none cursor-pointer`,
                    isMobile ? 'py-4' : 'py-2',
                    {
                      'hover:bg-gray-100': i !== currentStep,
                      'bg-white border-2 border-lena-corail font-bold': i === currentStep,
                    },
                  )}
                >
                  <span>{step.label}</span>
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Stepper;
