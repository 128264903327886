import React from 'react';
import RecommendationItemGenericV4 from 'containers/SkillCardV4Container/templates/components/ContentBlockGenericV4/Recommendations/RecommendationItemGenericV4';
import ContentBlockGenericV4 from 'containers/SkillCardV4Container/templates/components/ContentBlockGenericV4/ContentBlockGenericV4';

type Props = {
  data: any[];
  itemComponent?: React.ComponentType<{ data: any }>; // TODO: merge this into generic-types-v4.ts
};

const RecommendationBlockGenericV4: React.FC<Props> = ({ data: recommendationList, itemComponent }) => {
  const RenderedComponent = itemComponent || RecommendationItemGenericV4;

  return (
    <ContentBlockGenericV4 title="Recommandations">
      {recommendationList?.map?.((item: any) => (
        <RenderedComponent key={item.id} data={item} />
      ))}
    </ContentBlockGenericV4>
  );
};

export default RecommendationBlockGenericV4;
