import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Button, SelectorTest } from 'components/design-system';
import * as _ from 'lodash-es';
import { Competence } from 'common/requests/types';
import { getCompetenceGroup } from 'containers/SelfevalContainer/utils';
import classNames from 'common/utils/classNames';

type Props = {
  competenceJson: Competence[];
  selectedCompetences: string[];
  setSelectedCompetences: Dispatch<SetStateAction<string[]>>;
  onSubmit: (competenceIds: string[]) => void;
};

const SelectCompetences: React.FC<Props> = ({
  competenceJson,
  onSubmit,
  selectedCompetences,
  setSelectedCompetences,
}) => {
  const toggleCompetence = (id: string) => {
    setSelectedCompetences((current) => _.xor(current, [id]));
  };

  const groupedCompetences = useMemo(() => {
    return _.groupBy(competenceJson, 'type');
  }, []);

  const renderPole = (name: string, competences: any[]) => {
    if (!competences || competences.length <= 0) return null;

    const groupConfig = getCompetenceGroup(name);
    if (!groupConfig) return null;

    const Picto = groupConfig?.picto;

    return (
      <div className="space-y-2">
        <div
          className={classNames(
            'rounded-md px-4 py-2 uppercase font-bold text-lena-blue-dark inline-flex w-full gap-4',
            groupConfig?.color,
          )}
        >
          <Picto width={25} height={25} />

          {groupConfig?.label}
        </div>

        {competences.map((c, i) => (
          <SelectorTest
            key={i}
            withCheckBox={true}
            checked={selectedCompetences.includes(c.id)}
            onClick={() => toggleCompetence(c.id)}
            checkedColor={`${groupConfig?.color} bg-opacity-100`}
          >
            {c.title}
          </SelectorTest>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col container space-y-8 py-12">
      <div>
        <p className="font-bold text-xl">
          Toujours par rapport à ces expériences, quelles sont les compétences transversales à évaluer ?
        </p>
        <div className="rounded-md mt-2 text-sm">
          Vous pouvez visualiser le référentiel{' '}
          <a className="font-bold" href="https://static.diagoriente.fr/carto/RECTECA3_table_v4.pdf" target="blank">
            en cliquant ici
          </a>
        </div>
      </div>

      <div className="w-full space-y-2 ">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-2 gap-y-4 w-full">
          {['organizational', 'communication', 'reflective'].map((name) => renderPole(name, groupedCompetences[name]))}
        </div>
      </div>

      <Button
        variant="secondary"
        size="lg"
        extraClasses="w-72 mx-auto"
        onClick={() => onSubmit(selectedCompetences)}
        disabled={selectedCompetences.length <= 0}
      >
        Suivant
      </Button>
    </div>
  );
};

export default SelectCompetences;
