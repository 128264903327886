import React, { Dispatch, useMemo } from 'react';
import EvaluationCriteria from '../components/EvaluationCriteria';
import { Button } from 'components/design-system';
import { Critere } from 'common/requests/types';

type Props = {
  critereJson: Critere[];
  criteriaRatings: Record<string, number>;
  setCriteriaRatings: Dispatch<React.SetStateAction<Record<string, number>>>;
  onSubmit: () => void;
};

const EvalCriteres: React.FC<Props> = ({ critereJson, criteriaRatings, setCriteriaRatings, onSubmit }) => {
  const handleSetCriteriaLevel = (criteriaId: string, level: number) => {
    setCriteriaRatings((current) => ({ ...current, [criteriaId]: level }));
  };

  const canSubmit = useMemo(() => {
    return Object.keys(criteriaRatings).length === critereJson.length;
  }, [criteriaRatings]);

  return (
    <div className="flex flex-col items-center space-y-24 py-12">
      <div className="container xl:m-0">
        <EvaluationCriteria
          critereJson={critereJson}
          criteriaLevels={criteriaRatings}
          onUpdateCriteriaRating={handleSetCriteriaLevel}
        />
      </div>

      <Button variant="secondary" size="lg" onClick={onSubmit} disabled={!canSubmit}>
        Suivant
      </Button>
    </div>
  );
};

export default EvalCriteres;
