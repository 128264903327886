import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import posthog from 'posthog-js';
import appEnvironment from 'config/environment';

import './styles/tailwind.css';

// Polyfills
import './polyfills';

posthog.init(appEnvironment.REACT_APP_POSTHOG_PROJECT_KEY || '', {
  api_host: appEnvironment.REACT_APP_POSTHOG_HOST_URL || 'https://app.posthog.com',
  autocapture: false,
  debug: appEnvironment.NODE_ENV === 'development',
  cross_subdomain_cookie: true,
  opt_out_capturing_by_default: true,
});

if (appEnvironment.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: appEnvironment.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    release: appEnvironment.REACT_APP_RELEASE_NAME || undefined,
    environment: appEnvironment.REACT_APP_ENVIRONMENT,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: appEnvironment.NODE_ENV === 'development' ? 1.0 : 0.25,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as any);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (appEnvironment.isDevelopment) {
  import('./reportWebVitals').then(({ default: reportWebVitals }) => {
    reportWebVitals(console.log);
  });
}

if (appEnvironment.isDevelopment) {
  import('@axe-core/react').then(({ default: axe }) => {
    axe(React, ReactDOM, 1000);
  });
}
