import { createContext, Dispatch, SetStateAction } from 'react';
import { CVCompetence } from 'containers/SkillCardV4Container/types/dataCV';
import { TemplateFont, TemplateVariant } from 'containers/SkillCardV4Container/types/template';

export type CVContextVolunteers = {
  itemId: string;
  sourceType: string | 'USER_CREATED' | 'ORIGIN';
  type?: string;
  data: any;
};

export type CVContextSkills = {
  itemId: string;
  sourceType: string | 'USER_CREATED' | 'ORIGIN';
  type: string;
  data: any;
  activities?: { activityId: string; label?: string }[];
};

export type CVContextData = {
  cvId: string;
  setCvId: (s: string) => void;
  cvName: string;
  setCvName: (s: string) => void;
  templateKey: string;
  setTemplateKey: (s: string) => void;
  accentColor: string;
  setAccentColor: (s: string) => void;
  templateVariant: TemplateVariant;
  setTemplateVariant: (s: TemplateVariant) => void;
  templateFont: TemplateFont;
  setTemplateFont: (s: TemplateFont) => void;
  photo: string;
  setPhoto: (s: string) => void;
  job: string;
  setJob: (s: string) => void;
  description: string;
  setDescription: (s: string) => void;
  email: string;
  setEmail: (s: string) => void;
  phone: string;
  setPhone: (s: string) => void;
  extraInfos: string;
  setExtraInfos: (s: string) => void;
  formations: { id: string; value: string }[];
  setFormation: (s: { id: string; value: string }[]) => void;
  keywords: { id: string; value: string }[];
  setKeyword: (s: { id: string; value: string }[]) => void;
  contentOrder: { visible: boolean; name: string }[];
  setContentOrder: (s: { visible: boolean; name: string }[]) => void;
  skills: CVContextSkills[];
  setSkills: (s: CVContextSkills[]) => void;
  editSkill: string;
  setEditSkill: (s: string) => void;
  competences: {
    technique: CVCompetence[] | any[];
    transversal: CVCompetence[] | any[];
  };
  setCompetences: Dispatch<SetStateAction<{ technique: CVCompetence[] | any[]; transversal: CVCompetence[] | any[] }>>;
  overrides: {
    competences: {
      id: string;
      title: string;
    }[];
    skills: {
      id: string;
      title: string;
    }[];
  };
  setOverrides: (s: {
    competences: {
      id: string;
      title: string;
    }[];
    skills: {
      id: string;
      title: string;
    }[];
  }) => void;
  volunteers: CVContextVolunteers[];
  setVolunteers: (s: CVContextVolunteers[]) => void;
  importContentOrder: (order: string[]) => void;
  recommendations: string[];
  setRecommendations: Dispatch<SetStateAction<string[]>>;
};

export const CVContext = createContext<CVContextData>({
  cvId: '',
  setCvId: () => {},
  templateKey: '',
  setTemplateKey: () => {},
  accentColor: '',
  setAccentColor: () => {},
  templateVariant: 'trait',
  setTemplateVariant: () => {},
  templateFont: 'atkinson',
  setTemplateFont: () => {},
  cvName: '',
  setCvName: () => {},
  photo: '',
  setPhoto: () => {},
  job: '',
  setJob: () => {},
  description: '',
  setDescription: () => {},
  phone: '',
  setPhone: () => {},
  email: '',
  setEmail: () => {},
  extraInfos: '',
  setExtraInfos: () => {},
  formations: [],
  setFormation: () => {},
  keywords: [],
  setKeyword: () => {},
  skills: [],
  setSkills: () => {},
  editSkill: '',
  setEditSkill: () => {},
  contentOrder: [],
  competences: {
    technique: [],
    transversal: [],
  },
  setContentOrder: () => {},
  setCompetences: () => {},
  overrides: {
    competences: [],
    skills: [],
  },
  setOverrides: () => {},
  volunteers: [],
  setVolunteers: () => {},
  importContentOrder: () => {},
  recommendations: [],
  setRecommendations: () => {},
});
