import React, { Dispatch, SetStateAction } from 'react';
import { UseAdvertsSeenHistoryResult } from 'containers/AdvertsContainer/hooks/useAdvertsSeenHistory';

export const defaultParameters: AdvertsSearchParameters = {
  romeCodes: [],
  location: undefined,
  engagementDomain: undefined,
  emploiFilters: {},
  engagementFilters: {},
};

export type AdvertsSearchParameters = {
  romeCodes: { codes: string[]; label: string }[];
  location?: { lat: number; lng: number; label?: string; postcode?: string; citycode?: string; radius?: number };
  engagementDomain?: string;
  emploiFilters?: {
    offresManqueCandidats?: boolean;
    accesTravailleurHandicape?: boolean;
    debutantAccepte?: boolean;
    typesContrat?: string[];
  };
  engagementFilters?: {
    openToMinors?: boolean;
    reducedMobilityAccessible?: boolean;
  };
};

export type AdvertSearchContextData = {
  typeSearch: any;
  setTypeSearch: Dispatch<SetStateAction<any>>;
  mapZoom: number;
  setMapZoom: Dispatch<SetStateAction<any>>;
  cluster: [number, number] | null;
  setCluster: Dispatch<SetStateAction<[number, number] | null>>;
  mapPosition: [number, number];
  setMapPosition: Dispatch<SetStateAction<[number, number]>>;
  parameters: AdvertsSearchParameters;
  updateParameters: (input: Partial<AdvertsSearchParameters>) => void;
  advertsHistory: UseAdvertsSeenHistoryResult | null;
};

const AdvertSearchContext = React.createContext<AdvertSearchContextData>({
  typeSearch: '',
  setTypeSearch: () => {},
  mapZoom: 0,
  setMapZoom: () => {},
  cluster: null,
  setCluster: () => {},
  mapPosition: [0, 0],
  setMapPosition: () => {},
  parameters: defaultParameters,
  updateParameters: () => {},
  advertsHistory: null,
});

export default AdvertSearchContext;
