import React from 'react';
import { Background } from './components';

const Layout: React.FC<{
  sidebar?: JSX.Element;
  content: JSX.Element;
  customizationOptions: any;
}> = ({ sidebar, content, customizationOptions }) => {
  return (
    <Background>
      <div className="flex space-x-10 h-full text-print">
        <div
          className={`p-6 flex-shrink-0 h-full ${
            customizationOptions?.variant === 'trait' && 'border-r border-lena-blue-dark'
          }`}
          style={{
            width: '35%',
            ...(customizationOptions?.variant === 'trait' && {
              borderRight: `1px solid ${customizationOptions?.accentColor}`,
            }),
            ...(customizationOptions?.variant === 'fond' && { background: `${customizationOptions?.accentColor}16` }),
          }}
        >
          {sidebar}
        </div>

        <div style={{ width: '60%' }}>{content}</div>
      </div>
    </Background>
  );
};

export default Layout;
