import { gql, QueryHookOptions } from '@apollo/client';
import { useLocalLazyQuery, useLocalQuery } from 'common/hooks/apollo';
import { Skill, User } from 'common/requests/types';

export const GetSelfevalDataForCompetenceCardQuery = gql`
  query GetSelfevalDataForCompetenceCard($userId: ObjectID, $secret: String) {
    publicSelfeval(userId: $userId, secret: $secret) {
      user {
        firstName
        lastName
      }
      skills {
        id
        domain
        intitule
        theme {
          id
          title
        }
        activities {
          id
          title
        }
        extraActivity
        structure
        startDate
        endDate
      }
      globalCompetences {
        id
        title
        level
        levelDescription
      }
    }
  }
`;

export type GetSelfevalDataForCompetenceCardPayload = {
  publicSelfeval: {
    user: Pick<User, 'firstName' | 'lastName'>;
    skills: Skill[];
    globalCompetences: {
      id: string;
      title: string;
      level: number;
      levelDescription: string;
    }[];
  };
};

export type GetSelfevalDataForCompetenceCardArguments = {
  userId?: string | null;
  secret?: string | null;
};

export const useGetSelfevalDataForCompetenceCardQuery = (
  options: QueryHookOptions<GetSelfevalDataForCompetenceCardPayload, GetSelfevalDataForCompetenceCardArguments>,
) => useLocalQuery(GetSelfevalDataForCompetenceCardQuery, options);

export const useGetSelfevalDataForCompetenceCardLazyQuery = (
  options: QueryHookOptions<GetSelfevalDataForCompetenceCardPayload, GetSelfevalDataForCompetenceCardArguments>,
) => useLocalLazyQuery(GetSelfevalDataForCompetenceCardQuery, options);
