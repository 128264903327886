import React, { FunctionComponent, useState } from 'react';
import PictoExperience from 'assets/svg/generic/plus.svg';
import PictoPlaceholder from 'assets/svg/navigation/search.svg';
import { Link } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import LogoDiagoriente from 'assets/logos/diagoriente.svg';
import RepubliqueSvg from 'assets/logos/republique.svg';
import LogoBetagouv from 'assets/logos/betagouv.svg';
import DiagoSvg from 'assets/logos/diagoriente-mini.svg';
import { AppFlavor } from 'contexts/AppFlavorContext';
import useAppFlavor from 'hooks/useAppFlavor';
import classNames from 'common/utils/classNames';
import HeartSvg from 'assets/svg/navigation/heart_m.svg';
import TopJobSvg from 'assets/svg/navigation/top_job.svg';
import StarSvg from 'assets/svg/navigation/star_m.svg';
import SettingsSvg from 'assets/svg/navigation/settings_m.svg';
import LogoutSvg from 'assets/svg/navigation/logout.svg';
import { useCookies } from 'react-cookie';
import appEnvironment from 'config/environment';
import { getDomainWithoutProtocol } from 'utils';

const AppDrawer: FunctionComponent<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const { flavor, is1J1S } = useAppFlavor();
  const [showOverlay, setShowOverlay] = useState(false);
  const isOrientationTunnel = flavor === AppFlavor.Orientation1J1S;
  const isCVTunnel = flavor === AppFlavor.Cv1J1S;
  const [, , removeCookie] = useCookies(['consent']);

  const links = !is1J1S
    ? [
        { label: 'Accueil', icon: DiagoSvg, link: '/' },
        { label: 'Ajouter une expérience', icon: PictoExperience, link: '/ajout-exp' },
        { label: "Ajouter mes centres d'intérêt", icon: PictoExperience, link: '/centres_interet/chat' },
        { label: 'Rechercher des opportunités', icon: PictoPlaceholder, link: '/opportunites/recherche' },
        { label: 'Rechercher un métier', icon: PictoPlaceholder, link: '/metiers/recherche' },
      ]
    : [
        { label: 'Accueil', icon: DiagoSvg, link: '/' },
        ...(isOrientationTunnel
          ? [
              {
                label: "Mes centres d'intérêt",
                icon: HeartSvg,
                link: '/profil/mes-centres-d-interet',
              },
              {
                label: 'Métiers recommandés',
                icon: TopJobSvg,
                link: '/metiers/metiers-recommandes',
              },
              {
                label: 'Métiers favoris',
                icon: StarSvg,
                link: '/metiers/metiers-favoris',
              },
            ]
          : []),
        ...(isCVTunnel ? [{ label: 'Ajouter une expérience', icon: PictoExperience, link: '/ajout-exp' }] : []),
        {
          label: 'Mes informations',
          icon: SettingsSvg,
          link: '/profil/reglages',
        },
        {
          label: 'Déconnexion',
          icon: LogoutSvg,
          link: '/deconnexion',
        },
      ];

  const handleResetCookie = () => {
    removeCookie('consent', { domain: `${appEnvironment.isProduction ? '.' : ''}${getDomainWithoutProtocol()}` });
    window.location.reload();
  };

  // if (flavor === AppFlavor.UnJeuneUneSolution)
  //  return <AppDrawer1J1S open={open} showOverlay={showOverlay} setShowOverlay={setShowOverlay} onClose={onClose} />;

  return (
    <>
      {showOverlay && (
        <div
          className="fixed top-0 right-0 bottom-0 left-0 bg-lena-blue-dark bg-opacity-80 z-40 overflow-hidden"
          onClick={() => onClose?.()}
        />
      )}

      <Transition
        show={open}
        className="h-screen w-4/5 bg-white absolute top-0 h-screen flex flex-col shadow-xl z-50"
        enter="transform duration-150"
        enterFrom="-translate-x-full"
        enterTo="-translate-x-0"
        leave="transform duration-150"
        leaveFrom="-translate-x-0"
        leaveTo="-translate-x-full"
        beforeEnter={() => setShowOverlay(true)}
        afterLeave={() => setShowOverlay(false)}
      >
        <div className="h-full flex flex-col justify-between">
          <div className="flex flex-col divide divide-y divide-lena-lightgray2">
            <div className="flex flex-col px-8 justify-center space-y-2 py-8">
              <Link to="/">
                <img src={LogoDiagoriente} alt="Logo de Diagoriente" style={{ width: 155, height: 37 }} />
              </Link>
              <div className="flex items-center space-x-4">
                <img src={RepubliqueSvg} alt="Logo de la République Française" style={{ width: 68, height: 36 }} />
                <img src={LogoBetagouv} alt="Logo de Beta.gouv.fr" style={{ width: 80, height: 14 }} />
              </div>
            </div>

            {is1J1S && (
              <div>
                <a
                  className="flex flex-row bg-lena-lightgray items-center space-x-4 py-4 font-marianne"
                  href="https://1jeune1solution.gouv.fr"
                >
                  <div key="1j1s" className="px-8">
                    Revenir à 1jeune1solution
                  </div>
                </a>
              </div>
            )}

            <div className="px-8 py-8 space-y-4">
              {links.map((v) => (
                <div key={v.label} className="flex flex-row items-center space-x-4 text-lena-blue-dark">
                  {v.icon && <img src={v.icon} alt="" height={16} width={16} />}
                  <Link to={v.link}>{v.label}</Link>
                </div>
              ))}
            </div>
          </div>

          <div className="px-8 flex flex-col pb-7">
            {[
              {
                label: 'Préférences des cookies',
                url: window.location.href,
                onClick: handleResetCookie,
              },
              {
                label: 'Aide',
                url: 'https://diagoriente.beta.gouv.fr/aide',
              },
              {
                label: "Conditions d'utilisation",
                url: 'https://diagoriente.beta.gouv.fr/conditions-generales',
              },
              {
                label: 'Mentions légales',
                url: 'https://diagoriente.beta.gouv.fr/mentions-legales',
              },
              {
                label: 'Politique de confidentialité',
                url: 'https://diagoriente.beta.gouv.fr/confidentialite',
              },
            ].map((item: any, i) => (
              <div key={i} className="pt-2">
                {item?.onClick ? (
                  <span
                    className={classNames('flex flex-row items-center space-x-4 text-lena-blue-dark cursor-pointer')}
                    onClick={item?.onClick}
                  >
                    <span>{item.label}</span>
                  </span>
                ) : (
                  <a
                    className={classNames('flex flex-row items-center space-x-4 text-lena-blue-dark')}
                    href={item.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <span>{item.label}</span>
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
      </Transition>
    </>
  );
};

export default AppDrawer;
