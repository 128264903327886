import React from 'react';
import { SkillCardV4TemplateInputProps } from 'containers/SkillCardV4Container/types/template';

import { Background, SideBar } from './components';
import Layout from './Layout';
import ContentBlockSmartV4 from 'containers/SkillCardV4Container/templates/components/ContentBlockGenericV4/ContentBlockSmartV4';

const Base: React.FC<SkillCardV4TemplateInputProps> = ({ data, editorData, config, customizationOptions }) => {
  const { contentOrder = [] } = data || {};

  return (
    <Background>
      <Layout
        customizationOptions={customizationOptions}
        sidebar={
          <SideBar config={config} data={data} editorData={editorData} customizationOptions={customizationOptions} />
        }
        content={
          <>
            <div className="space-y-4">
              {contentOrder.map((item) => (
                <ContentBlockSmartV4 key={item.name} sourceCvData={data} contentKey={item.name} />
              ))}
            </div>
          </>
        }
      />
    </Background>
  );
};

export default Base;
