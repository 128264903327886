import React, { FunctionComponent, useContext, useState } from 'react';
import { ReactComponent as BurgerMenuSvg } from 'assets/svg/generic/menu.svg';
import { ReactComponent as ChevronLeftSvg } from 'assets/svg/generic/back.svg';
import { useNavigate } from 'react-router-dom';
import UserContext from 'common/contexts/UserContext';
import { AppFlavor } from 'contexts/AppFlavorContext';
import useAppFlavor from 'hooks/useAppFlavor';
import Logo1J1S from 'assets/logos/1j1s-horizontal.svg';
import AppHeaderJO2024Mobile from 'layouts/AppLayout/jo2024/AppHeaderJO2024.mobile';
import { AppUserMenu } from './AppUserMenu';
import AppDrawer from './AppDrawer';

const AppHeaderMobile: FunctionComponent<{ title?: React.ReactNode; withOnlyBack?: boolean }> = ({
  title,
  withOnlyBack,
}) => {
  const { user } = useContext(UserContext);
  const [showDrawer, setShowDrawer] = useState(false);
  const navigate = useNavigate();
  const { flavor, is1J1S } = useAppFlavor();

  if (flavor === AppFlavor.CEJ && !withOnlyBack) return null;
  if (flavor === AppFlavor.Olympics) return <AppHeaderJO2024Mobile />;

  return (
    <>
      <div className="sticky top-0 shadow z-50 h-14">
        {withOnlyBack ? (
          <div className="py-2 px-4 bg-lena-lightgray flex flex-row items-center justify-between h-full">
            <button
              onClick={() => navigate(-1)}
              className="flex items-center space-x-2 focus:ring-0 focus:outline-none"
            >
              <ChevronLeftSvg height={23} width={23} />
              <span className="text-lena-blue-dark font-bold text-lg">Retour</span>
            </button>
          </div>
        ) : (
          <>
            <div className="py-2 px-4 bg-lena-lightgray flex flex-row items-center justify-between h-full">
              <div>
                <BurgerMenuSvg onClick={() => setShowDrawer(!showDrawer)} />
              </div>
              {is1J1S ? (
                <div>
                  <a href="https://1jeune1solution.gouv.fr">
                    <img src={Logo1J1S} alt="Logo de 1jeune1solution" className="h-8" />
                  </a>
                </div>
              ) : (
                <>
                  <div className="font-bold text-lena-blue-dark text-xl">{title ?? document.title}</div>
                  {user && <AppUserMenu />}
                </>
              )}
            </div>
            <AppDrawer open={showDrawer} onClose={() => setShowDrawer(false)} />
          </>
        )}
      </div>
    </>
  );
};

export default AppHeaderMobile;
