import React, { useCallback } from 'react';
import StringListBlockV4 from 'containers/SkillCardV4Container/templates/components/ContentBlockGenericV4/StringList/StringListBlockV4';
import { allCvBlocks } from 'containers/SkillCardV4Container/types/template';
import SkillListBlockV4 from 'containers/SkillCardV4Container/templates/components/ContentBlockGenericV4/Skills/SkillListBlockV4';
import ContentBlockGenericV4 from 'containers/SkillCardV4Container/templates/components/ContentBlockGenericV4/ContentBlockGenericV4';

type Props = {
  sourceCvData: any;
  contentKey: string;
};

const ContentBlockSmartV4: React.FC<Props> = ({ contentKey, sourceCvData: cvCreateCtx }) => {
  const part = contentKey?.split('-')[0];
  const sub = contentKey?.split('-')[1];
  const title = allCvBlocks[contentKey];

  const renderCompetencesBlock = useCallback(
    (type: string) => {
      const attachOverride = (id: string) => {
        const findOverride = cvCreateCtx.overrides?.competences.find((e: any) => e.id === id);
        return findOverride ? findOverride.title : undefined;
      };

      const competencesStrings =
        (cvCreateCtx.competences as any)[type]?.map((e: any) => attachOverride(e.id) ?? e.title) || [];

      return (
        <StringListBlockV4
          stringItems={competencesStrings}
          title={title}
          editorWizardKey={{ content: contentKey, design: 'content_order' }}
        />
      );
    },
    [cvCreateCtx.competences, cvCreateCtx.overrides],
  );

  const renderSkillBlock = useCallback(
    (skills: any[]) => {
      if (!skills || skills.length <= 0) return null;
      return (
        <SkillListBlockV4
          contentKey={contentKey}
          skills={skills}
          enabledRecommendationsId={cvCreateCtx.recommendations}
        />
      );
    },
    [cvCreateCtx.skills, cvCreateCtx.recommendations],
  );

  switch (part) {
    case 'comp':
      return renderCompetencesBlock(sub);
    case 'experience':
    case 'volunteers':
      return renderSkillBlock(
        part === 'volunteers' ? cvCreateCtx.volunteers : cvCreateCtx.skills?.filter((v: any) => v.type === sub) || [],
      );
    case 'job':
      return (
        <ContentBlockGenericV4
          title={'Métier, poste'}
          editorWizardKey={{ content: 'job' }}
          hideTitleInPrintMode={true}
          hideInPrintMode={!cvCreateCtx.job}
        >
          {cvCreateCtx.job}
        </ContentBlockGenericV4>
      );
    case 'description':
      return (
        <ContentBlockGenericV4
          title={'Description'}
          editorWizardKey={{ content: 'description' }}
          hideTitleInPrintMode={true}
          hideInPrintMode={!cvCreateCtx.description}
        >
          <p className="whitespace-pre-wrap">{cvCreateCtx.description}</p>
        </ContentBlockGenericV4>
      );
    case 'contact':
      return (
        <ContentBlockGenericV4
          title={'Contact'}
          editorWizardKey={{ content: 'informations' }}
          hideInPrintMode={!cvCreateCtx.email || !cvCreateCtx.phone}
        >
          {(cvCreateCtx.email || cvCreateCtx.phone) && (
            <div>
              {cvCreateCtx.email && <div>{cvCreateCtx.email}</div>}
              {cvCreateCtx.phone && <div>{cvCreateCtx.phone}</div>}
            </div>
          )}
        </ContentBlockGenericV4>
      );
    case 'formations':
      return (
        <ContentBlockGenericV4
          title={'Formation'}
          editorWizardKey={{ content: 'formations' }}
          hideInPrintMode={!cvCreateCtx.formations || cvCreateCtx.formations.length <= 0}
        >
          {cvCreateCtx.formations && cvCreateCtx.formations.length > 0 && (
            <ul className="space-y-1">
              {cvCreateCtx.formations.map((formation: any) => (
                <li key={formation.id} className="break-words">
                  {formation.value}
                </li>
              ))}
            </ul>
          )}
        </ContentBlockGenericV4>
      );
    case 'extras':
      return (
        <ContentBlockGenericV4
          title={'Autres'}
          editorWizardKey={{ content: 'informations' }}
          hideInPrintMode={!cvCreateCtx.extraInfos}
        >
          <p className="whitespace-pre-wrap">{cvCreateCtx.extraInfos}</p>
        </ContentBlockGenericV4>
      );
    case 'keywords':
      return (
        <ContentBlockGenericV4
          title={'Mots clés'}
          editorWizardKey={{ content: 'keywords' }}
          hideInPrintMode={!cvCreateCtx.keywords || cvCreateCtx.keywords.length <= 0}
        >
          {cvCreateCtx.keywords && cvCreateCtx.keywords.length > 0 && (
            <div className={'flex flex-wrap gap-1'}>
              {cvCreateCtx.keywords.map((k: any) => (
                <div
                  key={k.id}
                  style={{ borderRadius: 4, border: `2px solid ${cvCreateCtx.customizationOptions?.accentColor}64` }}
                  className="px-2 py-2 truncate"
                >
                  {k.value}
                </div>
              ))}
            </div>
          )}
        </ContentBlockGenericV4>
      );
    default:
      return null;
  }
};

export default ContentBlockSmartV4;
