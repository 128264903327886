import React, { FunctionComponent, InputHTMLAttributes } from 'react';
import classNames from 'common/utils/classNames';
import { createUUID } from 'utils';

export type RadioButtonProps = {
  label: string | React.ReactNode;
  className?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const RadioButton: FunctionComponent<RadioButtonProps> = ({
  label,
  className,
  required,
  ...rest
}: RadioButtonProps) => {
  const id = `radio.${label || rest.id || createUUID()}`;
  return (
    <div>
      <label className="cursor-pointer flex space-x-2 items-center">
        <input
          id={id}
          type="radio"
          className={classNames(className, 'cursor-pointer h-4 w-4 text-lena-blue-dark focus:ring-0')}
          required={required}
          aria-checked={rest.checked}
          aria-label={`${label}`}
          {...rest}
        />
        <span>
          {label}
          {required && <span className="ml-1">*</span>}
        </span>
      </label>
    </div>
  );
};

export default RadioButton;
