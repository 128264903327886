import { useLayoutEffect, useState } from 'react';

const useRefDimensionsTemplate = (ref: any, showColumnEditor: boolean) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    const handleRef = () => {
      if (!ref.current) return;

      const { current } = ref;
      const { width, height } = current.getBoundingClientRect();

      setDimensions({ width: Math.round(width), height: Math.round(height) });
    };

    window.addEventListener('resize', handleRef);

    handleRef();

    return () => window.removeEventListener('resize', handleRef);
  }, [ref, ref.current, showColumnEditor]);

  return dimensions;
};

export default useRefDimensionsTemplate;
