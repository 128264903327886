import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUrlQuery } from 'hooks';
import AdvertSearchContext, { AdvertsSearchParameters, defaultParameters } from './AdvertSearchContext';
import useAdvertsSeenHistory from 'containers/AdvertsContainer/hooks/useAdvertsSeenHistory';

const AdvertSearchProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const urlQuery = useUrlQuery();
  const navigate = useNavigate();
  const location = useLocation();

  const [typeSearch, setTypeSearch] = useState();

  const [parameters, setParameters] = useState<AdvertsSearchParameters>(() => {
    const q = urlQuery.get('q');
    return q ? { ...defaultParameters, ...JSON.parse(q) } : defaultParameters;
  });

  const [mapZoom, setMapZoom] = useState(6);
  const [mapPosition, setMapPosition] = useState<[number, number]>(() =>
    parameters.location ? [parameters.location.lat, parameters.location.lng] : [47, 2.2],
  );

  const [cluster, setCluster] = useState<[number, number] | null>(null);
  const advertsHistory = useAdvertsSeenHistory();

  const updateParameters = (input: Partial<AdvertsSearchParameters>) => {
    setParameters((prevState) => ({
      ...prevState,
      ...input,
    }));
  };

  useEffect(() => {
    if (location.pathname.includes('/resultats'))
      navigate({ search: `q=${JSON.stringify(parameters)}` }, { replace: true });
  }, [parameters]);

  useEffect(() => {
    setCluster(null);
  }, [typeSearch, parameters]);

  return (
    <AdvertSearchContext.Provider
      value={{
        typeSearch,
        setTypeSearch,
        mapZoom,
        setMapZoom,
        cluster,
        setCluster,
        mapPosition,
        setMapPosition,
        parameters,
        updateParameters,
        advertsHistory,
      }}
    >
      {children}
    </AdvertSearchContext.Provider>
  );
};

export default AdvertSearchProvider;
