import React, { FunctionComponent, PropsWithChildren, useMemo, useState } from 'react';
import AppFlavorContext, { AppFlavor } from './AppFlavorContext';

const hostsPerFlavor: Record<Exclude<AppFlavor, AppFlavor.Default>, string[]> = {
  [AppFlavor.Olympics]: ['diago-jo2024.local', 'dev-jo2024.diagoriente.fr', 'cd93.diagoriente.beta.gouv.fr'],
  [AppFlavor.Orientation1J1S]: ['orientation.1jeune1solution.beta.gouv.fr'],
  [AppFlavor.Cv1J1S]: ['dev-1j1s.diagoriente.fr', 'cv.1jeune1solution.beta.gouv.fr'],
  [AppFlavor.CEJ]: ['diago-cej.local', 'dev-cej.diagoriente.fr', 'cej.diagoriente.beta.gouv.fr'],
};

const AppFlavorProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [flavor, setFlavor] = useState<AppFlavor | null>(null);

  const getDefaultFlavor = () => {
    const result = Object.entries(hostsPerFlavor).find(([, v]) => v.includes(window.location.hostname));
    return (result?.[0] as AppFlavor) || AppFlavor.Default;
  };

  const currentFlavor = useMemo(() => {
    return !flavor ? getDefaultFlavor() : flavor;
  }, [flavor, window.location.hostname]);

  const is1J1S = useMemo(() => {
    return [AppFlavor.Orientation1J1S, AppFlavor.Cv1J1S].includes(currentFlavor);
  }, [currentFlavor]);

  return (
    <AppFlavorContext.Provider value={{ flavor: currentFlavor, defaultFlavor: getDefaultFlavor(), setFlavor, is1J1S }}>
      {children}
    </AppFlavorContext.Provider>
  );
};

export default AppFlavorProvider;
