import React, { ChangeEvent, useRef, useState } from 'react';
import { Button } from 'components/design-system';
import { ReactComponent as PictoUploadSvg } from 'assets/svg/modules/cv/upload.svg';
import { Picture as PictureAssistant } from 'containers/SkillCardV4Container/components/assistant';
import { ReactComponent as PictoCross } from 'assets/svg/generic/delete.svg';
import Cropper from 'react-cropper';
import CropEndEvent = Cropper.CropEndEvent;

type Props = {
  onSkip: () => void;
  onSubmit: (avatar: string, file?: File) => void;
};

const CreateV4StepAvatar: React.FC<Props> = ({ onSkip, onSubmit }) => {
  const inputStubRef = useRef<HTMLInputElement>(null);
  const cropperRef = useRef<HTMLImageElement>(null);
  const [imageContent, setImageContent] = useState<string>();
  const [croppedImageContent, setCroppedImageContent] = useState<string>();
  const [imageCropped, setImageCropped] = useState<File>();
  const [error, setError] = useState<string>();

  const handleImageChangeCrop = (e: any) => {
    e.preventDefault();

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files && files.length) {
      const photoSize = Number(files[0].size);

      if (photoSize > 3 * 1024 * 1024) {
        setError('Votre photo est trop volumineuse ! Celle-ci ne doit pas dépasser 3Mo');
        return;
      } else {
        setError(undefined);
      }

      const reader = new FileReader();
      reader.onload = () => {
        setImageContent(reader.result as any);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleCropEnd = (e: CropEndEvent<HTMLImageElement>) => {
    try {
      const imageElement: any = cropperRef?.current;
      const cropper: any = imageElement?.cropper;
      const canvas = e.currentTarget?.cropper?.getCroppedCanvas();
      setCroppedImageContent(canvas.toDataURL());
      cropper?.getCroppedCanvas()?.toBlob(
        (e: any) => {
          if (e) {
            const cropped = new File([e], 'photo.jpeg', {
              type: e.type,
            });
            setImageCropped(cropped);
          }
        },
        'image/jpeg',
        0.9,
      );
    } catch (err) {}
  };

  const handleRemovePhoto = () => {
    setImageContent(undefined);
    setCroppedImageContent(undefined);
    setImageCropped(undefined);
  };

  return (
    <div className="divide-y divide-lena-gray-light">
      <div className="flex flex-col items-center mx-auto max-w-3xl gap-y-8 pt-4 pb-8">
        <div className="text-xl text-lena-blue-dark font-bold">Ajoutez une photo</div>
        {!imageContent && <PictureAssistant />}
        {error && <div className="bg-lena-pink rounded-md w-full text-center text-white py-4 font-bold">{error}</div>}
        <div className="flex w-full gap-2">
          <Button
            variant="white-shadow"
            fullWidth={true}
            iconLeft={<PictoUploadSvg height={24} width={24} />}
            onClick={() => inputStubRef.current?.click()}
          >
            {imageContent ? 'Sélectionner une autre photo' : 'Ajouter une photo'}
          </Button>
          {imageContent && (
            <Button
              variant="white-shadow"
              fullWidth={true}
              iconLeft={<PictoCross height={24} width={24} />}
              onClick={handleRemovePhoto}
            >
              Retirer la photo
            </Button>
          )}
        </div>
        <input
          ref={inputStubRef}
          className="hidden"
          type="file"
          accept="image/png, image/jpeg"
          onChange={handleImageChangeCrop}
        />
        {imageContent && (
          <div>
            <Cropper
              ref={cropperRef}
              src={imageContent}
              style={{ maxHeight: 512, width: '100%' }}
              initialAspectRatio={1}
              cropend={handleCropEnd}
            />
          </div>
        )}
      </div>
      <div className="flex flex-col items-center justify-center gap-1 py-8">
        <Button
          disabled={!croppedImageContent}
          variant="secondary"
          size="lg"
          onClick={() => croppedImageContent && onSubmit(croppedImageContent, imageCropped)}
        >
          Suivant
        </Button>
        <Button variant="ghost" onClick={() => onSkip()}>
          Passer cette étape
        </Button>
      </div>
    </div>
  );
};

export default CreateV4StepAvatar;
