import React, { FunctionComponent, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppUserMenu } from 'layouts/AppLayout/AppUserMenu';
import UserContext from 'common/contexts/UserContext';
import AppDesktopMenu from 'layouts/AppLayout/AppDesktopMenu';
import { useMediaQuery } from 'hooks';

import LogoDiagoriente from 'assets/logos/diagoriente.svg';
import LogoRepubliqueSvg from 'assets/logos/republique.svg';
import LogoBetagouv from 'assets/logos/betagouv.svg';
import LogoLesJeuxEnSSD from 'assets/promotion/jo2024/logo-region-ssd.svg';
import LogoTTV from 'assets/promotion/jo2024/logo-jo2024-ttv.webp';

type Props = {
  publicMode?: boolean;
};

const AppHeaderJO2024Desktop: FunctionComponent<Props> = ({ publicMode }) => {
  const is2XL = useMediaQuery('2xl');
  const isLg = useMediaQuery('lg');
  const { user } = useContext(UserContext);

  return (
    <header className="flex sticky top-0 shadow z-50 justify-between p-2 bg-white items-center">
      <div className="flex gap-2 xl:gap-4 items-center">
        <img src={LogoLesJeuxEnSSD} style={{ width: 'auto', height: 44 }} alt="Logo de Les Jeux en Seine-Saint-Denis" />
        <img src={LogoTTV} style={{ width: 'auto', height: 44 }} alt="Logo Tous et toutes volontaires" />

        {!user && (
          <div className="flex gap-2">
            <Link to="/inscription">
              <button className="inline-flex gap-4 rounded px-4 py-2 border border-jo-dark text-lena-blue-dark uppercase focus:outline-none focus:ring-0">
                S'inscrire
              </button>
            </Link>

            <Link to="/connexion">
              <button className="inline-flex gap-4 rounded px-4 py-2 border border-jo-dark text-lena-blue-dark uppercase focus:outline-none focus:ring-0">
                Se connecter
              </button>
            </Link>
          </div>
        )}

        {user && (
          <div className="inline-flex gap-4">
            <AppDesktopMenu />

            <button className="inline-flex gap-4 rounded px-4 py-2 border border-jo-dark text-lena-blue-dark focus:outline-none focus:ring-0">
              <AppUserMenu hasLabel={is2XL} />
            </button>
          </div>
        )}
      </div>
      <div>
        <div className="flex flex-col lg:flex-row items-center justify-center lg:space-x-4 xl:space-x-6 gap-2 lg:gap-0">
          <a href="https://ssd.fr" target="_blank" rel="noreferrer">
            <img
              src={LogoLesJeuxEnSSD}
              alt="Logo de Les Jeux en Seine-Saint-Denis"
              style={{ maxWidth: 190, maxHeight: 21 }}
            />
          </a>
          <div className="flex flex-row items-center justify-center gap-1 lg:gap-0 lg:space-x-4 xl:space-x-6">
            {publicMode ? (
              <>
                <a href="https://diagoriente.beta.gouv.fr/toutes-tous-volontaires">
                  <img
                    src={LogoDiagoriente}
                    alt="Logo de Diagoriente"
                    style={{ width: isLg ? 155 : 155 / 2, height: isLg ? 37 : 37 / 2 }}
                  />
                </a>
                <a href="https://diagoriente.beta.gouv.fr/toutes-tous-volontaires">
                  <img
                    src={LogoRepubliqueSvg}
                    alt="Logo de la République Française"
                    style={{ width: isLg ? 68 : 68 / 2, height: isLg ? 36 : 36 / 2 }}
                  />
                </a>
                <a href="https://diagoriente.beta.gouv.fr/toutes-tous-volontaires">
                  <img
                    src={LogoBetagouv}
                    alt="Logo de Beta.gouv.fr"
                    style={{ width: isLg ? 80 : 80 / 2, height: isLg ? 14 : 14 / 2 }}
                  />
                </a>
              </>
            ) : (
              <>
                <div className="flex flex-row items-center justify-center lg:space-x-4 xl:space-x-6">
                  <Link to="/">
                    <img
                      src={LogoDiagoriente}
                      alt="Logo de Diagoriente"
                      style={{ width: isLg ? 155 : 155 / 2, height: isLg ? 37 : 37 / 2 }}
                    />
                  </Link>
                  <Link to="/">
                    <img
                      src={LogoRepubliqueSvg}
                      alt="Logo de la République Française"
                      style={{ width: isLg ? 68 : 68 / 2, height: isLg ? 36 : 36 / 2 }}
                    />
                  </Link>
                  <Link to="/">
                    <img
                      src={LogoBetagouv}
                      alt="Logo de Beta.gouv.fr"
                      style={{ width: isLg ? 80 : 80 / 2, height: isLg ? 14 : 14 / 2 }}
                    />
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default AppHeaderJO2024Desktop;
