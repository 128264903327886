import { gql, MutationHookOptions } from '@apollo/client';
import { useLocalMutation } from 'common/hooks/apollo';
import { AppFlavor } from 'contexts/AppFlavorContext';
import { User, LocationUser, Token } from './types';

export const registerMutation = gql`
  mutation Register(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $structure: String
    $code: String
    $location: LocationInput!
    $appFlavor: String
    $utm: UTMInput
    $jo2024ProfilePersona: String
    $captchaToken: String!
    $gender: String
  ) {
    register(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      code: $code
      location: $location
      structure: $structure
      appFlavor: $appFlavor
      utm: $utm
      jo2024ProfilePersona: $jo2024ProfilePersona
      gender: $gender
      captchaToken: $captchaToken
    ) {
      status
      user {
        id
        email
        role
        firstName
        lastName
        location {
          lat
          lng
          address
        }
        registrationUtm {
          source
          campaign
          medium
        }
        skills {
          id
          domain
          startDate
          intitule
          endDate
          theme {
            id
            title
            domain
          }
          extraActivity
          activities {
            title
          }
          createdAt
          updatedAt
          recommendations {
            id
            firstName
            lastName
            status
            location
            response
            message
            email
            structure
          }
        }
        volunteers {
          id
          createdAt
          updatedAt
          extraOption
          intitule
          theme {
            id
            title
          }
          activities {
            options {
              title
            }
          }
          competences {
            id
            title
            levels {
              title
              subTitle
            }
          }
          startDate
          endDate
          recommendations {
            id
            firstName
            lastName
            status
            location
            response
            message
            email
            structure
          }
        }
        isPasswordless
        jo2024ProfilePersona
        gender
        birthdate
      }
      token {
        tokenType
        accessToken
        refreshToken
        expiresIn
      }
    }
  }
`;
export interface RegisterArguments {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  location: LocationUser;
  group?: string;
}
export interface RegisterData {
  user: User;
  token: Token;
}

export const useRegister = (options: MutationHookOptions<{ register: RegisterData }, RegisterArguments> = {}) =>
  useLocalMutation(registerMutation, options);

export const loginMutation = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id
        email
        role
        firstName
        lastName
        registrationUtm {
          source
          campaign
          medium
        }
        jobsSettings {
          accessLevel
        }
        interests {
          interest {
            title
            id
            cursors {
              title
              id
            }
            images
          }
          cursors {
            id
            title
          }
        }
        interestsV2 {
          mode
          selectedInterestsV2
        }
        location {
          lat
          lng
          address
        }
        skills {
          id
          createdAt
          updatedAt
          domain
          theme {
            id
            title
            domain
          }
          intitule
          recommendations {
            id
            firstName
            lastName
            status
            location
            response
            message
            email
            structure
          }
          startDate
          endDate
          extraActivity
          activities {
            title
          }
        }
        volunteers {
          createdAt
          updatedAt
          id
          intitule
          theme {
            id
            title
          }
          startDate
          endDate
          recommendations {
            id
            firstName
            lastName
            status
            location
            response
            message
            email
            structure
          }
          extraOption
          activities {
            options {
              title
            }
          }
        }
        cvNews {
          id
          title
        }
        isPasswordless
        jo2024ProfilePersona
        gender
        birthdate
      }
      token {
        tokenType
        accessToken
        refreshToken
        expiresIn
      }
      status
    }
  }
`;

export type AuthStatus =
  | 'SUCCESS'
  | 'UNKNOWN_ERROR'
  | 'EMAIL_ALREADY_EXISTS'
  | 'INVALID_TOKEN'
  | 'INVALID_NONCE'
  | 'INVALID_CREDENTIALS'
  | 'TOO_MANY_ATTEMPTS';

export interface LoginArguments {
  email: string;
  password: string;
}
export interface LoginData {
  user: User;
  token: Token;
  status: AuthStatus;
}

export const useLogin = (options: MutationHookOptions<{ login: LoginData }, LoginArguments> = {}) =>
  useLocalMutation(loginMutation, options);

export const refreshMutation = gql`
  mutation Refresh($email: String!, $refreshToken: String!) {
    refresh(email: $email, refreshToken: $refreshToken) {
      user {
        id
        email
        registrationUtm {
          source
          campaign
          medium
        }
        location {
          lat
          lng
          address
        }
        role
        jobsSettings {
          accessLevel
        }
        interests {
          interest {
            title
            id
            cursors {
              title
              id
            }
            images
          }
          cursors {
            id
            title
          }
        }
        interestsV2 {
          mode
          selectedInterestsV2
        }
        firstName
        lastName
        skills {
          id
          domain
          startDate
          intitule
          endDate
          theme {
            id
            title
            domain
          }
          extraActivity
          activities {
            title
          }
          createdAt
          updatedAt
          recommendations {
            id
            firstName
            lastName
            status
            location
            response
            message
            email
            structure
          }
        }
        volunteers {
          id
          createdAt
          updatedAt
          extraOption
          intitule
          theme {
            id
            title
          }
          activities {
            options {
              title
            }
          }
          competences {
            id
            title
            levels {
              title
              subTitle
            }
          }
          startDate
          endDate
          recommendations {
            id
            firstName
            lastName
            status
            location
            response
            message
            email
            structure
          }
        }
        cvNews {
          id
          title
        }
        isPasswordless
        jo2024ProfilePersona
        gender
        birthdate
      }
      token {
        tokenType
        accessToken
        refreshToken
        expiresIn
      }
      status
    }
  }
`;

export interface RefreshArguments {
  email: string;
  refreshToken: string;
  status?: AuthStatus;
}

export const useRefresh = (options: MutationHookOptions<LoginData, RefreshArguments> = {}) =>
  useLocalMutation<LoginData, RefreshArguments>(refreshMutation, options);

export const logoutMutation = gql`
  mutation logout {
    logout
  }
`;
export interface LogoutData {
  logout: string;
}
export const useLogout = (options: MutationHookOptions<{ logout: LogoutData }> = {}) =>
  useLocalMutation(logoutMutation, options);

export type ForgotPassword = {
  requestPasswordReset: string;
};

export const requestPasswordReset = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`;
export const useForgotPassword = (options: MutationHookOptions<ForgotPassword> = {}) =>
  useLocalMutation(requestPasswordReset, options);

export type ForgotPasswordValidation = {
  resetPassword: string;
};

export const requestPasswordResetValidation = gql`
  mutation resetPassword($token: String!, $password: String!, $confirmPassword: String!) {
    resetPassword(token: $token, password: $password, confirmPassword: $confirmPassword)
  }
`;
export const useForgotPasswordValidation = (options: MutationHookOptions<ForgotPasswordValidation> = {}) =>
  useLocalMutation(requestPasswordResetValidation, options);

export type ForgotPasswordVerify = {
  verifyResetPassword: string;
};

export const requestPasswordResetVerify = gql`
  mutation verifyResetPassword($token: String!) {
    verifyResetPassword(token: $token)
  }
`;
export const useForgotPasswordVerify = (options: MutationHookOptions<ForgotPasswordVerify> = {}) =>
  useLocalMutation(requestPasswordResetVerify, options);

export const loginByPartnerMutation = gql`
  mutation LoginByPartner($token: String!) {
    loginByPartner(token: $token) {
      status
      user {
        id
        email
        registrationUtm {
          source
          campaign
          medium
        }
        location {
          lat
          lng
          address
        }
        role
        jobsSettings {
          accessLevel
        }
        interests {
          interest {
            title
            id
            cursors {
              title
              id
            }
            images
          }
          cursors {
            id
            title
          }
        }
        interestsV2 {
          mode
          selectedInterestsV2
        }
        firstName
        lastName
        skills {
          id
          domain
          startDate
          intitule
          endDate
          theme {
            id
            title
            domain
          }
          extraActivity
          activities {
            title
          }
          createdAt
          updatedAt
          recommendations {
            id
            firstName
            lastName
            status
            location
            response
            message
            email
            structure
          }
        }
        volunteers {
          id
          createdAt
          updatedAt
          extraOption
          intitule
          theme {
            id
            title
          }
          activities {
            options {
              title
            }
          }
          competences {
            id
            title
            levels {
              title
              subTitle
            }
          }
          startDate
          endDate
          recommendations {
            id
            firstName
            lastName
            status
            location
            response
            message
            email
            structure
          }
        }
        cvNews {
          id
          title
        }
        isPasswordless
        jo2024ProfilePersona
        gender
        birthdate
      }
      token {
        expiresIn
        accessToken
        refreshToken
        tokenType
      }
    }
  }
`;

export const useLoginByPartner = (options: MutationHookOptions<any, { token: string }> = {}) =>
  useLocalMutation(loginByPartnerMutation, options);

export const authLoginPeMutation = gql`
  mutation LoginPoleEmploi($code: String!, $origin: String!, $flavor: String, $nonce: String!) {
    login: loginPoleEmploi(code: $code, origin: $origin, nonce: $nonce, flavor: $flavor) {
      user {
        id
        email
        role
        firstName
        lastName
        registrationUtm {
          source
          campaign
          medium
        }
        jobsSettings {
          accessLevel
        }
        interestsV2 {
          mode
          selectedInterestsV2
        }
        interests {
          interest {
            title
            id
            cursors {
              title
              id
            }
            images
          }
          cursors {
            id
            title
          }
        }
        location {
          lat
          lng
          address
        }
        skills {
          id
          createdAt
          updatedAt
          domain
          theme {
            id
            title
            domain
          }
          intitule
          recommendations {
            id
            firstName
            lastName
            status
            location
            response
            message
            email
            structure
          }
          startDate
          endDate
          extraActivity
          activities {
            title
          }
        }
        volunteers {
          createdAt
          updatedAt
          id
          intitule
          theme {
            id
            title
          }
          startDate
          endDate
          recommendations {
            id
            firstName
            lastName
            status
            location
            response
            message
            email
            structure
          }
          extraOption
          activities {
            options {
              title
            }
          }
        }
        cvNews {
          id
          title
        }
        isPasswordless
        jo2024ProfilePersona
        gender
        birthdate
      }
      token {
        tokenType
        accessToken
        refreshToken
        expiresIn
      }
      status
    }
  }
`;

export type PoleEmploiLoginResult = {
  login: LoginData;
};

export type PoleEmploiLoginVariables = {
  code: string;
  nonce: string;
  origin: string;
  flavor?: AppFlavor;
};

export const usePoleEmploiLogin = (
  options: MutationHookOptions<PoleEmploiLoginResult, PoleEmploiLoginVariables> = {},
) => useLocalMutation(authLoginPeMutation, options);
