import React, { useContext } from 'react';
import { ReactComponent as StarSvg } from 'assets/svg/navigation/star_m.svg';
import CvRenderContext from 'containers/SkillCardV4Container/contexts/CvRenderContext';
import classNames from 'common/utils/classNames';

type Props = {
  count: number | undefined;
};

const SkillRecommendationIndicator = ({ count }: Props) => {
  const renderCtx = useContext(CvRenderContext);
  if (!count) return null;

  const isEditorMode = !renderCtx.config.preview && !renderCtx.config.print;

  const handleClick = (e: React.MouseEvent) => {
    if (isEditorMode) {
      document.getElementById('cv-editor-recommendation-page')?.scrollIntoView({ behavior: 'smooth' });
      e.stopPropagation();
    }
  };

  return (
    <div
      className={classNames(
        'flex items-center space-x-2 font-bold py-1 px-1',
        isEditorMode && 'hover:bg-lena2021-corail hover:bg-opacity-20',
      )}
      onClick={handleClick}
    >
      <StarSvg height={12} width={12} />
      <div>Expérience recommandée {isEditorMode && `(${count})`}</div>
    </div>
  );
};

export default SkillRecommendationIndicator;
