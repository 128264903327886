import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import useBoolean from 'common/hooks/useBoolean';

import CardExperienceForSelfeval from 'containers/SelfevalContainer/components/CardExperienceForSelfeval';

import { Button, Checkbox, Modal } from 'components/design-system';
import AppLoader from 'components/ui/AppLoader';

import ModalSelfevalIntro from '../components/ModalSelfevalIntro';
import translateExperienceType from 'utils/translateExperienceType';
import MasonryWrapper from 'components/MasonryWrapper';
import useTutorialSystem, { TUTORIAL_KEYS } from 'hooks/useTutorialSystem';
import { Skill } from 'common/requests/types';
import { useListSkillsForSelfevalLazyQuery } from '../requests/ListSkillsForSelfeval';

const SelfevalHome = () => {
  const navigate = useNavigate();
  const hideAlreadyEvaluated = useBoolean(true);
  const showModalWelcome = useBoolean(false);
  const tutorialSystem = useTutorialSystem();
  const [callMySkills, mySkillsState] = useListSkillsForSelfevalLazyQuery({
    initialFetchPolicy: 'network-only',
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    callMySkills();
  }, []);

  const experiences = useMemo(() => {
    const skills = mySkillsState.data?.me?.skills || [];

    return Object.values(
      skills.reduce((acc: any, cur: any) => {
        const experiences = skills.filter((skill: Skill) => skill.domain == cur.domain);

        return {
          ...acc,
          [cur.domain]: {
            title: `Mes expériences ${translateExperienceType(cur.domain).plural}`,
            items: experiences.filter((v: Skill) =>
              hideAlreadyEvaluated.value ? !v.selfeval?.createdAt : true,
            ) as any[],
          },
        };
      }, {}),
    );
  }, [mySkillsState.data, hideAlreadyEvaluated]);

  useEffect(() => {
    if (!tutorialSystem.hasTutorialFlag(TUTORIAL_KEYS.RECTEC_SELFEVAL_INTRO)) showModalWelcome.on();
  }, [tutorialSystem.hasTutorialFlag, mySkillsState.data]);

  return (
    <>
      <div className="container space-y-4 p-4">
        <div className="flex my-8 justify-between">
          <h2 className="font-bold text-2xl">
            Sélectionnez les expériences pour lesquelles
            <br />
            vous souhaitez évaluer vos compétences transversales
          </h2>

          <div className="flex-col space-y-2 text-right space-x-4">
            {experiences.length > 0 && (
              <Button variant="secondary" size="sm" onClick={() => navigate('/evaluer-mes-competences/preview')}>
                Voir ma carte de compétences
              </Button>
            )}

            <Button variant="secondary" size="sm" onClick={() => navigate('/ajout-exp?parcours_scope=selfeval')}>
              Ajouter une expérience
            </Button>
          </div>
        </div>

        <Checkbox
          checked={hideAlreadyEvaluated.value}
          onClick={hideAlreadyEvaluated.toggle}
          label="Masquer les expériences déjà évaluées"
        />

        <div className="bg-lena-lightgray px-8 py-8 space-y-4 rounded-md">
          {mySkillsState.loading ? (
            <AppLoader />
          ) : (
            <div className="flex flex-col gap-2">
              {experiences.flatMap((group: any) => group.items).length <= 0 && (
                <p>Vous n'avez aucune expérience à évaluer pour le moment.</p>
              )}

              {experiences.length >= 0 && (
                <MasonryWrapper breakpoints={{ base: 1, md: 2 }} gutter={16}>
                  {experiences?.map((group: any, i: number) => {
                    if (group.items.length <= 0) return;

                    return (
                      <div key={i}>
                        <h2 className="font-bold mb-4 text-xl">{group.title}</h2>

                        <div className="space-y-4">
                          {group.items.map((v: any) => {
                            return (
                              <CardExperienceForSelfeval
                                key={v.id}
                                startDate={v.startDate}
                                endDate={v.endDate}
                                skillId={v.id}
                                title={v.intitule || v.theme.title}
                                structure={v.structure}
                                selfevalAt={v.selfeval?.createdAt}
                              />
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </MasonryWrapper>
              )}
            </div>
          )}
        </div>
      </div>

      <Modal
        open={showModalWelcome.value}
        type="dialog"
        allowOutsideClick={false}
        hideClose={true}
        onClose={() => showModalWelcome.off()}
      >
        <ModalSelfevalIntro
          onAccept={() => {
            showModalWelcome.off();
            tutorialSystem.setTutorialFlag(TUTORIAL_KEYS.RECTEC_SELFEVAL_INTRO);
          }}
          onBack={() => navigate('/')}
        />
      </Modal>
    </>
  );
};

export default SelfevalHome;
