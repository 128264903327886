import { useEffect, useMemo } from 'react';
import { uniqBy } from 'lodash-es';
import { Skill, ThemeDomain } from 'common/requests/types';
import { useListSkills, useListVolunteersSkills } from 'common/requests/skills';

export const SKILL_TYPES: ThemeDomain[] = [
  'personal',
  'professional',
  'voluntary',
  'sport_amateur',
  'sport_pro',
  'entrepreneurship',
  'militant',
  'insertion',
];

export default function useCv(id?: string | null) {
  const [fetchSkills, skillsState] = useListSkills({ fetchPolicy: 'network-only' });
  const [fetchVolunteers, volunteersState] = useListVolunteersSkills({ fetchPolicy: 'network-only' });

  useEffect(() => {
    fetchSkills();
    fetchVolunteers();
  }, []);

  const cv = useMemo(() => {
    return null;
  }, [id]);

  const allSkills = useMemo(() => {
    const data = {} as { [K in ThemeDomain]: Skill[] };

    SKILL_TYPES.forEach((type) => {
      data[type] = skillsState.data ? skillsState.data.skills.data.filter((skill) => skill.domain === type) : [];
    });

    return data;
  }, [skillsState.data]);

  const allVolunteers = useMemo(() => {
    if (!volunteersState.data) return [];
    const { data } = volunteersState.data.volunteers;

    return data;
  }, [volunteersState.data]);

  const skills = useMemo(() => {
    const data = {} as { [K in ThemeDomain]: Skill[] };

    SKILL_TYPES.forEach((type) => {
      data[type] = allSkills[type];
    });

    return data;
  }, [allSkills, cv]);

  const volunteers = useMemo(() => {
    return allVolunteers;
  }, [allVolunteers]);

  const competences = useMemo(() => {
    const macroCompetences = uniqBy(
      Object.entries(allSkills)
        .map(([, skillList]) =>
          skillList
            .map((skill) =>
              skill.activities
                .map((activity) => activity.competences.map((c) => ({ ...c, activity: activity.title })))
                .flat(),
            )
            .flat(),
        )
        .flat(),

      (c) => c.id,
    ).map((c) => ({ ...c, cmpType: 'macro' }));

    return {
      technique: macroCompetences.filter((c) => c.type === 'technique'),
      transversal: [...macroCompetences.filter((c) => c.type !== 'technique')],
    };
  }, [skills, volunteers]);
  return { cv, skills, volunteers, competences, listCvs: [] };
}
