import React, { useMemo } from 'react';
import { Skill } from 'common/requests/types';
import { SelfevalCard } from 'containers/SelfevalContainer/components/SelfevalCard';

type Props = {
  skills?: Skill[];
  globalCompetences?: { id: string; title: string; level: number; levelDescription: string }[];
  user?: { firstName?: string; lastName?: string };
};

const InitializeSelfevalCard: React.FC<Props> = ({ skills, globalCompetences, user }) => {
  const reformattedSkills = useMemo(() => {
    return (
      skills?.map((sk: Skill) => ({
        id: sk.id,
        activities: [...sk.activities.map((a) => a.title), ...sk.extraActivity],
        title: sk.intitule || sk.theme.title,
        domain: sk.domain,
      })) || []
    );
  }, [skills]);

  return (
    <SelfevalCard
      globalCompetences={globalCompetences || []}
      experiences={reformattedSkills}
      user={{ firstName: user?.firstName || '', lastName: user?.lastName || '' }}
    />
  );
};

export default InitializeSelfevalCard;
