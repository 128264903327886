import React, { Fragment, useState, useEffect, useRef, ReactNode, ReactElement, useCallback } from 'react';
import {
  arrow,
  offset,
  flip,
  shift,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss,
} from '@floating-ui/react-dom-interactions';
import { autoUpdate } from '@floating-ui/dom';

import clsx from 'clsx';

type TargetBySelector = {
  type: 'selector';
  target: string;
};

type TargetByRef = {
  type: 'ref';
  target: React.RefObject<HTMLElement | null>;
};

type TargetByChildren = {
  type: 'children';
  children: ReactElement;
};

export type TooltipProps = {
  text: ReactNode;
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
  showArrow?: boolean;
} & (TargetByRef | TargetByChildren | TargetBySelector);

const Tooltip = (props: TooltipProps) => {
  const { text, type, showArrow = true, mouseEnterDelay = 0, mouseLeaveDelay = 0 } = props;

  const arrowRef = useRef(null);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (type === 'children') return;
    if (type === 'ref') return reference(props.target?.current);
    if (type === 'selector') return reference(document.querySelector(props.target));
  }, [type, props]);

  const { x, y, reference, floating, strategy, context, placement, middlewareData, update } = useFloating({
    open: show,
    placement: 'bottom',
    onOpenChange: setShow,
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    middleware: [
      offset(12),
      flip({
        fallbackPlacements: ['top', 'bottom', 'left', 'right'],
      }),
      shift({ padding: 10 }),
      arrow({ element: arrowRef }),
    ],
  });

  const { getFloatingProps } = useInteractions([
    useHover(context, {
      delay: {
        open: mouseEnterDelay,
        close: mouseLeaveDelay,
      },
    }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  const arrowCallback = useCallback(
    (node: any) => {
      arrowRef.current = node;

      update();
    },
    [update],
  );

  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[placement.split('-')[0]];

  return (
    <Fragment>
      {type == 'children' && (
        <div data-testid="wrapper-children" ref={reference}>
          {props.children}
        </div>
      )}

      {show && (
        <div
          ref={floating}
          data-testid="tooltip"
          className="inline text-[#333] bg-white border border-lena-lightgray px-6 py-4 rounded-md shadow-sm max-w-md"
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            zIndex: 1000,
          }}
          {...getFloatingProps()}
        >
          {showArrow && (
            <div
              data-testid="arrow"
              className={clsx('arrow absolute w-3 h-3 bg-white rotate-45 border-lena-lightgray', {
                'border-t border-l': staticSide === 'top',
                'border-b border-r': staticSide === 'bottom',
                'border-l border-b': staticSide === 'left',
                'border-t border-r': staticSide === 'right',
              })}
              ref={arrowCallback}
              style={{
                left: middlewareData.arrow?.x != null ? `${middlewareData.arrow.x}px` : '',
                top: middlewareData.arrow?.y != null ? `${middlewareData.arrow.y}px` : '',
                [staticSide as string]: '-7px',
              }}
            />
          )}

          <div data-testid="tooltip-text">{text}</div>
        </div>
      )}
    </Fragment>
  );
};

export default Tooltip;
