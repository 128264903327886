import { Dispatch, SetStateAction, useState } from 'react';

interface ReturnType {
  value: boolean;
  set: Dispatch<SetStateAction<boolean>>;
  on: () => void;
  off: () => void;
  toggle: () => void;
}

function useBoolean(defaultValue?: boolean): ReturnType {
  const [value, setValue] = useState(!!defaultValue);

  const on = () => setValue(true);
  const off = () => setValue(false);
  const toggle = () => setValue((x) => !x);

  return { value, set: setValue, on, off, toggle };
}

export default useBoolean;
