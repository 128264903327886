import { gql, MutationHookOptions, QueryHookOptions, useMutation } from '@apollo/client';

import { useLocalLazyQuery, useLocalMutation, useLocalQuery } from 'common/hooks/apollo';
import {
  PublicSkill,
  Skill,
  SkillList,
  SkillVolunteersList,
  Volunteers,
  Options,
  Theme,
  Competence,
  Recommendation,
} from './types';

export const addSkillMutation = gql`
  mutation CreateSkill(
    $theme: ObjectID!
    $activities: [ObjectID!]
    $startDate: Date
    $endDate: Date
    $extraActivity: [String]
    $structure: String
    $attachToCv: ObjectID
    $intitule: String
  ) {
    createSkill(
      theme: $theme
      activities: $activities
      startDate: $startDate
      endDate: $endDate
      extraActivity: $extraActivity
      structure: $structure
      attachToCv: $attachToCv
      intitule: $intitule
    ) {
      id
      domain
      startDate
      endDate
      extraActivity
      createdAt
      structure
      intitule
      recommendations {
        id
        skill
        status
        email
        lastName
        firstName
        message
        response
        location
        structure
      }
      theme {
        id
        title
        domain
      }
      activities {
        title
      }
    }
  }
`;

export interface AddSkillArgument {
  theme: string;
  activities?: string[];
  startDate?: string;
  endDate?: string;
  extraActivity?: string[];
  intitule?: string;
}

export interface SkillAddResponse {
  createSkill: Skill;
}

export const useAddSkill = (options: MutationHookOptions<SkillAddResponse, AddSkillArgument> = {}) =>
  useLocalMutation<SkillAddResponse, AddSkillArgument>(addSkillMutation, options);

export const addSkillVolunteer = gql`
  mutation CreateSkillVolunteer(
    $theme: ObjectID!
    $competences: [ObjectID!]!
    $startDate: Date
    $endDate: Date
    $options: [[ObjectID!]]
    $extraOption: [String]
    $structure: String
    $ratings: [VolunteerRatingInput!]
  ) {
    createVolunteer(
      theme: $theme
      competences: $competences
      startDate: $startDate
      endDate: $endDate
      options: $options
      extraOption: $extraOption
      structure: $structure
      ratings: $ratings
    ) {
      id
      createdAt
      updatedAt
      user {
        id
      }
      theme {
        id
        title
        domain
      }
      activities {
        options {
          id
          title
        }
      }
      competences {
        id
        title
        levels {
          title
          subTitle
        }
      }
      ratings {
        value
      }
      startDate
      endDate
      extraOption
      structure
    }
  }
`;

export type AddSkillVolunteerVariables = {
  theme: string;
  competences: string[];
  startDate?: string;
  endDate?: string;
  options?: string[][];
  extraOption?: string[];
  structure?: string;
  intitule?: string;
  ratings?: { competence: string; value: number }[];
};

export type AddSkillVolunteerPayload = {
  createVolunteer: {
    id: string;
    user: string;
    theme: Theme;
    activities: { options: Options[] }[];
    competences: Competence[];
    startDate: string;
    endDate: string;
    extraOption: string[];
    structure: string;
    recommendations: Recommendation[];
    ratings: {
      competence: Partial<Competence>;
      value: number;
    }[];
    createdAt: string;
    updatedAt: string;
  };
};

export const useAddSkillVolunteer = (
  options: MutationHookOptions<AddSkillVolunteerPayload, AddSkillVolunteerVariables> = {},
) => useLocalMutation<AddSkillVolunteerPayload, AddSkillVolunteerVariables>(addSkillVolunteer, options);

export const skillsQuery = gql`
  query ListMySkills($domain: ThemeDomain) {
    skills(domain: $domain) {
      data {
        id
        domain
        theme {
          title
          id
        }
        intitule
        activities {
          id
          title
          competences {
            title
            type
            id
            levels {
              title
              subTitle
            }
          }
        }
        extraActivity
        createdAt
        startDate
        endDate
        structure
        recommendations {
          id
          skill
          status
          email
          lastName
          firstName
          message
          response
          location
          structure
        }
      }
    }
  }
`;

export interface SkillsResponse {
  skills: SkillList;
}

export const useListSkills = (options: QueryHookOptions<SkillsResponse, { domain?: string }> = {}) =>
  useLocalLazyQuery<SkillsResponse>(skillsQuery, options);

export const skillsVolunteerQuery = gql`
  query ListMyVolunteers {
    volunteers {
      data {
        id
        theme {
          title
          id
        }
        activities {
          options {
            title
          }
        }
        intitule
        competences {
          title
          id
          levels {
            title
            subTitle
          }
        }
        createdAt
        recommendations {
          id
          skill
          status
          email
          lastName
          firstName
          message
          response
          location
          structure
        }
        extraOption
        startDate
        endDate
        structure
      }
    }
  }
`;

export interface SkillsVolunteersResponse {
  volunteers: SkillVolunteersList;
}

export const useListVolunteersSkills = (options: QueryHookOptions<SkillsVolunteersResponse> = {}) =>
  useLocalLazyQuery<SkillsVolunteersResponse>(skillsVolunteerQuery, options);

export const skillQuery = gql`
  query GetSkillInfo($id: ObjectID!) {
    skill(id: $id) {
      id
      domain
      theme {
        title
        id
      }
      intitule
      structure
      createdAt
      activities {
        id
        title
        competences {
          title
          type
          id
          levels {
            title
            subTitle
          }
        }
      }
      extraActivity
      startDate
      endDate
      recommendations {
        id
        skill
        status
        email
        lastName
        firstName
        message
        response
        location
        structure
      }
    }
  }
`;

export interface SkillResponse {
  skill: Skill;
}

export const useSkill = (options: QueryHookOptions<SkillResponse> = {}) =>
  useLocalLazyQuery<SkillResponse>(skillQuery, options);

// volunteer
export const skillServiceQuery = gql`
  query Volunteer($id: ObjectID!) {
    volunteer(id: $id) {
      id
      intitule
      theme {
        title
        id
        reference {
          id
          competences {
            id
            title
            levels {
              title
              subTitle
            }
          }
        }
      }
      structure
      activities {
        options {
          id
          title
        }
      }
      createdAt
      extraOption
      startDate
      endDate
      competences {
        id
        title
        levels {
          title
          subTitle
        }
      }
      ratings {
        competence {
          id
          title
          levels {
            title
          }
        }
        value
      }
      recommendations {
        id
        skill
        status
        email
        lastName
        firstName
        message
        response
        location
        structure
      }
    }
  }
`;
export interface SkillServiceResponse {
  volunteer: Volunteers;
}
export const useSkillVolunteer = (options: QueryHookOptions<SkillServiceResponse> = {}) =>
  useLocalLazyQuery<SkillServiceResponse>(skillServiceQuery, options);

export interface PublicSkillArguments {
  id: string;
  secret: string;
}

export const getPublicSkillRecoQuery = gql`
  query PublicSkill($id: ObjectID!, $secret: String!) {
    publicSkill(id: $id, secret: $secret) {
      id
      user {
        firstName
        lastName
      }
      domain
      recommendations {
        status
        firstName
        lastName
      }
    }
  }
`;

export const useGetSkillReco = (options: QueryHookOptions<{ publicSkill: PublicSkill }, PublicSkillArguments>) =>
  useLocalQuery(getPublicSkillRecoQuery, options);

export const removeSkillMutation = gql`
  mutation RemoveMySkill($id: ObjectID!) {
    removeSkill(id: $id)
  }
`;

export const useRemoveSkill = (options: MutationHookOptions<any, { id: string }> = {}) =>
  useLocalMutation(removeSkillMutation, options);

export const updateSkillMutation = gql`
  mutation UpdateSkill(
    $id: ObjectID!
    $activities: [ObjectID!]
    $startDate: Date
    $endDate: Date
    $extraActivity: [String]
    $structure: String
    $intitule: String
  ) {
    updateSkill(
      id: $id
      activities: $activities
      startDate: $startDate
      endDate: $endDate
      extraActivity: $extraActivity
      structure: $structure
      intitule: $intitule
    ) {
      id
      domain
      startDate
      endDate
      extraActivity
      structure
      createdAt
      updatedAt
      intitule
      recommendations {
        id
        skill
        status
        email
        lastName
        firstName
        message
        response
        location
        structure
      }
      theme {
        id
        title
        domain
      }
      activities {
        title
      }
    }
  }
`;

export interface UpdateSkillArgument {
  id: string;
  activities?: string[];
  startDate?: string;
  endDate?: string;
  extraActivity?: string[];
  structure?: string;
  intitule?: string;
}

export interface SkillUpdateResponse {
  updateSkill: Skill;
}

export const useUpdateSkill = (options: MutationHookOptions<{ updateSkill: Skill }, UpdateSkillArgument> = {}) =>
  useLocalMutation(updateSkillMutation, options);
export const updateSkillVolunteer = gql`
  mutation UpdateSkillVolunteer(
    $id: ObjectID!
    $startDate: Date
    $endDate: Date
    $options: [[ObjectID!]]
    $extraOption: [String]
    $structure: String
    $competences: [ObjectID!]
    $theme: ObjectID
    $ratings: [VolunteerRatingInput!]
    $intitule: String
  ) {
    updateVolunteer(
      id: $id
      theme: $theme
      startDate: $startDate
      endDate: $endDate
      options: $options
      extraOption: $extraOption
      structure: $structure
      competences: $competences
      ratings: $ratings
      intitule: $intitule
    ) {
      id
      user {
        id
      }
      theme {
        title
        domain
        id
      }
      intitule
      activities {
        options {
          title
        }
      }
      competences {
        id
        title
        levels {
          title
          subTitle
        }
      }
      ratings {
        value
      }
      createdAt
      updatedAt
      startDate
      endDate
      extraOption
      structure
      recommendations {
        id
        skill
        status
        email
        lastName
        firstName
        message
        response
        location
        structure
      }
    }
  }
`;

export interface UpdateSkillVolunteerArgument {
  id: string;
  competences?: string[];
  startDate?: string;
  endDate?: string;
  extraOption?: string[];
  options?: any;
  intitule?: string;
}

export interface UpdateAddVolunteerResponse {
  updateVolunteer: Volunteers;
}

export const useUpdateSkillVolunteer = (
  options: MutationHookOptions<UpdateAddVolunteerResponse, UpdateSkillVolunteerArgument> = {},
) => useLocalMutation<UpdateAddVolunteerResponse, UpdateSkillVolunteerArgument>(updateSkillVolunteer, options);
export const removeSkillVolunteersMutation = gql`
  mutation RemoveMyVolunteer($id: ObjectID!) {
    removeVolunteer(id: $id)
  }
`;

export const useRemoveVolunteer = (options: MutationHookOptions<any, { id: string }> = {}) =>
  useLocalMutation(removeSkillVolunteersMutation, options);

export const createVolunteerBilanMutation = gql`
  mutation CreateVolunteerBilan(
    $skillId: String!
    $reviewerFirstName: String!
    $reviewerLastName: String!
    $reviewerEmail: String!
    $reviewerMessage: String!
    $bilanFormation: String!
    $bilanMission: String!
  ) {
    createVolunteerBilan(
      skillId: $skillId
      reviewerFirstName: $reviewerFirstName
      reviewerLastName: $reviewerLastName
      reviewerEmail: $reviewerEmail
      reviewerMessage: $reviewerMessage
      bilanFormation: $bilanFormation
      bilanMission: $bilanMission
    ) {
      id
    }
  }
`;

type CreateVolunteerVariables = {
  skillId: string;
  reviewerFirstName: string;
  reviewerLastName: string;
  reviewerEmail: string;
  reviewerMessage: string;
  bilanFormation: string;
  bilanMission: string;
};

export const useCreateVolunteerBilan = (
  options: MutationHookOptions<Partial<Volunteers>, CreateVolunteerVariables> = {},
) => useLocalMutation(createVolunteerBilanMutation, options);

// volunteer by token
export const volunteerByTokenQuery = gql`
  query GetVolunteerByToken($id: String!, $token: String!) {
    volunteerByToken(id: $id, token: $token) {
      id
      theme {
        title
        id
        reference {
          id
          competences {
            id
            title
            levels {
              title
              subTitle
            }
          }
        }
      }
      structure
      activities {
        options {
          id
          title
        }
      }
      createdAt
      extraOption
      startDate
      endDate
      intitule
      competences {
        id
        title
        levels {
          title
          subTitle
        }
      }
      ratings {
        competence {
          id
          title
          levels {
            title
          }
        }
        value
      }
      bilan {
        formations
        mission
        birthDate
        reviewer {
          firstName
          lastName
        }
      }
      user {
        firstName
        lastName
      }
    }
  }
`;

export const useVolunteerByToken = (options: QueryHookOptions<{ volunteerByToken: Volunteers }> = {}) =>
  useLocalLazyQuery<{ volunteerByToken: Volunteers }>(volunteerByTokenQuery, options);

export const exportBilanQuery = gql`
  query ExportVolunteerBilan($id: ObjectID!, $token: String) {
    exportBilan(id: $id, token: $token)
  }
`;

export const useExportBilan = (
  options: QueryHookOptions<{ exportBilan: string }, { id: string; token?: string }> = {},
) => useLocalLazyQuery(exportBilanQuery, options);

export const updateBilanMutation = gql`
  mutation UpdateVolunteerBilan($id: ObjectID!, $token: String!, $comment: String, $extraSkills: String) {
    updateVolunteerBilan(comment: $comment, extraSkills: $extraSkills, skillId: $id, token: $token) {
      id
    }
  }
`;

type UpdateBilanVariables = {
  id: string;
  token: string;
  extraSkills: string;
  comment: string;
};

export const useUpdateBilan = (
  options: MutationHookOptions<{ updateVolunteerBilan: Volunteers }, UpdateBilanVariables> = {},
) => useMutation(updateBilanMutation, options);
