import React, { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import UserContext from 'common/contexts/UserContext';
import { encodeUri } from 'common/utils/url';

type Props = { protect?: boolean; unauthenticatedOnly?: boolean; authorizedRole?: string };

const RouteWrapper6: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  protect = false,
  unauthenticatedOnly = false,
  authorizedRole = 'user',
}) => {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [searchParams] = useSearchParams();

  if (protect) {
    if (!user && authorizedRole === 'user') {
      const encodedParams =
        location.pathname === '/deconnexion' ? '' : encodeUri({ from: location.pathname + location.search });

      return <Navigate to={`/connexion${encodedParams}`} replace />;
    }
  }

  if (user && unauthenticatedOnly) {
    const fromUrl = searchParams.has('from') && searchParams.get('from');
    if (!fromUrl || fromUrl === '/deconnexion') return <Navigate to="/" replace />;
    else return <Navigate to={fromUrl} replace />;
  }

  if ((!user || user.role === 'user') && authorizedRole === 'admin') {
    return <Navigate to="/404" />;
  }
  if ((!user || user.role === 'user') && authorizedRole === 'advisor') {
    return <Navigate to="/404" />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default RouteWrapper6;
