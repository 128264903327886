import React, { FC } from 'react';
import { getTrackBackground, Range } from 'react-range';

export type SliderProps = {
  value: number;
  min?: number;
  max?: number;
  step?: number;
  onChange?: (e: number) => void;
  valueFormatter?: (value: number) => string;
};

const Slider: FC<SliderProps> = ({ value, min = 0, max = 100, step = 1, onChange, valueFormatter }) => {
  return (
    <Range
      step={step}
      min={min}
      max={max}
      values={[value]}
      onChange={(v) => onChange?.(v[0])}
      renderTrack={({ props, children }) => (
        <div>
          <div
            {...props}
            style={{
              ...props.style,
              height: '6px',
              width: '100%',
              background: getTrackBackground({
                values: [value],
                colors: ['#FF926F', '#D1D1D1'],
                min,
                max,
              }),
            }}
          >
            {children}
          </div>
          <div className="flex justify-between items-center mt-4">
            <span>{valueFormatter ? valueFormatter(min) : min}</span>
            <span>{valueFormatter ? valueFormatter(max) : max}</span>
          </div>
        </div>
      )}
      renderThumb={({ props, value: currValue }) => (
        <div
          {...props}
          style={{
            height: '22px',
            width: '22px',
          }}
          className="rounded-full bg-white flex justify-center items-center shadow-lg border-1 border-lena2021-gray-light focus:ring-0 focus:border-0 active:border-0 focus-visible:border-lena2021-corail-dark"
        >
          <div
            style={{
              position: 'absolute',
              top: '-33px',
              fontSize: '12px',
              padding: '4px',
              borderRadius: '4px',
            }}
            className="bg-lena2021-corail text-center z-100 w-max"
          >
            <span className="text-lena-blue-dark font-bold">{valueFormatter ? valueFormatter(currValue) : value}</span>
          </div>
        </div>
      )}
    />
  );
};

export default Slider;
