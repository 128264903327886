import React, { FunctionComponent, PropsWithChildren, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'common/utils/classNames';
import { ReactComponent as CrossIcon } from 'assets/svg/legacy/cross.svg';
import useOnClickOutside from 'common/hooks/useOnClickOutside';
import FocusLock from 'react-focus-lock';

export type ModalProps = {
  open: boolean;
  onClose?: () => void;
  type?: 'modal' | 'dialog';
  hideClose?: boolean;
  useRelativePosition?: boolean;
  allowOutsideClick?: boolean;
  enableGlassEffect?: boolean;
};

const Modal: FunctionComponent<PropsWithChildren<ModalProps>> = ({
  open,
  hideClose,
  type = 'modal',
  children,
  onClose,
  allowOutsideClick = true,
  enableGlassEffect = true,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const outsideClick = allowOutsideClick || type === 'modal';

  const escFunction = useCallback((event: KeyboardEvent) => event.keyCode === 27 && onClose?.(), [onClose]);

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', escFunction);
    }
    return () => document.removeEventListener('keydown', escFunction);
  }, [open]);

  useOnClickOutside(ref, () => {
    if (open && outsideClick) onClose?.();
  });

  if (!open) return null;

  return ReactDOM.createPortal(
    <FocusLock>
      <div
        role="alertdialog"
        aria-modal={type === 'modal' && outsideClick}
        aria-describedby="dialog_desc"
        className={classNames(
          'bg-lena-blue-dark bg-opacity-50 z-100 fixed top-0 bottom-0 right-0 left-0',
          'flex flex-col items-center justify-center',
          enableGlassEffect && 'backdrop-filter backdrop-blur-sm backdrop-contrast-125',
          'modal', // Do not remove this class, it is used to apply overflow-hidden on the body
        )}
      >
        <div
          ref={ref}
          className={classNames(
            'relative p-4 overflow-y-auto overflow-x-hidden my-4',
            'w-full md:w-3/4 lg:w-3/5 xl:w-1/2 2xl:w-2/5',
          )}
        >
          {(type === 'modal' || !hideClose) && (
            <button
              type="button"
              onClick={() => onClose?.()}
              className="cursor-pointer absolute right-0 top-0 p-2 rounded-full shadow-lg bg-lena-blue-dark"
            >
              <CrossIcon className="fill-current text-white" />
            </button>
          )}

          <div
            className={classNames(
              'rounded-lg h-full w-full overflow-y-auto',
              type === 'modal' && 'bg-white',
              type === 'dialog' && 'bg-lena-lightgray',
            )}
          >
            <div className="h-full">
              <div id="dialog_desc" className="p-4 h-full text-left overflow-y-auto scroll-thin">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </FocusLock>,
    document.body,
  );
};

export default Modal;
