import React, { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import createLazyComponent from 'utils/createLazyComponent';

const Formations = createLazyComponent(() => import('./FormationContainer/FormationsContainer'));
const ImmersionSearch = createLazyComponent(() => import('./ImmersionContainer/ImmersionSearch'));
const ImmersionResult = createLazyComponent(() => import('./ImmersionContainer/ImmersionSearchResults'));

const JO2024Route: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/formations" element={<Formations />} />
      <Route path="/benevolat/recherche" element={<ImmersionSearch />} />
      <Route path="/benevolat/recherche/resultats" element={<ImmersionResult />} />
    </Routes>
  );
};

export default JO2024Route;
