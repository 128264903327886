import React, { Dispatch, SetStateAction } from 'react';

export enum AppFlavor {
  Default = 'default',
  Orientation1J1S = '1j1s_orientation',
  Cv1J1S = '1j1s_cv',
  Olympics = 'jo2024',
  CEJ = 'cej',
}

type AppFlavorContextData = {
  flavor: AppFlavor | null;
  defaultFlavor: AppFlavor;
  setFlavor: Dispatch<SetStateAction<AppFlavor | null>>;
  is1J1S: boolean;
};

const AppFlavorContext = React.createContext<AppFlavorContextData>({
  flavor: null,
  defaultFlavor: AppFlavor.Default,
  setFlavor: () => {},
  is1J1S: false,
});

export default AppFlavorContext;
