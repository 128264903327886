import React from 'react';

// layout
export const StepAvatar = React.lazy(() => import('./layout/StepAvatar'));
export const StepFontSelect = React.lazy(() => import('./layout/StepFontSelect'));
export const StepColors = React.lazy(() => import('./layout/StepColors'));
export const StepTemplates = React.lazy(() => import('./layout/StepTemplates'));
export const StepContentOrder = React.lazy(() => import('./layout/StepContentOrder'));

// content
export const StepCompetence = React.lazy(() => import('./content/Competence'));
export const StepExperience = React.lazy(() => import('./content/Experience'));
export const StepBio = React.lazy(() => import('./content/StepBio'));
export const StepExtraInfo = React.lazy(() => import('./content/StepExtraInfo'));
export const StepFormation = React.lazy(() => import('./content/StepFormation'));
export const StepJobDesc = React.lazy(() => import('./content/StepJobDesc'));
export const StepKeywords = React.lazy(() => import('./content/StepKeywords'));
export const StepVolunteers = React.lazy(() => import('./content/StepVolunteers'));
