import { Dispatch, SetStateAction, createContext } from 'react';

export type EditorTab = {
  key: string;
  type: 'step' | 'dropdown';
  label: string;
  icon: any;
  items: STEP[];
};

export type ActiveStep = { tab: string | null; step: string | null };

export type STEP = {
  key: string;
  icon?: any;
  label: string;
  assistant?: JSX.Element;
  children?: () => JSX.Element;
};

type EditorModeContextData = {
  activeStep: ActiveStep;
  setActiveStep: (s: ActiveStep) => void;
  handleActiveStep: (key: string) => void;
  editorTabs: EditorTab[];
  steps: STEP[];
  showColumnEditor: boolean;
  toggleShowColumnEditor: Dispatch<SetStateAction<boolean>>;
  currentStep: number;
  currentKeyStep: string;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  prevEditorPage: () => void;
  nextEditorPage: () => void;
  setCurrentStepByKey: (s: string) => void;
  hiddenWarningHeightMax: boolean;
  setHiddenWarningHeightMax: (value: boolean) => void;
};

export const EditorModeContext = createContext<EditorModeContextData>({
  activeStep: { tab: null, step: null },
  setActiveStep: () => {},
  handleActiveStep: () => {},
  editorTabs: [],
  steps: [],
  showColumnEditor: true,
  toggleShowColumnEditor: () => {},
  currentStep: 0,
  currentKeyStep: '',
  setCurrentStep: () => {},
  prevEditorPage: () => {},
  nextEditorPage: () => {},
  setCurrentStepByKey: () => {},
  hiddenWarningHeightMax: false,
  setHiddenWarningHeightMax: () => {},
});
