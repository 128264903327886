import React from 'react';
import { HashRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { client } from 'common/requests/client';
import * as Sentry from '@sentry/react';

import './index.css';

import './utils/momentLocale';
import './utils/yupLocale';

import RootContainer from 'containers/RootContainer';
import { ToastContextProvider } from 'contexts/ToastContext';
import AppFlavorProvider from 'contexts/AppFlavorProvider';
import AppDebugBar from 'components/debug/AppDebugBar';
import AppErrorBoundary from 'components/debug/AppErrorBoundary';
import ModalProvider from './contexts/ModalProvider';
import CookieBanner from 'components/CookieBanner';

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<AppErrorBoundary />} showDialog={false} dialogOptions={{ lang: 'fr' }}>
      <AppFlavorProvider>
        <ApolloProvider client={client}>
          <HashRouter>
            <ModalProvider>
              <ToastContextProvider>
                <AppDebugBar />
                <RootContainer />
                <CookieBanner />
              </ToastContextProvider>
            </ModalProvider>
          </HashRouter>
        </ApolloProvider>
      </AppFlavorProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
