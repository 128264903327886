import React from 'react';
import { formatDateRange } from 'utils/formatDate';

type Props = {
  data: any;
};

const RecommendationItemGenericV4: React.FC<Props> = ({ data: recommendationData }) => {
  return (
    <div className="pbi-avoid">
      <strong className="flex font-semibold">
        De {`${recommendationData.firstName} ${recommendationData.lastName}`}{' '}
        {recommendationData.structure && `— ${recommendationData.structure}`}
      </strong>
      <span>
        Concernant : {recommendationData.theme.title}{' '}
        <span className="uppercase">
          {recommendationData.startDate &&
            `— ${formatDateRange(recommendationData.startDate, recommendationData.endDate)}`}
        </span>
      </span>
      <p className="mt-2">{recommendationData.response}</p>
    </div>
  );
};

export default RecommendationItemGenericV4;
