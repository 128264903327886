import React from 'react';
import { Routes, Route } from 'react-router-dom';
import createLazyComponent from 'utils/createLazyComponent';
import ProfileLayout from 'layouts/ProfileLayout/ProfileLayout';

const EditInfoContainer = createLazyComponent(() => import('./Settings/containers/EditInfoContainer'));
const ProfileSettingsContainer = createLazyComponent(() => import('./Settings/ProfileSettingsContainer'));
const EditPassword = createLazyComponent(() => import('./Settings/containers/EditPassword'));
const EditEmail = createLazyComponent(() => import('containers/ProfileContainer/Settings/containers/EditEmail'));
const MyExperiencesContainer = createLazyComponent(() => import('./MyExperiences/MyExperiencesContainer'));
const MyInterestsContainer = createLazyComponent(() => import('./Interests/MyInterestsContainer'));

const ProfileRoute = () => {
  return (
    <Routes>
      <Route path={'/*'} element={<ProfileLayout />}>
        <Route path="mes-experiences" element={<MyExperiencesContainer />} />
        <Route path="mes-centres-d-interet/" element={<MyInterestsContainer />} />
      </Route>

      <Route path={'/reglages'} element={<ProfileLayout />}>
        <Route index element={<ProfileSettingsContainer />} />
        <Route path="infos" element={<EditInfoContainer />} />
        <Route path="edit-password" element={<EditPassword />} />
        <Route path="email" element={<EditEmail />} />
      </Route>
    </Routes>
  );
};

export default ProfileRoute;
