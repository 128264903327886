import React, { FunctionComponent, useState } from 'react';
import { Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import LogoDiagoriente from 'assets/logos/diagoriente.svg';
import RepubliqueSvg from 'assets/logos/republique.svg';
import LogoBetagouv from 'assets/logos/betagouv.svg';
import classNames from 'common/utils/classNames';
import LogoLesJeuxEnSSD from 'assets/promotion/jo2024/logo-region-ssd.svg';

const AppDrawerJO2024: FunctionComponent<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const [showOverlay, setShowOverlay] = useState(false);

  const links = [
    { label: 'Identifiez vos compétences', link: '/ajout-exp' },
    { label: 'Formez-vous', link: '/jo-2024/formations' },
    { label: 'Missions de bénévolat', link: '/jo-2024/benevolat/recherche' },
  ];

  return (
    <>
      {showOverlay && (
        <div
          className="fixed top-0 right-0 bottom-0 left-0 bg-lena-blue-dark bg-opacity-80 z-40 overflow-hidden"
          onClick={() => onClose?.()}
        />
      )}

      <Transition
        show={open}
        className="h-screen w-4/5 bg-white absolute top-0 h-screen flex flex-col shadow-xl z-50"
        enter="transform duration-150"
        enterFrom="-translate-x-full"
        enterTo="-translate-x-0"
        leave="transform duration-150"
        leaveFrom="-translate-x-0"
        leaveTo="-translate-x-full"
        beforeEnter={() => setShowOverlay(true)}
        afterLeave={() => setShowOverlay(false)}
      >
        <div className="h-full flex flex-col justify-between">
          <div className="flex flex-col">
            <div className="flex flex-col justify-center p-8">
              <div className="grid grid-cols-2">
                <Link to="/">
                  <img width="131" height="40" src={LogoLesJeuxEnSSD} alt="Logo de Les Jeux en Seine-Saint-Denis" />
                </Link>

                <p className="font-bold text-xl text-jo-dark items-center self-center">
                  Tous et toutes
                  <br className="flex xl:hidden" /> volontaires
                </p>
              </div>
            </div>

            <div className="">
              <Link to="/">
                <div className="px-8 space-y-4 py-4 flex flex-row items-center space-x-4 text-lena-blue-dark bg-lena-lightgray">
                  Les Jeux en Seine-Saint-Denis
                </div>
              </Link>
              {links.map((v, i) => (
                <Link key={i} to={v.link}>
                  <div key={v.label} className="flex px-8 py-4 space-y-4 flex-row items-center space-x-4">
                    {i + 1} - {v.label}
                  </div>
                </Link>
              ))}
              <Link to="/deconnexion">
                <div className="px-8 space-y-4 py-4 flex flex-row items-center space-x-4 text-lena-blue-dark">
                  Déconnexion
                </div>
              </Link>
            </div>
          </div>

          <div className="px-8 flex flex-col pb-7 border-t border-jo-dark">
            <div className="flex flex-col justify-center space-y-2 py-8">
              <Link to="/">
                <img src={LogoDiagoriente} alt="Logo de Diagoriente" style={{ width: 155, height: 37 }} />
              </Link>

              <div className="flex items-center space-x-4">
                <img src={RepubliqueSvg} alt="Logo de la République Française" style={{ width: 68, height: 36 }} />
                <img src={LogoBetagouv} alt="Logo de Beta.gouv.fr" style={{ width: 80, height: 14 }} />
              </div>
            </div>

            {[
              {
                label: 'Aide',
                url: 'https://diagoriente.beta.gouv.fr/aide',
              },
              {
                label: "Conditions d'utilisation",
                url: 'https://diagoriente.beta.gouv.fr/conditions-generales',
              },
              {
                label: 'Mentions légales',
                url: 'https://diagoriente.beta.gouv.fr/mentions-legales',
              },
              {
                label: 'Politique de confidentialité',
                url: 'https://diagoriente.beta.gouv.fr/confidentialite',
              },
            ].map((item: any, i) => (
              <div key={i} className="pt-2">
                <a
                  className={classNames('flex flex-row items-center space-x-4 text-lena-blue-dark')}
                  href={item.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span>{item.label}</span>
                </a>
              </div>
            ))}
          </div>
        </div>
      </Transition>
    </>
  );
};

export default AppDrawerJO2024;
