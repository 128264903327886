import { gql, MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { useLocalLazyQuery, useLocalMutation } from 'common/hooks/apollo';

export const listFeedbackQuestionsQuery = gql`
  query ListFeedbackQuestions($group: String!, $count: Int = 3) {
    userFeedbackQuestions(group: $group, count: $count) {
      id
      questionGroup
      text
      reactionGroup {
        id
        type
        reactions
      }
    }
  }
`;

export type ListFeedbackQuestionsVariables = {
  group: string;
  count?: number;
};

export type ListFeedbackQuestionsPayload = {
  userFeedbackQuestions:
    | {
        id: string;
        questionGroup: string;
        text: string;
        reactionGroup: {
          id: string;
          type: string;
          reactions: string[];
        };
      }[]
    | null;
};

export const useListFeedbackQuestions = (
  options: QueryHookOptions<ListFeedbackQuestionsPayload, ListFeedbackQuestionsVariables> = {},
) =>
  useLocalLazyQuery<ListFeedbackQuestionsPayload, ListFeedbackQuestionsVariables>(listFeedbackQuestionsQuery, options);

////////////////////////////////////////////////////////////////////////////////

export const createFeedbackAnswerMutation = gql`
  mutation CreateUserFeedbackAnswer($question: ObjectID!, $answer: [String], $intensity: Int) {
    createUserFeedbackAnswer(question: $question, reactionAnswer: $answer, reactionIntensity: $intensity)
  }
`;

export type CreateFeedbackAnswerVariables = {
  question: string;
  answer?: string[];
  intensity?: number;
};

export const useCreateFeedbackAnswer = (options?: MutationHookOptions<boolean, CreateFeedbackAnswerVariables>) =>
  useLocalMutation(createFeedbackAnswerMutation, options);
