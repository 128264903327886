import React, { FunctionComponent } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';

import createLazyComponent from 'utils/createLazyComponent';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import InterestProvider from './components/InterestProvider';

const InterestsIntro = createLazyComponent(() => import('./containers/InterestsIntro'));
const SelectContainer = createLazyComponent(() => import('./containers/SelectContainer'));
const FamilyContainer = createLazyComponent(() => import('./containers/FamilyContainer'));
const ChatContainer = createLazyComponent(() => import('./containers/ChatContainer'));

// react query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
});

const CenterInterestRoute: FunctionComponent = () => {
  return (
    <InterestProvider>
      <Routes>
        <Route path="/intro" element={<InterestsIntro />} />
        {/* <Route  path="/centres_interet" element={<StartContainer} />} />*/}
        <Route path="/family" element={<FamilyContainer />} />
        <Route path="/selection" element={<SelectContainer />} />
        <Route
          element={
            <QueryClientProvider client={queryClient} contextSharing={true}>
              <Outlet />
            </QueryClientProvider>
          }
        >
          <Route path="/chat" element={<ChatContainer />} />
        </Route>
      </Routes>
    </InterestProvider>
  );
};

export default CenterInterestRoute;
