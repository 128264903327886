import React, { FunctionComponent, HTMLProps, ReactNode, useEffect, useRef, useState } from 'react';
import { useFloating } from '@floating-ui/react-dom-interactions';
import { autoUpdate, shift, flip, offset } from '@floating-ui/dom';

import classNames from 'common/utils/classNames';
import useOnClickOutside from 'common/hooks/useOnClickOutside';
import { TextField } from 'components/design-system';
import * as _ from 'lodash-es';

type SuggestionProps = {
  key: string;
  value: any;
  label: ReactNode;
  disabled?: boolean;
};

export type AutocompleteProps = HTMLProps<HTMLInputElement> & {
  icon?: ReactNode;
  placeholder?: string;
  suggestions: SuggestionProps[];
  loading?: boolean;
  onSelectOptions?: (e: any) => void;
  onCreate?: (e: any) => void;
  onChange?: (e: any) => void;
  isCreatable?: boolean;
  error?: string | boolean;
};

const Autocomplete: FunctionComponent<AutocompleteProps> = ({
  icon,
  suggestions,
  loading,
  onSelectOptions,
  onChange,
  onCreate,
  isCreatable,
  error,
  ...restProps
}) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const [showSelect, setShowSelect] = useState(false);
  const [pending, setPending] = useState(false);

  useEffect(() => setPending(false), [suggestions]);

  const { x, y, reference, floating, strategy } = useFloating({
    open: showSelect,
    onOpenChange: setShowSelect,
    whileElementsMounted: autoUpdate,
    middleware: [offset(1), shift({ padding: 10 }), flip({ fallbackPlacements: ['bottom', 'top'] })],
  });

  useOnClickOutside(selectRef, () => {
    if (!showSelect) return;

    setShowSelect(false);
  });

  const handleChange = (e: any) => {
    setShowSelect(true);
    setPending(true);

    onChange?.(e);
  };

  const handleSelect = (value: any) => {
    onSelectOptions?.(value);

    setShowSelect(false);
  };

  const handleCreate = () => {
    onCreate?.(restProps.value);
    setShowSelect(false);
  };

  const renderOptions = () => {
    const styles = {
      option:
        'w-full rounded active:bg-lena2021-gray-light px-3 py-2 flex items-center justify-start space-x-2 text-left',
      disabled: 'cursor-not-allowed opacity-50',
    };

    if (!restProps.value) return null;

    if (loading || pending) return <div className={styles.option}>Chargement...</div>;

    if (!isCreatable && suggestions.length <= 0) {
      return <div className={styles.option}>Aucun résultat</div>;
    }

    return (
      <>
        {isCreatable && (
          <button
            key={String(restProps.value)}
            type="button"
            className={classNames(styles.option)}
            onClick={handleCreate}
          >
            {'Choisir: '}"<span className="font-bold">{restProps.value}</span>"
          </button>
        )}
        {suggestions?.map((option) => {
          const { key, value, label, disabled } = option;

          return (
            <button
              key={key || value}
              type="button"
              className={classNames(styles.option, disabled && styles.disabled)}
              onClick={() => {
                if (disabled) return;

                handleSelect(value);
              }}
            >
              {label}
            </button>
          );
        })}
      </>
    );
  };

  return (
    <div>
      <div>
        {/* omit the ref as it creates issues with typings */}
        <TextField
          {..._.omit(restProps, 'ref')}
          ref={reference}
          type="search"
          onChange={(e) => handleChange(e)}
          onClick={() => setShowSelect(true)}
          error={error}
          icon={icon}
        >
          {!!renderOptions() && (
            <div
              ref={floating}
              className={classNames(
                'mt-1 absolute w-full rounded-md border-2 p-1 border-lena-lightgray2 bg-white shadow z-100',
                'text-left max-h-60 overflow-y-auto scroll-thin space-y-1',
              )}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                marginTop: 5,
                marginBottom: 5,
                width: '100%',
                maxHeight: 300,
                visibility: showSelect ? 'visible' : 'hidden',
              }}
            >
              {renderOptions()}
            </div>
          )}
        </TextField>
      </div>
    </div>
  );
};

export default Autocomplete;
