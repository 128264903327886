import React from 'react';
import ContentBlockGenericV4, {
  ContentBlockGenericPropsV4,
} from 'containers/SkillCardV4Container/templates/components/ContentBlockGenericV4/ContentBlockGenericV4';

type Props = {
  stringItems: string[];
} & ContentBlockGenericPropsV4;

const StringListBlockV4: React.FC<Props> = ({ stringItems, title, editorWizardKey }) => {
  if (!stringItems || stringItems.length <= 0) return null;

  return (
    <ContentBlockGenericV4 title={title} editorWizardKey={editorWizardKey}>
      {stringItems && stringItems.length > 0 && (
        <ul className="list-inside ml-2.5">
          {stringItems.map((str, index) => (
            <li key={index} className="flex items-start">
              <span className="font-bold mr-2">•</span>
              <span>{str}</span>
            </li>
          ))}
        </ul>
      )}
    </ContentBlockGenericV4>
  );
};

export default StringListBlockV4;
