import React, { FunctionComponent, ReactNode, useMemo, useRef } from 'react';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { useFloating } from '@floating-ui/react-dom-interactions';
import { autoUpdate, shift, flip, offset } from '@floating-ui/dom';

import { HelperText } from 'components/design-system/HelperText';
import { createUUID } from 'utils';
import useOnClickOutside from 'common/hooks/useOnClickOutside';
import classNames from 'common/utils/classNames';

import { ReactComponent as ArrowDownSvg } from 'assets/svg/generic/arrow_down.svg';
import { ReactComponent as CheckmarkSvg } from 'assets/svg/generic/checkmark.svg';

export type DropdownProps = {
  label?: string;
  title?: string;
  labelHidden?: boolean;
  multiple?: boolean;
  choices: { key?: string; value: string; render: ReactNode }[];
  value: string | string[];
  onChangeSingle?: (value: string) => void;
  onChangeMultiple?: (value: string[]) => void;
  disabled?: boolean;
  wrapperClasses?: string;
  allowUnselect?: boolean;
  error?: string | boolean;
};

const Dropdown: FunctionComponent<DropdownProps> = (props: DropdownProps) => {
  const {
    label,
    title: defaultDisplayText,
    labelHidden = false,
    onChangeSingle,
    onChangeMultiple,
    value,
    multiple = false,
    choices = [],
    disabled = false,
    wrapperClasses,
    allowUnselect = false,
    error,
  } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(choices.length);

  const { x, y, reference, floating, strategy } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(1), shift({ padding: 10 }), flip({ fallbackPlacements: ['bottom', 'top'] })],
  });

  const id = `dropdown.${createUUID()}`;

  useOnClickOutside(wrapperRef, () => setIsOpen(false));

  const displayText = useMemo(() => {
    if (!multiple && typeof value === 'string') {
      if (!value || value.length <= 0) return defaultDisplayText;
      return choices.find((v) => v.value === value)?.render || '';
    }
    if (!value || value.length <= 0) return defaultDisplayText;
    return choices
      .filter((v) => value.includes(v.value))
      .map((v) => v.render)
      .join(', ');
  }, [value, multiple, choices]);

  const handleSelectItem = (selected: string) => {
    if (multiple && typeof value !== 'string')
      onChangeMultiple?.(value.includes(selected) ? value.filter((v) => v !== selected) : [...value, selected]);
    else {
      onChangeSingle?.(value === selected ? (allowUnselect ? '' : selected) : selected);
      setIsOpen(false);
    }
  };

  const isInValue = (v: string) => {
    return typeof value === 'string' ? v === value : value.includes(v);
  };

  return (
    <div className={classNames('relative', wrapperClasses)} ref={wrapperRef}>
      {label && !labelHidden && (
        <label data-testid="label" className="text-lena2021-blue-dark font-bold mr-6" htmlFor={id}>
          {label}
        </label>
      )}

      <div>
        <button
          id={id}
          type="button"
          className={classNames(
            label && !labelHidden && 'mt-1',
            'w-full bg-white border focus:border-lena-gray rounded-lg text-left px-4 py-3',
            'focus:outline-none focus:ring-0 flex items-center justify-between space-x-4',
            'cursor-pointer',
            error ? 'border-lena2021-corail-dark' : 'border-lena-lightgray2',
            disabled ? 'text-lena-gray bg-lena-lightgray2' : 'text-lena-blue-dark',
          )}
          aria-label={label}
          aria-invalid={Boolean(error)}
          aria-describedby={`helperText.${id}`}
          data-testid="dropdown"
          onClick={() => {
            if (disabled) setIsOpen(!isOpen);
          }}
          {...buttonProps}
          ref={reference}
        >
          <div data-testid="displayText" className="sm:truncate">
            {displayText}
          </div>
          <ArrowDownSvg className="flex-shrink-0" height={16} width={16} />
        </button>

        {isOpen && (
          <div
            ref={floating}
            role="menu"
            data-testid="list"
            className={classNames(
              'my-1 absolute w-full rounded-md border-2 p-1 border-lena-lightgray2 bg-white shadow z-100',
              'text-left max-h-60 overflow-auto scroll-thin space-y-1',
            )}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            {choices.map((v, i) => (
              <a
                {...itemProps[i]}
                data-testid="item"
                key={v.key || v.value}
                className={classNames(
                  'rounded active:bg-lena2021-gray-light cursor-pointer px-3 py-2 flex items-center justify-start space-x-2',
                  isInValue(v.value) ? 'bg-lena2021-gray-light' : 'hover:bg-lena2021-gray-lightest',
                )}
                onClick={() => handleSelectItem(v.value)}
              >
                {isInValue(v.value) && <CheckmarkSvg className="flex-shrink-0" height={16} width={16} />}
                <div>{v.render}</div>
              </a>
            ))}
          </div>
        )}
      </div>

      {error && (
        <HelperText
          id={id}
          helperText={{
            type: 'error',
            text: error,
          }}
        />
      )}
    </div>
  );
};

export default Dropdown;
