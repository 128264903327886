function sift3(str1: string, str2: string) {
  if (str1 == null || str1.length === 0) {
    if (str2 == null || str2.length === 0) {
      return 0;
    } else {
      return str2.length;
    }
  }

  if (str2 == null || str2.length === 0) return str1.length;

  let c = 0;
  let offset1 = 0;
  let offset2 = 0;
  let lcs = 0;

  const maxOffset = 5;

  while (c + offset1 < str1.length && c + offset2 < str2.length) {
    if (str1.charAt(c + offset1) == str2.charAt(c + offset2)) {
      lcs++;
    } else {
      offset1 = 0;
      offset2 = 0;

      for (let i = 0; i < maxOffset; i++) {
        if (c + i < str1.length && str1.charAt(c + i) == str2.charAt(c)) {
          offset1 = i;
          break;
        }

        if (c + i < str2.length && str1.charAt(c) == str2.charAt(c + i)) {
          offset2 = i;
          break;
        }
      }
    }
    c++;
  }

  return (str1.length + str2.length) / 2 - lcs;
}

export default sift3;
