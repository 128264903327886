import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import classNames from 'common/utils/classNames';

import { ReactComponent as Check } from 'assets/svg/generic/checkmark.svg';

import * as _ from 'lodash-es';

type Props = {
  maxLevel: number;
  criteria?: { id: string; title: string; subtitle?: string; levels: { level: number; content: string }[] }[];
  ratings?: { competenceId: string; level: number }[];
  criteriaLevels: Record<string, number>;
  onSetCriteriaLevel?: (criteriaId: string, level: number) => void;
};

const GraduationCriteriaTable: FunctionComponent<Props> = ({
  maxLevel,
  criteria,
  criteriaLevels,
  onSetCriteriaLevel,
}) => {
  const inited = useRef(false);
  const [rowsHidden, setRowsHidden] = useState<string[]>([]);
  const [rowsChecked, setRowsChecked] = useState<string[]>([]);

  // default hide rows except the first row
  useEffect(() => {
    if (!criteria) return;

    const countCriteria = Object.keys(criteria);
    const criteriaformatted = countCriteria.slice(1, countCriteria.length);

    setRowsHidden(criteriaformatted);
  }, [criteria]);

  useEffect(() => {
    if (!inited.current) {
      setRowsChecked(Object.keys(criteriaLevels));
      if (Object.keys(criteriaLevels).length > 0) {
        setRowsHidden([]);
      }

      inited.current = true;
    }
  }, [criteriaLevels]);

  const renderLevelsRow = () => {
    return (
      <>
        <div></div>
        {Array.from(Array(maxLevel)).map((_, index) => (
          <div
            key={`level-${index}`}
            className="rounded-md bg-lena2021-blue-dark text-white bg-opacity-80 py-1 text-center uppercase font-bold"
          >
            Niveau {index + 1}
          </div>
        ))}
      </>
    );
  };

  const renderCriteriaRow = () => {
    return criteria?.map((v, i) => (
      <>
        <div className="relative flex rounded-md items-center justify-center">
          <div className="px-2 py-4 text-sm self-center text-center">
            <div className="font-bold uppercase text-sm">{v.title}</div>
          </div>
        </div>

        {v.levels.map((c, index) => {
          if (rowsHidden.includes(i.toString())) {
            return <button className="rounded-md p-2 bg-lena-lightgray cursor-not-allowed" />;
          }

          return (
            <button
              key={`competence${v.id}-level-${index}`}
              className={classNames(
                'rounded-md text-sm p-2',
                criteriaLevels[v.id] === c.level
                  ? 'bg-lena2021-corail-light shadow'
                  : 'bg-lena-lightgray hover:bg-lena-lightgray2 hover:bg-opacity-60',
              )}
              onClick={() => {
                onSetCriteriaLevel?.(v.id, c.level);
                setRowsChecked(_.xor(rowsChecked, [v.id]));
                setRowsHidden((prevState) => prevState.filter((_) => _ !== (i + 1).toString()));
              }}
            >
              {rowsChecked.includes(v.id) ? (
                <div className="flex justify-center items-center">
                  {criteriaLevels[v.id] === c.level && <Check width={22} />}
                </div>
              ) : (
                c.content
              )}
            </button>
          );
        })}
      </>
    ));
  };

  if (!inited.current) return null;

  return (
    <div className="grid grid-cols-8 gap-2 w-full m-auto">
      {renderLevelsRow()}
      {renderCriteriaRow()}
    </div>
  );
};

export default GraduationCriteriaTable;
