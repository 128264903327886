import React from 'react';

type CvRenderContextData = {
  config: {
    preview: boolean;
    print: boolean;
  };
};

const CvRenderContext = React.createContext<CvRenderContextData>({ config: { preview: false, print: false } });

export default CvRenderContext;
