import React, { useMemo } from 'react';
import { SkillItemGenericProps } from 'containers/SkillCardV4Container/templates/components/ContentBlockGenericV4/generic-types-v4';
import SkillRecommendationIndicator from 'containers/SkillCardV4Container/templates/components/SkillRecommendationIndicator';
import { formatDateRange } from 'utils/formatDate';
import { useCvCreate } from 'hooks';

const SkillListItemGenericV4: React.FC<SkillItemGenericProps> = ({ skill, enabledRecommendationsId }) => {
  const cvc = useCvCreate();

  const enabledRecommendations = useMemo(() => {
    // Weird bug in editor mode, somehow enabledRecommendationsId is empty; works in print tho
    const mergedIds: string[] = [...(enabledRecommendationsId || []), ...(cvc.recommendations || [])];
    return mergedIds?.filter((x) => skill?.recommendations?.includes(x)) || [];
  }, [enabledRecommendationsId, cvc.recommendations]);

  if (!skill) return <div>ERROR : No Skill Data</div>;

  return (
    <div className="space-y-1 pbi-avoid">
      <div className="leading-4">
        <div className="font-bold">{skill.title}</div>
        <div>
          {skill.structure && `${skill.structure}`} {skill.structure && skill.startDate && ' | '}
          {formatDateRange(skill.startDate, skill.endDate)}
        </div>
      </div>
      <div>
        {skill.activity && <p className="whitespace-pre-line ml-2.5">{skill.activity}</p>}
        {skill.activities && (
          <ul className="list-inside ml-2.5">
            {skill.activities.map((activity) => (
              <li key={activity.id} className="flex items-start">
                <span className="mr-2">•</span>
                <span>{activity.title}</span>
              </li>
            ))}
          </ul>
        )}
        {skill.extraActivity && (
          <ul className="list-inside ml-2.5">
            {skill.extraActivity.map((activity) => (
              <li key={activity} className="flex items-start">
                <span className="mr-2">•</span>
                <span>{activity}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
      <SkillRecommendationIndicator count={enabledRecommendations.length} />
    </div>
  );
};

export default SkillListItemGenericV4;
