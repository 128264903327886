import React, { FunctionComponent, useMemo } from 'react';
import { ReactComponent as PagePrevSvg } from 'assets/svg/generic/page_prev.svg';
import { ReactComponent as PageNextSvg } from 'assets/svg/generic/page_next.svg';
import classNames from 'common/utils/classNames';
import * as lodash from 'lodash-es';

export type PaginationProps = {
  knownPageCount?: boolean;
  currentPage: number;
  numOfPages: number;
  onChangePage?: (page: number) => void;
};

const NUMBER_OF_PAGES = 5;

const Pagination: FunctionComponent<PaginationProps> = (props) => {
  const { onChangePage, currentPage = 1, numOfPages = 1, knownPageCount = true } = props;

  const clamped = lodash.clamp(currentPage, 0, numOfPages);

  const elements = useMemo(() => {
    const pages = [];

    if (numOfPages <= NUMBER_OF_PAGES) {
      for (let i = 1; i <= numOfPages; i += 1) {
        pages.push(i);
      }
    } else {
      let leftSide = Math.ceil(NUMBER_OF_PAGES / 2);
      let rightSide = NUMBER_OF_PAGES - leftSide;

      if (clamped > numOfPages - Math.trunc(NUMBER_OF_PAGES / 2)) {
        rightSide = numOfPages - clamped;
        leftSide = NUMBER_OF_PAGES - rightSide;
      } else if (clamped < leftSide) {
        leftSide = clamped;
        rightSide = NUMBER_OF_PAGES - leftSide;
      }

      for (let i = leftSide - 1; i >= 0; i -= 1) {
        pages.push(clamped - i);
      }

      for (let i = 1; i <= rightSide; i += 1) {
        pages.push(clamped + i);
      }
    }

    return pages;
  }, [clamped, numOfPages]);

  const renderLink = (number: number) => {
    const active = number === clamped;

    return (
      <li key={number}>
        <button
          aria-label={active ? `${active ? 'Page actuelle, ' : ''}Page ${number}` : `Aller à la page ${number}`}
          aria-current={active}
          className={classNames(
            'px-4 py-2 rounded-lg border border-transparent',
            active
              ? 'bg-lena-blue-dark text-white'
              : 'hover:border-lena-lightgray2 text-lena-blue-dark bg-white cursor-pointer',
          )}
          onClick={() => !active && onChangePage?.(number)}
        >
          {number}
        </button>
      </li>
    );
  };

  return (
    <nav
      role="navigation"
      aria-label="Navigation de la pagination"
      className="flex items-center space-x-4 flex-wrap font-bold"
    >
      <button
        aria-label="Page précédente"
        aria-disabled={clamped <= 1 && knownPageCount}
        className={classNames(
          'cursor-pointer flex items-center',
          clamped <= 1 && (knownPageCount ? 'invisible' : 'hidden'),
        )}
        onClick={() => onChangePage?.(clamped - 1)}
      >
        <PagePrevSvg />
        {!knownPageCount && <div className="text-lena-blue-dark">Page précédente</div>}
      </button>

      {knownPageCount && <ul className="flex flex-1 items-center space-x-2 flex-wrap">{elements.map(renderLink)}</ul>}

      <button
        aria-label="Page suivante"
        aria-disabled={knownPageCount && clamped >= numOfPages}
        className={classNames('cursor-pointer flex items-center', knownPageCount && clamped >= numOfPages && 'hidden')}
        onClick={() => onChangePage?.(clamped + 1)}
      >
        {!knownPageCount && <div className="text-lena-blue-dark">Page suivante</div>}
        <PageNextSvg />
      </button>
    </nav>
  );
};

export default Pagination;
