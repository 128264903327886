import React, { FunctionComponent, useContext } from 'react';
import { Link } from 'react-router-dom';
import UserContext from 'common/contexts/UserContext';
import { AppFlavor } from 'contexts/AppFlavorContext';
import { useAppFlavor } from 'hooks';
import { AppUserMenu } from 'layouts/AppLayout/AppUserMenu';
import AppHeaderJO2024Desktop from 'layouts/AppLayout/jo2024/AppHeaderJO2024.desktop';

import LogoDiagoriente from 'assets/logos/diagoriente.svg';
import RepubliqueSvg from 'assets/logos/republique.svg';
import LogoBetagouv from 'assets/logos/betagouv.svg';
import Logo1J1S from 'assets/logos/1j1s-horizontal.svg';

import { ReactComponent as AnnotationIcon } from 'assets/svg/generic/annotation-ask.svg';
import { ReactComponent as ObjectifsIcon } from 'assets/svg/generic/objectifs.svg';

import AppDesktopMenu from './AppDesktopMenu';
import FeedbackQuestions from 'components/FeedbackQuestions/FeedbackQuestions';

const AppHeaderDesktop: FunctionComponent = () => {
  const { user } = useContext(UserContext);
  const { flavor, is1J1S } = useAppFlavor();

  if (flavor === AppFlavor.CEJ) return null;
  if (flavor === AppFlavor.Olympics) return <AppHeaderJO2024Desktop />;

  return (
    <div className="sticky top-0 shadow z-50">
      <header
        className={`bg-white flex flex-row items-center justify-between px-4
      md:px-8 py-4 space-x-4 sticky top-0 z-50 h-14`}
      >
        <div className="flex flex-row items-center justify-center space-x-6">
          <Link to="/">
            <img src={LogoDiagoriente} alt="Logo de Diagoriente" style={{ width: 155, height: 37 }} />
          </Link>
          <img src={RepubliqueSvg} alt="Logo de la République Française" style={{ width: 68, height: 36 }} />
          <img src={LogoBetagouv} alt="Logo de Beta.gouv.fr" style={{ width: 80, height: 14 }} />
          <div>{user && <AppDesktopMenu />}</div>
        </div>
        {is1J1S ? (
          <div>
            <a href="https://1jeune1solution.gouv.fr">
              <img src={Logo1J1S} alt="Logo de 1jeune1solution" className="h-10" />
            </a>
          </div>
        ) : (
          <div className="flex flex-row items-center justify-center space-x-6">
            {user && flavor === AppFlavor.Default && (
              <FeedbackQuestions group="segmentation">
                <ObjectifsIcon width={27} height={27} />
              </FeedbackQuestions>
            )}

            {user && flavor === AppFlavor.Default && (
              <FeedbackQuestions group="v1_DEFAULT" pollInterval={120_000}>
                <AnnotationIcon width={24} height={24} />
              </FeedbackQuestions>
            )}

            <div>{user && <AppUserMenu />}</div>
          </div>
        )}
      </header>
    </div>
  );
};

export default AppHeaderDesktop;
