import React, { useEffect } from 'react';
import { Button, SelectorTest } from 'components/design-system';
import useBoolean from 'common/hooks/useBoolean';

type Props = {
  title: React.ReactNode;
  levels: { description: string; value: number }[];
  selectedLevel?: number;
  onSelectLevel: (level: number) => void;
  window: number;
};

const CardWithLevelSelector: React.FC<Props> = ({ title, levels, selectedLevel, onSelectLevel, window }) => {
  const expanded = useBoolean(false);
  const levelsToDisplay = expanded.value ? [...levels] : [...levels].slice(0, Math.min(window, levels.length));

  useEffect(() => {
    if (selectedLevel !== undefined && selectedLevel > window && !expanded.value) expanded.on();
  }, [selectedLevel]);

  return (
    <div className="bg-lena-lightgray p-4 rounded-md space-y-2">
      <div className="mb-4">{title}</div>
      <div className="gap-2 grid grid-cols-1">
        {levelsToDisplay.map((l, index) => (
          <SelectorTest
            key={index}
            defaultColor="bg-white shadow"
            withCheckBox={true}
            checked={index + 1 === selectedLevel}
            checkedColor="bg-lena2021-corail-light shadow"
            onClick={() => onSelectLevel?.(index + 1)}
          >
            <p className="text-sm">
              <span className="font-bold">Niveau {l.value} : </span>
              <span>{l.description}</span>
            </p>
          </SelectorTest>
        ))}
      </div>
      {!expanded.value && (
        <div className="text-center">
          <Button variant="ghost" size="sm" padding="sm" onClick={expanded.toggle}>
            {expanded.value ? 'Voir moins de niveaux' : 'Voir tous les niveaux'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CardWithLevelSelector;
