import useTypedLocalStorage from 'common/hooks/useTypedLocalStorage';

const HISTORY_SIZE = 200;

export type UseAdvertsSeenHistoryResult = {
  contains: (advertsId: string) => boolean;
  add: (advertsId: string) => void;
  stored: string[];
};

const useAdvertsSeenHistory = (): UseAdvertsSeenHistoryResult => {
  const [stored, setStored] = useTypedLocalStorage<string[]>('adverts.history', []);

  const contains = (advertsId: string) => stored.includes(advertsId);
  const add = (advertsId: string) => setStored(Array.from(new Set([...stored, advertsId])).slice(-HISTORY_SIZE));

  return { contains, add, stored };
};

export default useAdvertsSeenHistory;
