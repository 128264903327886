import { gql } from '@apollo/client';

export const ALL_CV_DATA = gql`
  fragment CvNewAllData on CvNew {
    id
    title
    publicToken
    contentOrder
    user {
      firstName
      lastName
    }
    template {
      id
      templateKey
      title
    }
    info {
      email
      extraInfo
      bio
      keywords
      position
      phone
    }
    avatar
    formations {
      id
      title
    }
    competences {
      type
      items {
        id
        type
        origin {
          id
          title
        }
        userData {
          title
        }
      }
    }
    skills {
      type
      items {
        id
        type
        origin {
          id
          intitule
          startDate
          endDate
          structure
          activities {
            id
            title
          }
          extraActivity
          theme {
            title
          }
          recommendations {
            id
            status
            firstName
            lastName
            email
            location
            message
            response
            structure
          }
        }
        userData {
          title
          startDate
          endDate
          structure
          activities
          location
        }
        activities {
          activityId
        }
      }
    }
    volunteers {
      id
      type
      origin {
        id
        intitule
        startDate
        endDate
        structure
        activities {
          options {
            id
            title
          }
        }
        extraOption
        theme {
          title
        }
        recommendations {
          id
          status
          firstName
          lastName
          email
          location
          message
          response
          structure
        }
      }
    }
    overrides {
      competences {
        origin
        title
      }
      skills {
        origin
        title
      }
    }
    customizationOptions {
      variant
      accentColor
      font
    }
    recommendations {
      id
      firstName
      lastName
    }
  }
`;
