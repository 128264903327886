import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  PropsWithChildren,
} from 'react';
import { CVContext, CVContextSkills, CVContextVolunteers } from 'contexts/CVContext';
import UserContext from 'common/contexts/UserContext';
import { CVCompetence } from 'containers/SkillCardV4Container/types/dataCV';
import { allCvBlocks, TemplateFont, TemplateVariant } from 'containers/SkillCardV4Container/types/template';

const CVProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { user } = useContext(UserContext);

  const [cvId, setCvId] = useState<string>('');
  const [templateKey, setTemplateKey] = useState<string>('');
  const [templateVariant, setTemplateVariant] = useState<TemplateVariant>('fond');
  const [templateFont, setTemplateFont] = useState<TemplateFont>('atkinson');
  const [accentColor, setAccentColor] = useState<string>('#1E2E58'); // #1lE2E58
  const [cvName, setCvName] = useState<string>('');
  const [photo, setPhoto] = useState<string>('');
  const [job, setJob] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [extraInfos, setExtraInfos] = useState<string>('');
  const [formations, setFormation] = useState<{ id: string; value: string }[]>([]);
  const [keywords, setKeyword] = useState<{ id: string; value: string }[]>([]);
  const [skills, setSkills] = useState<CVContextSkills[]>([]);
  const [volunteers, setVolunteers] = useState<CVContextVolunteers[]>([]);
  const [editSkill, setEditSkill] = useState<string>('');
  const [contentOrder, setContentOrder] = useState<{ visible: boolean; name: string }[]>([]);
  const [competences, setCompetences] = useState<{
    technique: CVCompetence[];
    transversal: CVCompetence[];
  }>({
    technique: [],
    transversal: [],
  });
  const [overrides, setOverrides] = useState<{
    competences: {
      id: string;
      title: string;
    }[];
    skills: {
      id: string;
      title: string;
    }[];
  }>({
    competences: [],
    skills: [],
  });
  const [recommendations, setRecommendations] = useState<string[]>([]);

  const enabledBlocks = useMemo(() => {
    return Object.keys(allCvBlocks).map((name) => {
      let isVisibleBlock = false;
      const part = name?.split('-')[0];
      const sub = name?.split('-')[1];

      switch (part) {
        case 'comp':
          isVisibleBlock = (competences as any)[sub]?.length > 0;
          break;
        case 'experience':
          isVisibleBlock = skills.some((v) => v.type === sub);
          break;
        case 'volunteers':
          isVisibleBlock = volunteers.length > 0;
          break;
        default:
          isVisibleBlock = false;
      }

      return {
        visible: isVisibleBlock,
        name,
      };
    });
  }, [user, competences, skills, volunteers]);

  const importContentOrder = useCallback(
    (values: string[]) => {
      const result = [
        ...(values.map((v) => enabledBlocks.find((c) => c.name === v) || null).filter(Boolean) as any),
        ...enabledBlocks.filter((c) => !values.some((v) => v === c.name)),
      ];
      // console.log('new content order should be', result);
      setContentOrder(result);
    },
    [enabledBlocks, setContentOrder, contentOrder],
  );

  /**
   * Get the contentOrder dynamic
   */
  useEffect(() => {
    importContentOrder(contentOrder.map((v) => v.name));
  }, [user, competences, skills, volunteers]);

  return (
    <CVContext.Provider
      value={{
        cvId,
        setCvId,
        templateKey,
        setTemplateKey,
        accentColor,
        setAccentColor,
        templateVariant,
        setTemplateVariant,
        templateFont,
        setTemplateFont,
        cvName,
        setCvName,
        photo,
        setPhoto,
        job,
        setJob,
        description,
        setDescription,
        phone,
        setPhone,
        email,
        setEmail,
        extraInfos,
        setExtraInfos,
        formations,
        setFormation,
        keywords,
        setKeyword,
        skills,
        setSkills,
        editSkill,
        setEditSkill,
        contentOrder,
        setContentOrder,
        competences,
        setCompetences,
        overrides,
        setOverrides,
        importContentOrder,
        volunteers,
        setVolunteers,
        recommendations,
        setRecommendations,
      }}
    >
      {children}
    </CVContext.Provider>
  );
};

export default CVProvider;
