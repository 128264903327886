import { gql, MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { useLocalLazyQuery, useLocalMutation } from '../hooks/apollo';

export const interestsQuery = gql`
  query InterestsWithMyInterests {
    interests {
      data {
        id
        title
        help
        images
        cursors {
          id
          index
          title
          parentId
          interest
        }
      }
    }
    # Fetch users interests so we can display it on the UI
    myInterests: me {
      id
      interests {
        cursors {
          id
          parentId
        }
        interest {
          id
          images
          cursors {
            id
            title
          }
        }
      }
      interestsV2 {
        mode
        selectedInterestsV2
      }
    }
  }
`;
export type InterestsResponse = {
  interests: {
    data: {
      id: string;
      title: string;
      help: string;
      images: string[];
      cursors: {
        id: string;
        title: string;
        parentId: string;
      }[][];
    }[];
  };
  myInterests: {
    interests: {
      cursors: {
        id: string;
        parentId: string;
      }[];
      interest: {
        id: string;
      };
    }[];
    interestsV2: {
      mode: string;
      selectedInterestsV2: number[];
    };
  };
};
export const useInterests = (options: QueryHookOptions<InterestsResponse> = {}) =>
  useLocalLazyQuery<InterestsResponse>(interestsQuery, options);

export const updateInterestsMutation = gql`
  mutation UpdateMyInterests($interests: [ObjectID!]!, $families: [ObjectID!]!) {
    updateUserInterests(cursors: $interests, interests: $families) {
      id
      interests {
        cursors {
          id
          title
        }
        interest {
          id
          title
          images
          cursors {
            id
            title
          }
        }
      }
    }
  }
`;

export const useUpdateUserInterests = (
  options: MutationHookOptions<any, { interests: string[]; families: string[] }> = {},
) => useLocalMutation(updateInterestsMutation, options);

export const myInterestsQuery = gql`
  query GetMyInterests {
    me {
      id
      interests {
        interest {
          id
          title
          images
        }
        cursors {
          id
          title
          interest
          parentId
        }
      }
    }
  }
`;

export type MyInterestsResponse = {
  me: {
    id: string;
    interests: {
      interest: {
        id: string;
        title: string;
        images: string[];
      };
      cursors: [
        {
          id: string;
          title: string;
          parentId: string;
        },
      ];
    }[];
  };
};

export const useMyInterests = (options: QueryHookOptions<MyInterestsResponse> = {}) =>
  useLocalLazyQuery<MyInterestsResponse>(myInterestsQuery, options);

export const updateInterestsV2Mutation = gql`
  mutation UpdateMyInterestsV2($interests: [Int!]!) {
    updateUserInterestsV2(interests: $interests) {
      id
    }
  }
`;

export const useUpdateUserInterestsV2 = (options: MutationHookOptions<any, { interests: number[] }> = {}) =>
  useLocalMutation(updateInterestsV2Mutation, options);
