import { useRefDimensionsTemplate } from 'hooks';
import React, { useMemo, useRef } from 'react';
import classNames from 'common/utils/classNames';

type Props = {
  allowOverflow?: boolean;
  children: any;
  print?: boolean;
  showColumnEditor?: boolean;
};

const PreviewA4: React.FC<Props> = ({ allowOverflow = false, print = false, children, showColumnEditor = false }) => {
  const refPreview = useRef<any>(null);
  const refDivTransformed = useRef<HTMLDivElement>(null);

  const { width: outerWidth } = useRefDimensionsTemplate(refPreview, showColumnEditor);
  const innerHeight = useMemo(() => refDivTransformed.current?.getBoundingClientRect().height, [children]);

  return (
    <div
      ref={refPreview}
      className="template--wrapperPreview"
      style={{
        minHeight: innerHeight,
      }}
    >
      <div
        ref={refDivTransformed}
        className="absolute left-[50%] top-0 bg-white flex origin-top-left"
        style={{
          transform: `scale(${outerWidth / 794}) translateX(-50%)`,
        }}
      >
        <div className={classNames(allowOverflow ? 'template--preview-dynamic' : 'template--preview', !print && 'p-4')}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default PreviewA4;
