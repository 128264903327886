import React, { useMemo } from 'react';
import ContentBlockGenericV4 from 'containers/SkillCardV4Container/templates/components/ContentBlockGenericV4/ContentBlockGenericV4';
import { CVBase } from 'containers/SkillCardV4Container/types/dataCV';
import { SkillItemGenericProps } from 'containers/SkillCardV4Container/templates/components/ContentBlockGenericV4/generic-types-v4';
import SkillListItemGenericV4 from 'containers/SkillCardV4Container/templates/components/ContentBlockGenericV4/Skills/SkillListItemGenericV4';
import { allCvBlocks } from 'containers/SkillCardV4Container/types/template';
import { uniqueId } from 'lodash-es';

type Props = {
  contentKey: string;
  skills: CVBase[];
  enabledRecommendationsId: string[];
  itemComponent?: React.ComponentType<SkillItemGenericProps>;
};

const SkillListBlockV4: React.FC<Props> = ({ skills, contentKey, itemComponent, enabledRecommendationsId }) => {
  const editorWizardKey = contentKey;
  const title = allCvBlocks[contentKey];
  const RenderedComponent = itemComponent || SkillListItemGenericV4;

  const transformedSkills = useMemo(() => {
    const transformActivities = (acts: any[]) => {
      if (contentKey === 'volunteers') {
        return acts.flatMap((v) => ({
          id: uniqueId('local'),
          title: v.options.map((o: any) => o.title).join(' '),
        }));
      }

      return acts;
    };

    return skills.map((sk: any) => {
      const isUserRedactedSkill = sk.sourceType === 'USER_CREATED';
      return {
        id: sk.data.id,
        title: isUserRedactedSkill ? sk.data.title : sk.data.intitule || sk.data.theme?.title,
        activities: isUserRedactedSkill ? undefined : transformActivities(sk.data.activities),
        activity: isUserRedactedSkill ? sk.data.activities : undefined,
        extraActivity: sk.data.extraActivity || sk.data.extraOption || [],
        startDate: sk.data.startDate,
        endDate: sk.data.endDate,
        structure: sk.data.structure,
        recommendations:
          sk.data.recommendations?.filter((v: any) => v.status === 'accepted').map((v: any) => v.id) || [],
      };
    });
  }, [skills]);

  return (
    <ContentBlockGenericV4 title={title} editorWizardKey={{ content: editorWizardKey, design: 'content_order' }}>
      <div className="space-y-4">
        {transformedSkills.map((v) => (
          <RenderedComponent key={v.id} skill={v} enabledRecommendationsId={enabledRecommendationsId} />
        ))}
      </div>
    </ContentBlockGenericV4>
  );
};

export default SkillListBlockV4;
