import { useEffect, useMemo, useState } from 'react';
import { useCreateFeedbackAnswer, useListFeedbackQuestions } from 'common/requests/userFeedback';

type Props = {
  handleSuccess: () => any;
  pollInterval?: number;
  group: string;
  maxQuestions?: number;
  numQuestionsToFetch?: number;
};

const useFeedbackQuestions = ({
  handleSuccess,
  pollInterval,
  group,
  maxQuestions = 3, // We fetch 6 questions, users have to answer at most 3, and can skip 3
  numQuestionsToFetch = 6,
}: Props) => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [responses, setResponses] = useState<any>([]);

  const [callMutationCreateFeedback] = useCreateFeedbackAnswer();
  const [callQuestion, { data }] = useListFeedbackQuestions({
    variables: {
      count: numQuestionsToFetch,
      group: group,
    },
    ...(pollInterval && {
      pollInterval: pollInterval,
    }),
  });

  useEffect(() => {
    callQuestion();
  }, []);

  const currentQuestion = useMemo(() => data?.userFeedbackQuestions?.[questionIndex], [questionIndex, data]);

  const handleSuccessForm = () => {
    setQuestionIndex(0);
    setResponses([]);

    handleSuccess();
  };

  const handleSubmit = (value: any) => {
    if (!data || !data.userFeedbackQuestions) return;

    if (value !== null) {
      setResponses((prevState: any) => {
        return [
          ...prevState,
          {
            ...currentQuestion,
            response: value,
          },
        ];
      });
    }

    if (currentQuestion) {
      callMutationCreateFeedback({
        variables: {
          question: currentQuestion.id,
          answer: value?.answer,
          intensity: value?.intensity,
        },
      });
    }

    if (questionIndex + 1 < (data?.userFeedbackQuestions.length || 0) && responses.length + 1 < maxQuestions) {
      setQuestionIndex((prevState) => prevState + 1);
    } else {
      handleSuccessForm();
    }
  };

  return {
    responses,
    currentQuestion,
    handleSubmit,
    data,
  };
};

export default useFeedbackQuestions;
