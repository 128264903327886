import React, { PropsWithChildren, useContext } from 'react';
import classNames from 'common/utils/classNames';
import { useEditor } from 'hooks';
import CvRenderContext from 'containers/SkillCardV4Container/contexts/CvRenderContext';

export type ContentBlockGenericPropsV4 = {
  title: string;
  editorWizardKey?: { [tab: string]: string };
  hideTitle?: boolean;
  hideTitleInPrintMode?: boolean;
  hideInPrintMode?: boolean;
};

const ContentBlockGenericV4: React.FC<PropsWithChildren<ContentBlockGenericPropsV4>> = ({
  children,
  title,
  hideTitle,
  hideTitleInPrintMode,
  hideInPrintMode,
  editorWizardKey,
}) => {
  const renderContext = useContext(CvRenderContext);
  const editor = useEditor();
  const contextualWizardKey = editorWizardKey?.[String(editor.activeStep.tab)] || undefined;
  const isActiveStep = editor.currentKeyStep === contextualWizardKey;
  const isEditorMode = !renderContext.config.print && !renderContext.config.preview;

  const handleClickBlock = (e: React.MouseEvent) => {
    if (contextualWizardKey) editor.setCurrentStepByKey(contextualWizardKey);
    if (isEditorMode) e.stopPropagation();
  };

  const shouldHideTitle = renderContext.config.print ? hideTitleInPrintMode : hideTitle;

  if (renderContext.config.print && hideInPrintMode) return null;

  return (
    <div
      className={classNames(
        'space-y-1',
        isEditorMode && 'p-2',
        isEditorMode && 'border-2 border-dashed',
        isEditorMode &&
          (contextualWizardKey ? 'hover:border-lena-corail hover:cursor-pointer' : 'hover:cursor-default'),
        contextualWizardKey && isActiveStep ? 'border-lena-corail' : 'border-transparent',
      )}
      onClick={handleClickBlock}
    >
      {!shouldHideTitle && <div className="font-bold uppercase">{title}</div>}
      {children && <div>{children}</div>}
    </div>
  );
};

export default ContentBlockGenericV4;
