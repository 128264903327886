import React, { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import createLazyComponent from 'utils/createLazyComponent';

import { AdvertSearchProvider } from 'contexts';

const AdvertsSearch = createLazyComponent(() => import('./containers/AdvertsSearch/AdvertsSearch'));
const ResultsEngagement = createLazyComponent(() => import('./components/ResultsImpl/ResultsEngagement'));
const ResultsImmersion = createLazyComponent(() => import('./components/ResultsImpl/ResultsImmersion'));
const ResultsEmploi = createLazyComponent(() => import('./components/ResultsImpl/ResultsEmploi'));
const ResultsApprentissage = createLazyComponent(() => import('./components/ResultsImpl/ResultsApprentissage'));
const ResultsFormationLheo = createLazyComponent(() => import('./components/ResultsImpl/ResultsFormationLheo'));

const AdvertsRoute: FunctionComponent = () => {
  return (
    <AdvertSearchProvider>
      <Routes>
        <Route path="/recherche" element={<AdvertsSearch />} />
        <Route path="/resultats/immersion" element={<ResultsImmersion />} />
        <Route path="/resultats/apprentissage" element={<ResultsApprentissage />} />
        <Route path="/resultats/formation" element={<ResultsFormationLheo />} />
        <Route path="/resultats/engagement" element={<ResultsEngagement publisher={['5f5931496c7ea514150a818f']} />} />
        <Route
          path="/resultats/service-civique"
          element={<ResultsEngagement publisher={['5f99dbe75eb1ad767733b206']} />}
        />
        <Route path="/resultats/emploi" element={<ResultsEmploi />} />
      </Routes>
    </AdvertSearchProvider>
  );
};

export default AdvertsRoute;
