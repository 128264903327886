import appEnvironment from 'config/environment';
export const getDomainWithoutProtocol = () => {
  let domain = appEnvironment.REACT_APP_PUBLIC_URL || '';

  if (domain?.startsWith('http://')) {
    domain = domain.replace('http://', '');
  }

  if (domain?.startsWith('https://')) {
    domain = domain.replace('https://', '');
  }

  if (domain?.split(':')?.length === 2) {
    domain = domain.split(':')[0];
  }

  return domain?.replace(/\/$/, '');
};
