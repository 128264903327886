import React, { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import createLazyComponent from '../../utils/createLazyComponent';

const DetailJob = createLazyComponent(() => import('./containers/DetailJobV2'));
const Search = createLazyComponent(() => import('./containers/JobsSearch'));
const SearchResults = createLazyComponent(() => import('./containers/JobsSearchResults'));
const TopJobContainer = createLazyComponent(() => import('./containers/TopJobContainer'));
const FavoriteJobsContainer = createLazyComponent(() => import('./containers/FavoriteJobsContainer'));
const NearbyContainer = createLazyComponent(() => import('./containers/NearbyJobsContainer'));
const TopJobBeforeContainer = createLazyComponent(() => import('./containers/TopJobBeforeContainer'));

const JobsRoute: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/metiers-recommandes" element={<TopJobBeforeContainer />} />
      <Route path="/metiers-recommandes/resultats" element={<TopJobContainer />} />
      <Route path="/metiers-favoris" element={<FavoriteJobsContainer />} />
      {/* TODO: Removed by Pole emploi, replace it ? */}
      {/* <Route path="/pres-de-chez-moi" element={<NearbyContainer />} /> */}
      <Route path="/recherche" element={<Search />} />
      <Route path="/recherche/resultats" element={<SearchResults />} />
      <Route path="/:id" element={<DetailJob />} />
    </Routes>
  );
};

export default JobsRoute;
