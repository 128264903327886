import React, { FunctionComponent, InputHTMLAttributes } from 'react';
import classNames from 'common/utils/classNames';
import { createUUID } from 'utils';

export type CheckboxProps = {
  label?: string | React.ReactNode;
  sizeVariant?: 'sm' | 'md';
} & InputHTMLAttributes<HTMLInputElement>;

const sizes: { [index: string]: string } = {
  sm: 'h-4 w-4 text-sm',
  md: 'h-6 w-6',
};

const Checkbox: FunctionComponent<CheckboxProps> = ({ sizeVariant = 'md', label, disabled, required, ...rest }) => {
  const id = `checkbox.${label || rest.id || createUUID()}`;

  return (
    <div>
      <div className="flex space-x-4 items-start">
        <input
          id={id}
          type="checkbox"
          className={classNames(
            'checked:text-lena-blue-dark rounded disabled:opacity-40',
            sizes[sizeVariant],
            !disabled && 'cursor-pointer',
          )}
          aria-checked={rest.checked}
          aria-label={`${label}`}
          required={required}
          disabled={disabled}
          {...rest}
        />
        {label && (
          <label htmlFor={id} className="select-none cursor-pointer">
            {label}
            {required && <span className="ml-1">*</span>}
          </label>
        )}
      </div>
    </div>
  );
};

export default Checkbox;
