import { gql, MutationHookOptions } from '@apollo/client';
import { useLocalMutation } from 'common/hooks/apollo';

export const DownloadSelfevalCardMutation = gql`
  mutation DownloadSelfevalCard {
    downloadSelfeval
  }
`;

export const useDownloadSelfevalCardMutation = (options: MutationHookOptions<{ downloadSelfeval: string }> = {}) =>
  useLocalMutation(DownloadSelfevalCardMutation, options);
