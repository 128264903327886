import { Popover, Transition } from '@headlessui/react';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'common/utils/classNames';
import PictoExperience from 'assets/svg/generic/plus.svg';
import PictoSearch from 'assets/svg/navigation/search.svg';
import DiagoSvg from 'assets/logos/diagoriente-mini.svg';
import { ReactComponent as ArrowDownSvg } from 'assets/svg/generic/arrow_down.svg';
import { useAppFlavor, useMediaQuery } from 'hooks';
import { AppFlavor } from 'contexts/AppFlavorContext';
import HeartSvg from 'assets/svg/navigation/heart_m.svg';
import TopJobSvg from 'assets/svg/navigation/top_job.svg';
import StarSvg from 'assets/svg/navigation/star_m.svg';
import SettingsSvg from 'assets/svg/navigation/settings_m.svg';
import LogoutSvg from 'assets/svg/navigation/logout.svg';
import { useCookies } from 'react-cookie';
import appEnvironment from 'config/environment';
import { getDomainWithoutProtocol } from 'utils';

const AppDesktopMenu: FunctionComponent = () => {
  const { flavor, is1J1S } = useAppFlavor();
  const hasJO2024 = flavor === AppFlavor.Olympics;
  const isOrientationTunnel = flavor === AppFlavor.Orientation1J1S;
  const isCVTunnel = flavor === AppFlavor.Cv1J1S;
  const isDesktop = useMediaQuery('md');
  const [, , removeCookie] = useCookies(['consent']);

  const links = !is1J1S
    ? [
        {
          label: 'Accueil',
          icon: DiagoSvg,
          link: '/',
        },
        ...(hasJO2024
          ? [
              { label: 'Identifiez vos compétences', link: '/ajout-exp' },
              { label: 'Formez-vous', link: '/jo-2024/formations' },
              { label: 'Missions de bénévolat', link: '/jo-2024/benevolat/recherche' },
            ]
          : [
              {
                label: 'Ajouter une expérience',
                icon: PictoExperience,
                link: '/ajout-exp',
              },
              {
                label: "Ajouter mes centres d'intérêt",
                icon: PictoExperience,
                link: '/centres_interet/chat',
              },
              {
                label: 'Rechercher des opportunités',
                icon: PictoSearch,
                link: '/opportunites/recherche',
              },
              {
                label: 'Rechercher un métier',
                icon: PictoSearch,
                link: '/metiers/recherche',
              },
            ]),
      ]
    : [
        { label: 'Accueil', icon: DiagoSvg, link: '/' },
        ...(isOrientationTunnel
          ? [
              {
                label: "Mes centres d'intérêt",
                icon: HeartSvg,
                link: '/profil/mes-centres-d-interet',
              },
              {
                label: 'Métiers recommandés',
                icon: TopJobSvg,
                link: '/metiers/metiers-recommandes',
              },
              {
                label: 'Métiers favoris',
                icon: StarSvg,
                link: '/metiers/metiers-favoris',
              },
            ]
          : []),
        ...(isCVTunnel ? [{ label: 'Ajouter une expérience', icon: PictoExperience, link: '/ajout-exp' }] : []),
        {
          label: 'Mes informations',
          icon: SettingsSvg,
          link: '/profil/reglages',
        },
        {
          label: 'Déconnexion',
          icon: LogoutSvg,
          link: '/deconnexion',
        },
      ];

  const link = (icon: any, label: string, url: string) => {
    return (
      <Link to={url}>
        <div
          className={classNames(
            'flex flex-row items-center space-x-2 text-lena-blue-dark',
            'hover:bg-lena2021-gray-lightest active:bg-lena2021-gray-light rounded px-4 py-2',
          )}
        >
          {icon && <img src={icon} alt={label} height={16} width={16} />}
          <span>{label}</span>
        </div>
      </Link>
    );
  };

  const handleResetCookie = () => {
    removeCookie('consent', { domain: `${appEnvironment.isProduction ? '.' : ''}${getDomainWithoutProtocol()}` });
    window.location.reload();
  };

  return (
    <Popover>
      <Popover.Button
        className={classNames(
          'rounded px-4 py-2 bg-lena-lightgray text-lena-blue-dark uppercase',
          'hover:bg-lena-lightgray2',
        )}
      >
        <div className="flex flex-row space-x-4 items-center justify-center">
          <span>Menu</span>
          <ArrowDownSvg className="h-4 w-4" />
        </div>
      </Popover.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform opacity-0"
      >
        <Popover.Panel className="absolute z-10 bg-white rounded-md w-max filter drop-shadow-md">
          <div className="p-2 divide-y divide-lena-lightgray2">
            <div className="pb-2">
              {is1J1S && !isDesktop && (
                <div className="pt-2">
                  <a
                    className={classNames(
                      'flex flex-row items-center space-x-2 text-lena-blue-dark',
                      'hover:bg-lena2021-gray-lightest active:bg-lena2021-gray-light rounded px-4 py-2 font-marianne',
                    )}
                    href="https://1jeune1solution.gouv.fr"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <span>Revenir sur 1jeune1solution.gouv.fr</span>
                  </a>
                </div>
              )}

              {links.map((v, i) => (
                <div key={i}>{link(v.icon, v.label, v.link)}</div>
              ))}
            </div>
            <div className="pt-2">
              {[
                {
                  label: 'Préférences des cookies',
                  url: window.location.href,
                  onClick: handleResetCookie,
                },
                {
                  label: 'Aide',
                  url: 'https://diagoriente.beta.gouv.fr/aide',
                },
                {
                  label: "Conditions d'utilisation",
                  url: 'https://diagoriente.beta.gouv.fr/conditions-generales',
                },
                {
                  label: 'Mentions légales',
                  url: 'https://diagoriente.beta.gouv.fr/mentions-legales',
                },
                {
                  label: 'Politique de confidentialité',
                  url: 'https://diagoriente.beta.gouv.fr/confidentialite',
                },
              ].map((item: any, i) => (
                <div key={i}>
                  {item?.onClick ? (
                    <span
                      className={classNames(
                        'flex flex-row items-center space-x-2 text-lena-blue-dark',
                        'hover:bg-lena2021-gray-lightest active:bg-lena2021-gray-light rounded px-4 py-2 cursor-pointer',
                      )}
                      onClick={item?.onClick}
                    >
                      <span>{item.label}</span>
                    </span>
                  ) : (
                    <a
                      className={classNames(
                        'flex flex-row items-center space-x-2 text-lena-blue-dark',
                        'hover:bg-lena2021-gray-lightest active:bg-lena2021-gray-light rounded px-4 py-2',
                      )}
                      href={item.url}
                      rel="noopener noreferrer"
                      target={'_blank'}
                    >
                      <span>{item.label}</span>
                    </a>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default AppDesktopMenu;
