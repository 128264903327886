const appEnvironment = {
  NODE_ENV: process.env.NODE_ENV,

  // App settings
  REACT_APP_PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL,
  REACT_APP_STATIC_URL: process.env.REACT_APP_STATIC_URL,
  REACT_APP_BACKEND_URL: process.env.REACT_APP_BACKEND_URL,

  // DEVELOPMENT settings
  REACT_APP_DEBUG_BAR: process.env.REACT_APP_DEBUG_BAR === 'true',

  // Build Info
  REACT_APP_RELEASE_NAME: process.env.REACT_APP_RELEASE_NAME,
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  REACT_APP_BRANCH_NAME: process.env.REACT_APP_BRANCH_NAME || process.env.REACT_APP_VERCEL_GIT_COMMIT_REF,
  REACT_APP_COMMIT_ID: process.env.REACT_APP_COMMIT_ID || process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA?.slice(0, 8),

  // PostHog
  REACT_APP_POSTHOG_PROJECT_KEY: process.env.REACT_APP_POSTHOG_PROJECT_KEY,
  REACT_APP_POSTHOG_HOST_URL: process.env.REACT_APP_POSTHOG_HOST_URL,

  // Sentry
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,

  // PE Connect
  REACT_APP_PE_CLIENT_ID: process.env.REACT_APP_PE_CLIENT_ID,
  REACT_APP_PE_SCOPE: process.env.REACT_APP_PE_SCOPE,

  // hCaptcha
  // 2023-04-06: Remove hCaptcha for GDPR
  REACT_APP_HCAPTCHA_KEY: '', // process.env.REACT_APP_HCAPTCHA_KEY

  // Mapbox
  REACT_APP_MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,

  // GDPR consent cookie domain
  REACT_APP_GDPR_DOMAIN_BASE: process.env.REACT_APP_GDPR_DOMAIN_BASE || '',
};

const isDevelopment = appEnvironment.REACT_APP_ENVIRONMENT !== 'production';
const isProduction = ['staging', 'production'].includes(appEnvironment.REACT_APP_ENVIRONMENT || '');

export default { ...appEnvironment, isDevelopment, isProduction };
