import { useMemo, useState } from 'react';
import sift3 from 'utils/sift3';

const domains = [
  'aliceadsl.fr',
  'club-internet.fr',
  'clubinternet.fr',
  'free.fr',
  'gmail.com',
  'gmx.fr',
  'hotmail.fr',
  'icloud.com',
  'laposte.net',
  'live.com',
  'live.fr',
  'msn.com',
  'outlook.com',
  'outlook.fr',
  'orange.fr',
  'orange.net',
  'protonmail.com',
  'sfr.fr',
  'wanadoo.fr',
  'yahoo.com',
  'yahoo.fr',
];

const useEmailDomainSuggestion = () => {
  const [value, setValue] = useState<string>('');

  const suggested = useMemo(() => {
    const [left, domain] = value.split('@');

    if (domain && domain.length > 2) {
      const suggestions = domains.sort((a, b) => {
        return sift3(a, domain) - sift3(b, domain);
      });

      return left + '@' + suggestions[0];
    }
  }, [value]);

  return {
    value,
    setValue,
    suggested,
    reset: () => setValue(''),
  };
};

export default useEmailDomainSuggestion;
