import React, { FC, useEffect, useRef, useState } from 'react';
import { useFloating } from '@floating-ui/react-dom-interactions';
import { autoUpdate, shift, flip, offset } from '@floating-ui/dom';
import moment from 'moment';

import useOnClickOutside from 'common/hooks/useOnClickOutside';
import classNames from 'common/utils/classNames';

import { createUUID, mergeRefs } from 'utils';
import { HelperText } from 'components/design-system/HelperText';

import { ReactComponent as ChevronLeft } from 'assets/svg/modules/interests/chevron_b_left.svg';
import { ReactComponent as ChevronRight } from 'assets/svg/modules/interests/chevron_b_right.svg';
import { ReactComponent as CrossSvg } from 'assets/svg/generic/cross.svg';
import { ReactComponent as CalendarSvg } from 'assets/svg/legacy/calendar.svg';

const MONTHS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

type FormatDate = {
  date?: Date | undefined;
  string?: string | undefined;
};

export type MonthPickerProps = {
  label?: string;
  labelHidden?: boolean;
  date?: Date | undefined;
  onChange: (d: FormatDate) => void;
  disabled?: boolean;
  required?: boolean;
  error?: string | boolean;
  clearable?: boolean;
};

const MonthPicker: FC<MonthPickerProps> = (props: MonthPickerProps) => {
  const { label, labelHidden = false, date, onChange, disabled, required = false, error, clearable = true } = props;

  const [year, setYear] = useState<number>(0);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setYear(date ? date.getFullYear() : new Date(Date.now()).getFullYear());
  }, []);

  const { x, y, reference, floating, strategy } = useFloating({
    open: showCalendar,
    onOpenChange: setShowCalendar,
    whileElementsMounted: autoUpdate,
    middleware: [offset(1), shift({ padding: 10 }), flip({ fallbackPlacements: ['bottom', 'top'] })],
  });

  useOnClickOutside(calendarRef, () => setShowCalendar(false));

  const handleChangeDate = (month: number) => {
    if (year) {
      const initDate = new Date(year, month, 15);

      initDate.setHours(12);
      initDate.setMinutes(0);
      initDate.setSeconds(0);
      initDate.setMilliseconds(0);

      const data: FormatDate = {
        date: initDate,
        string: moment(initDate).format('YYYY-MM-DD'),
      };

      onChange?.(data);
      setShowCalendar(false);
    }
  };

  const id = `pickerMonth.${createUUID()}`;

  const renderAsterik = () => {
    const text = required ? 'obligatoire' : 'optionnel';

    return (
      <span className="ml-1 font-extrabold">
        {required && <i aria-hidden="true">*</i>}
        <i className="sr-only">({text})</i>
      </span>
    );
  };

  const handleOpenCalendar = () => {
    if (!disabled) setShowCalendar(true);
  };

  return (
    <div className={'relative'}>
      {label && !labelHidden && (
        <label
          className="text-lena2021-blue-dark font-bold mr-6 select-none cursor-text"
          onClick={handleOpenCalendar}
          htmlFor={id}
        >
          {label}
          {renderAsterik()}
        </label>
      )}

      <div
        className={classNames(
          'bg-white border-lena-lightgray2 focus:border-lena-gray w-full border rounded-md focus:ring-0 focus:outline-none flex',
          disabled && 'bg-white opacity-50',
          label && !labelHidden && 'mt-1',
        )}
      >
        <button
          ref={reference}
          type={'button'}
          id={id}
          aria-haspopup="true"
          aria-expanded={showCalendar}
          onClick={handleOpenCalendar}
          className={'flex gap-3 py-3 px-4 flex-grow'}
          data-testid={'dropdown'}
        >
          <span>
            <CalendarSvg />
          </span>

          <span data-testid="label">
            {typeof date !== 'undefined'
              ? `${MONTHS[Number(date.getMonth())]} ${date.getFullYear()}`
              : 'Choisir une date'}
          </span>
        </button>

        {date && clearable && (
          <button
            type={'button'}
            onClick={() => onChange({ date: undefined, string: undefined })}
            data-testid="clear"
            className={
              'px-2 rounded-r-md bg-lena2021-gray-lightest hover:bg-lena2021-gray-light transition duration-200'
            }
          >
            <CrossSvg height={18} width={18} />
          </button>
        )}
      </div>

      {error && (
        <HelperText
          id={id}
          helperText={{
            type: 'error',
            text: error,
          }}
        />
      )}

      <div
        ref={mergeRefs([floating, calendarRef])}
        className="border border-lena-lightgray2 rounded-md flex flex-col z-100 bg-white"
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
          width: '100%',
          maxWidth: 450,
          visibility: showCalendar ? 'visible' : 'hidden',
          pointerEvents: showCalendar ? 'visible' : 'none',
        }}
      >
        <div>
          <div className={'flex items-center justify-between px-2 py-2 border-b border-b-lena2021-gray-light'}>
            <button
              type="button"
              data-testid="button-prev"
              className={' h-9 w-9 flex justify-center items-center rounded-md hover:bg-lena2021-gray-lightest'}
              onClick={() => setYear(year - 1)}
            >
              <ChevronLeft />
            </button>

            <span data-testid="year" className={'font-bold'}>
              {year}
            </span>

            <button
              type={'button'}
              data-testid="button-next"
              className={'h-9 w-9 flex justify-center items-center rounded-md hover:bg-lena2021-gray-lightest'}
              onClick={() => setYear(year + 1)}
            >
              <ChevronRight />
            </button>
          </div>

          <div
            role="menu"
            aria-label="Calendrier"
            aria-labelledby={id}
            className={'grid grid-cols-2 gap-2 lg:grid-cols-3 my-2 mt-2 px-2'}
            data-testid={'list'}
          >
            {MONTHS.map((month, i) => {
              const selected = date && i === date.getMonth() && date.getFullYear() === year;

              return (
                <button
                  key={month}
                  type={'button'}
                  aria-label={month}
                  onClick={() => handleChangeDate(i)}
                  data-selected={selected}
                  data-testid={selected ? 'month-select' : 'month'}
                  className={classNames(
                    'text-center duration-200 transition p-2 rounded-md',
                    selected ? 'font-bold bg-lena2021-blue-dark text-white' : 'hover:bg-lena2021-gray-lightest',
                  )}
                >
                  {month}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthPicker;
