import React from 'react';
import { Route, Routes } from 'react-router-dom';

import AppLayout from 'layouts/AppLayout/AppLayout';
import SelfevalHome from './routes/SelfevalHome';
import PreviewSelfevalCard from './routes/PreviewSelfevalCard';
import SelfevalAllInOne from './routes/SelfevalAllInOne';

const SelfevalRoutes = () => {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route index element={<SelfevalHome />} />
        <Route path="/:skillId" element={<SelfevalAllInOne />} />
        <Route path="/preview" element={<PreviewSelfevalCard />} />
      </Route>
    </Routes>
  );
};

export default SelfevalRoutes;
