import { isNullOrWhitespace } from 'utils/stringUtils';

const buildQueryParams = (uri: string, params: Record<string, string | false>) => {
  const filtered = Object.fromEntries(
    Object.entries(params).filter(([, v]) => !!v && !isNullOrWhitespace(v)),
  ) as Record<string, string>;

  return `${uri}?${new URLSearchParams(filtered).toString()}`;
};

export default buildQueryParams;
