import { QueryTuple } from '@apollo/client';
import { createContext } from 'react';

export interface Cursor {
  id: string;
  title: string;
  parentId: string;
}

export interface Interest {
  id: string;
  title: string;
  help: string;
  images: string[];
  cursors: Cursor[][];
}

export type MyCursor = Omit<Cursor, 'title'> & { familyId: string };

type CenterInterestContextData = {
  refetch: QueryTuple<any, {}>[1]['refetch'];
  interests: Interest[];
  myCursors: MyCursor[];
  loading: boolean;
  selectedCursors: MyCursor[];
  selectedFamilies: Interest[];
  setInterest: (i: Interest[]) => void;
  setSelectedCursors: (c: MyCursor[]) => void;
};

export const CenterInterestContext = createContext<CenterInterestContextData>({
  interests: [],
  myCursors: [],
  selectedFamilies: [],
  refetch: () => {
    return new Promise(() => {});
  },
  loading: false,
  selectedCursors: [],
  setSelectedCursors: () => {},
  setInterest: () => {},
});
