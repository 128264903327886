import React, { FunctionComponent } from 'react';
import { LoaderOval } from 'components/design-system';

const AppLoader: FunctionComponent<{ text?: string }> = ({ text }) => {
  return (
    <div className="flex flex-1 flex-col justify-center items-center bg-transparent space-y-4">
      <LoaderOval height={128} width={128} />
      {text && <div className="text-xl text-lena-black">{text}</div>}
    </div>
  );
};

export default AppLoader;
