import AppLayout from 'layouts/AppLayout/AppLayout';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import classNames from 'common/utils/classNames';
import AppFooter from 'layouts/AppLayout/AppFooter';
import useMediaQuery from '../../hooks/useMediaQuery';
import ProfileSidebar from './ProfileSidebar';
import { Outlet } from 'react-router-dom';

const ProfileLayout: FunctionComponent<PropsWithChildren<{ bgColor?: string }>> = ({ bgColor, children }) => {
  const isDesktop = useMediaQuery('md');

  return (
    <AppLayout>
      <div className="flex flex-row flex-1 absolute top-0 bottom-0 left-0 right-0 p-4">
        {isDesktop && <ProfileSidebar />}
        <div className={classNames('flex flex-col flex-1 md:overflow-auto', bgColor && bgColor)}>
          <div className="flex flex-1 flex-col">{children || <Outlet />}</div>
          <AppFooter />
        </div>
      </div>
    </AppLayout>
  );
};

export default ProfileLayout;
