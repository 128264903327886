import React, { ReactNode, Ref } from 'react';
import { createUUID } from 'utils/uuid';
import classNames from 'common/utils/classNames';

import { ReactComponent as HidePasswordIcon } from 'assets/svg/generic/hide_password.svg';
import { ReactComponent as ShowPasswordIcon } from 'assets/svg/generic/show_password.svg';

import { HelperText } from 'components/design-system/HelperText';
import useBoolean from 'common/hooks/useBoolean';

export type TextFieldProps = {
  label?: string;
  labelHidden?: boolean;
  isEmpty?: boolean;
  icon?: ReactNode;
  className?: string;
  helper?: ReactNode;
  error?: string | boolean;
  disabled?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const TextField = React.forwardRef((props: TextFieldProps, parentRef: Ref<HTMLInputElement>) => {
  const {
    id: idProp,
    label,
    labelHidden = false,
    isEmpty,
    className,
    helper,
    type = 'text',
    disabled,
    icon,
    error,
    children,
    ...rest
  } = props;

  const showPassword = useBoolean(false);

  const id = `textField.${idProp || createUUID()}`;

  const inputClasses = classNames([
    'w-full border rounded-md focus:ring-0 focus:outline-none py-3',
    !!error
      ? 'border-lena2021-corail-dark focus:border-lena2021-corail-dark text-lena2021-corail-dark'
      : 'border-lena-lightgray2 focus:border-lena-gray',
    isEmpty && 'border-lena2021-corail-dark focus:border-lena2021-corail-dark',
    (type === 'password' || error) && 'pr-8',
    icon && 'pl-10',
    className && { className },
    disabled && 'bg-lena-gray-light-2 cursor-not-allowed',
  ]);

  const renderAsterik = () => {
    const text = rest.required ? 'obligatoire' : 'optionnel';

    return (
      <span className="ml-1 font-extrabold">
        {rest.required && <i aria-hidden="true">*</i>}
        <i className="sr-only">({text})</i>
      </span>
    );
  };

  return (
    <div className="w-full">
      {label && !labelHidden && (
        <label className="text-lena2021-blue-dark font-bold mr-6 select-none" htmlFor={id}>
          {label}
          {rest.required && renderAsterik()}
        </label>
      )}

      <div className={`relative justify-center items-center w-full ${label && 'mt-1'}`}>
        {icon && <div className="absolute inset-y-0 left-4 flex items-center justify-center">{icon}</div>}

        <input
          id={id}
          ref={parentRef}
          className={inputClasses}
          disabled={disabled}
          type={type === 'password' && showPassword.value ? 'text' : type}
          aria-label={label}
          aria-invalid={Boolean(error)}
          aria-describedby={`helperText.${id}`}
          {...rest}
        />

        {children}

        {type === 'password' && (
          <button
            type="button"
            aria-label={'Afficher le mot de passe'}
            aria-checked={showPassword.value}
            onClick={() => showPassword.toggle()}
            className={`absolute inset-y-0 right-1 flex items-center justify-center select-none w-9 m-2 rounded-md`}
          >
            {showPassword.value ? (
              <HidePasswordIcon className="fill-current text-lena-gray" />
            ) : (
              <ShowPasswordIcon className="fill-current text-lena-gray" />
            )}
          </button>
        )}
      </div>

      {helper}

      {error && (
        <HelperText
          id={id}
          helperText={{
            type: 'error',
            text: error,
          }}
        />
      )}
    </div>
  );
});

export default TextField;
