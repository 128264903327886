import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { CenterInterestContext, MyCursor, Interest } from 'contexts/CenterInterestContext';
import { useInterests } from 'common/requests/interests';

const InterestProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [getInterestsCall, getInterestsState] = useInterests();
  const [selectedCursors, setSelectedCursors] = useState([] as MyCursor[]);
  const [selectedInterest, setInterest] = useState([] as Interest[]);

  const interests = useMemo(() => {
    if (!getInterestsState.data) return [];
    return getInterestsState.data.interests.data;
  }, [getInterestsState.data]);

  const myCursors = useMemo(() => {
    if (!getInterestsState.data) return [];
    return getInterestsState.data.myInterests.interests
      .map((interest) =>
        interest.cursors.map((cursor) => ({
          familyId: interest.interest.id,
          id: cursor.id,
          parentId: cursor.parentId,
        })),
      )
      .flat();
  }, [getInterestsState.data]);

  useEffect(() => {
    setSelectedCursors(myCursors);
  }, [myCursors]);

  useEffect(() => {
    getInterestsCall();
  }, []);

  return (
    <CenterInterestContext.Provider
      value={{
        interests,
        myCursors,
        refetch: getInterestsState.refetch,
        loading: getInterestsState.loading,
        selectedCursors,
        selectedFamilies: selectedInterest,
        setInterest,
        setSelectedCursors,
      }}
    >
      {children}
    </CenterInterestContext.Provider>
  );
};

export default InterestProvider;
