export default (type: string): { singular: string; plural: string; url: string } => {
  switch (type) {
    case 'personal':
      return { singular: 'personnelle', plural: 'personnelles', url: 'personal' };
    case 'professional':
      return { singular: 'professionnelle', plural: 'professionnelles', url: 'professional' };
    case 'voluntary':
      return { singular: 'de bénévolat', plural: 'de bénévolat', url: 'voluntary' };
    case 'engagement':
      return { singular: 'Service Civique', plural: 'Service Civique', url: 'engagement' };
    case 'sport_amateur':
      return { singular: 'de sport amateur', plural: 'de sport amateur', url: 'sport_amateur' };
    case 'sport_pro':
      return {
        singular: 'de Sport de haut niveau',
        plural: 'de Sport de haut niveau',
        url: 'sport_pro',
      };
    case 'entrepreneurship':
      return {
        singular: "d'entrepreneuriat",
        plural: "d'entrepreneuriat",
        url: 'entrepreneurship',
      };
    case 'insertion':
      return {
        singular: 'en parcours accompagné',
        plural: 'en parcours accompagné',
        url: 'insertion',
      };
    case 'militant':
      return {
        singular: "d'engagement militant / de citoyenneté",
        plural: "d'engagement militant / de citoyenneté",
        url: 'militant',
      };
    default:
      return { singular: 'personnelle', plural: 'personnelles', url: 'personal' };
  }
};
