import React, { FunctionComponent, PropsWithChildren } from 'react';
import classNames from 'common/utils/classNames';

const variants = {
  'blue-light': 'rounded-full bg-lena-blue-lightest text-lena-blue-dark',
  orange: 'rounded-full bg-lena-corail-light text-lena-blue-dark',
  'orange-light': 'rounded-full bg-lena-corail-lightest text-lena-blue-dark',
  'orange-light2': 'rounded-md bg-lena-corail-lightest text-lena-blue-dark',
};

export type PillProps = {
  extraClassName?: string;
  size?: 'sm';
  variant?: keyof typeof variants;
};

const Pill: FunctionComponent<PropsWithChildren<PillProps>> = ({
  variant = 'blue-light',
  extraClassName,
  size,
  children,
}) => {
  return (
    <div
      className={classNames(
        'inline-block px-3 py-1 text-center',
        size === 'sm' && 'text-sm',
        variants[variant],
        extraClassName,
      )}
    >
      {children}
    </div>
  );
};

export default Pill;
