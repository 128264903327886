/* eslint-disable no-console */
import { ApolloClient, ApolloLink, InMemoryCache, Observable, Operation } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';
import appEnvironment from 'config/environment';

let token = '';

export function setAuthorizationBearer(nextToken: string) {
  token = nextToken;
}

const request = async (operation: Operation) => {
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: ZenObservable.Subscription | undefined;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

export const client = new ApolloClient({
  name: 'Web',
  version: appEnvironment.REACT_APP_RELEASE_NAME,
  link: ApolloLink.from([
    requestLink,
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
        );
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    // Disable persisted queries in development mode
    // sha256 can only be used in secure context -https/localhost-, not compatible with appflavors
    ...(appEnvironment.NODE_ENV === 'development'
      ? []
      : [createPersistedQueryLink({ sha256, useGETForHashedQueries: true })]),
    createUploadLink({
      uri: appEnvironment.REACT_APP_BACKEND_URL,
      useGETForQueries: appEnvironment.NODE_ENV === 'production',
    }) as unknown as ApolloLink /* graphql-upload-link uses an older @apollo/client package */,
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          missionsEngagement: {
            keyArgs: [
              'distance',
              'domain',
              'lat',
              'lon',
              'openToMinors',
              'publisher',
              'reducedMobilityAccessible',
              'size',
            ],
            merge: (existing = { data: [] }, incoming) => {
              return { ...incoming, data: [...existing.data, ...incoming.data] };
            },
          },
          offers: {
            keyArgs: [
              'accesTravailleurHandicape',
              'code',
              'commune',
              'departement',
              'distance',
              'experienceExigence',
              'entreprisesAdaptees',
              'offresManqueCandidats',
              'sort',
              'typeContrat',
            ],
            merge: (existing = { data: [] }, incoming) => {
              return { ...incoming, data: [...existing.data, ...incoming.data] };
            },
          },
          formationsLheo: {
            keyArgs: ['codeRomes', 'departement', 'distance', 'location'],
            merge: (existing = [], incoming) => {
              return [...existing, ...incoming];
            },
          },
        },
      },
    },
  }),
});
