import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useListCvTemplates } from 'common/requests/cv_v3';
import { Button, Dropdown } from 'components/design-system';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import useMediaQuery from 'hooks/useMediaQuery';
import CvThumbnail from 'containers/SkillCardV4Container/components/CVThumbnail';
import InitializeTemplate from 'containers/SkillCardV4Container/templates/InitializeTemplate';
import { PreviewA4 } from 'containers/SkillCardV4Container/components/editor';
import { placeholderDataV4 } from 'containers/SkillCardV4Container/helpers/placeholderData';
import clsx from 'clsx';
import DEFAULT_COLORS from 'containers/SkillCardV4Container/constants/colors';
import { ReactComponent as LoaderDotsSvg } from 'assets/svg/loader-dots.svg';

type Props = {
  avatar?: string;
  onPrev: () => void;
  onSubmit: (templateId: string, color: string, variant: string) => void;
};

const CreateV4StepTemplate: React.FC<Props> = ({ avatar, onSubmit, onPrev }) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [selectedColor, setSelectedColor] = useState(DEFAULT_COLORS[0].code);
  const [selectedVariant, setSelectedVariant] = useState('');
  const isDesktop = useMediaQuery('md');

  const templatesState = useListCvTemplates();
  const allServerTemplates = templatesState.data?.cvTemplates.data || [];

  const selectedTemplate = useMemo(() => {
    return allServerTemplates.find((v) => v.id === selectedTemplateId);
  }, [selectedTemplateId]);

  useEffect(() => {
    if (!selectedTemplate) return;
    if (!selectedTemplate.variants.find((tv) => tv.key === selectedVariant))
      setSelectedVariant(selectedTemplate.variants[0].key);
  }, [selectedTemplate]);

  useEffect(() => {
    if (allServerTemplates && allServerTemplates.length && !selectedTemplate)
      setSelectedTemplateId(allServerTemplates[0]?.id);
  }, [allServerTemplates]);

  const renderServerTemplates = () => {
    return allServerTemplates?.map((v) => (
      <div
        key={v.templateKey}
        onClick={() => setSelectedTemplateId(v.id)}
        className={clsx(
          'cursor-pointer outline-0 ring-0 border-2 hover:border-lena-corail',
          v.id === selectedTemplateId ? 'border-lena-corail' : 'border-transparent',
        )}
      >
        <CvThumbnail size={'sm'}>
          <PreviewA4>
            <InitializeTemplate
              theme={v.templateKey as any}
              data={{ ...placeholderDataV4, photo: avatar || placeholderDataV4.photo }}
              editorData={{}}
              config={{ preview: true, print: true }}
              customizationOptions={{ variant: selectedVariant as any, accentColor: selectedColor }}
            />
          </PreviewA4>
        </CvThumbnail>
      </div>
    ));
  };

  return (
    <div className="divide-y divide-lena-lightgray2">
      <div className="space-y-4 py-4">
        <div className="font-bold text-xl text-center py-2">Choisissez un modèle</div>
        <div>
          {templatesState.loading && <LoaderDotsSvg height={128} />}

          <Suspense fallback={<LoaderDotsSvg height={128} />}>
            {isDesktop ? (
              <div className="flex flex-wrap justify-center gap-x-2 gap-y-4">{renderServerTemplates()}</div>
            ) : (
              <ScrollMenu>{renderServerTemplates()}</ScrollMenu>
            )}
          </Suspense>
        </div>
      </div>
      <div className="py-8 grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8">
        <Dropdown
          label={'Couleur'}
          choices={DEFAULT_COLORS.map((v) => ({ value: v.code, render: v.label }))}
          title="Choisissez dans la liste"
          value={selectedColor}
          onChangeSingle={(v) => setSelectedColor(v)}
        />
        <Dropdown
          disabled={!selectedTemplate || (selectedTemplate.variants.length || 0) <= 1}
          label={'Style'}
          title="Choisissez dans la liste"
          choices={selectedTemplate?.variants.map((v) => ({ value: v.key, render: v.name })) || []}
          value={selectedVariant}
          onChangeSingle={(v) => setSelectedVariant(v)}
        />
      </div>
      <div className="py-8 flex flex-col items-center justify-center space-y-1">
        <Button
          disabled={!selectedTemplateId || !selectedColor || !selectedVariant}
          variant="secondary"
          size="lg"
          onClick={() => onSubmit?.(selectedTemplateId, selectedColor, selectedVariant)}
        >
          Valider
        </Button>
        <Button variant="ghost" onClick={() => onPrev?.()}>
          Etape précédente
        </Button>
      </div>
    </div>
  );
};

export default CreateV4StepTemplate;
