import { TemplateContentProps } from 'containers/SkillCardV4Container/types/template';
import AvatarDefaultSvg from 'assets/svg/modules/cv/avatar-default-filled.svg';

const fakeData = {
  skills: [
    {
      itemId: '637cab32215d0c5d5c89ef12',
      sourceType: 'ORIGIN',
      type: 'personal',
      data: {
        id: '637b405fffe136c3c2ee65e1',
        activities: [
          { __typename: 'Activity', id: '61927318ac6c186b3480df30', title: 'Faire des ourlets' },
          { __typename: 'Activity', id: '61927318ac6c186b3480df35', title: 'Concevoir des vêtements, des accessoires' },
          { __typename: 'Activity', id: '61927318ac6c186b3480df33', title: 'Utiliser une machine à coudre' },
        ],
        theme: { __typename: 'Theme', title: 'Couture/tricot/broderie' },
      },
    },
    {
      itemId: '637cab32215d0c5d5c89ef14',
      sourceType: 'ORIGIN',
      type: 'professional',
      data: {
        id: '637cab29215d0c5d5c89edd6',
        startDate: '2022-01-15',
        endDate: '2022-06-15',
        structure: 'Patapain',
        activities: [
          { __typename: 'Activity', id: '61423b5b5475530aeb09f5b2', title: 'Déterminer des mesures correctives' },
          { __typename: 'Activity', id: '61423b5b5475530aeb09f522', title: "Suivre l'état des stocks" },
          { __typename: 'Activity', id: '61423b5b5475530aeb09f73f', title: 'Préparer les commandes' },
          { __typename: 'Activity', id: '61423b5b5475530aeb09f807', title: 'Contrôler la mise en rayon des articles' },
          {
            __typename: 'Activity',
            id: '61423b5b5475530aeb09f809',
            title: "Adapter le plan d'implantation des articles",
          },
          { __typename: 'Activity', id: '61423b5b5475530aeb09f80f', title: "Coordonner l'activité d'une équipe" },
          { __typename: 'Activity', id: '60f030e04539840cbeb928f3', title: 'Gérer des invendus alimentaires' },
        ],
        theme: { __typename: 'Theme', title: 'Chef de rayon boulangerie' },
      },
    },
  ],
  competences: {
    technique: [
      {
        manual: false,
        cmpType: 'technique',
        id: '614139f5c932a6142ec9ae79',
        __typename: 'MacroCompetence',
        title: 'Créer, concevoir de nouveaux produits ou des améliorations produits',
      },
      {
        manual: false,
        cmpType: 'technique',
        id: '63175d561164b9244f69d9f6',
        __typename: 'MacroCompetence',
        title: 'Utiliser un outil, une machine, un équipement',
      },
      {
        manual: false,
        cmpType: 'technique',
        id: '61413b591931e5150655db94',
        __typename: 'MacroCompetence',
        title: 'Evacuer, recycler des déchets, des produits',
      },
      {
        manual: false,
        cmpType: 'technique',
        id: '614139f5c932a6142ec9ae73',
        __typename: 'MacroCompetence',
        title: "Respecter les règles d'hygiène, de sécurité et d'environnement (HSE)",
      },
      {
        manual: false,
        cmpType: 'technique',
        id: '6141350254f83111438223b2',
        __typename: 'MacroCompetence',
        title: 'Gérer les stocks',
      },
      {
        manual: false,
        cmpType: 'technique',
        id: '63175d561164b9244f69da4a',
        __typename: 'MacroCompetence',
        title: 'Déterminer des mesures correctives',
      },
      {
        manual: false,
        cmpType: 'technique',
        id: '614139f5c932a6142ec9ae94',
        __typename: 'MacroCompetence',
        title: 'Organiser le traitement des commandes',
      },
      {
        manual: false,
        cmpType: 'technique',
        id: '6141350254f83111438223a3',
        __typename: 'MacroCompetence',
        title: 'Organiser et planifier une activité',
      },
      {
        manual: false,
        cmpType: 'technique',
        id: '63175d561164b9244f69da57',
        __typename: 'MacroCompetence',
        title: 'Analyser et contrôler la qualité et la conformité des process',
      },
      {
        manual: false,
        cmpType: 'technique',
        id: '614139f5c932a6142ec9ae15',
        __typename: 'MacroCompetence',
        title: 'Transmettre et faire respecter les règles et les procédures',
      },
      {
        manual: false,
        cmpType: 'technique',
        id: '6141350254f83111438223e5',
        __typename: 'MacroCompetence',
        title: 'Organiser, aménager un espace de vente',
      },
    ],
    transversal: [
      {
        manual: false,
        cmpType: 'transversal',
        id: '6141350254f8311143822398',
        __typename: 'MacroCompetence',
        title: 'Identifier et respecter les règles, normes, procédures et consignes (hors HSE)',
      },
      {
        manual: false,
        cmpType: 'transversal',
        id: '6141350254f83111438223ae',
        __typename: 'MacroCompetence',
        title: 'Maîtriser une gestuelle, une technique',
      },
      {
        manual: false,
        cmpType: 'transversal',
        id: '63175d561164b9244f69daf0',
        __typename: 'MacroCompetence',
        title: 'Inspirer, donner du sens',
      },
      {
        manual: false,
        cmpType: 'transversal',
        id: '614139f5c932a6142ec9ae54',
        __typename: 'MacroCompetence',
        title: 'Anticiper, prévoir',
      },
      {
        manual: false,
        cmpType: 'transversal',
        id: '63175d561164b9244f69db3c',
        __typename: 'MacroCompetence',
        title: 'Analyser, résoudre un problème courant ou complexe',
      },
      {
        manual: false,
        cmpType: 'transversal',
        id: '614139f5c932a6142ec9ae52',
        __typename: 'MacroCompetence',
        title: "S'adapter aux changements",
      },
      {
        manual: false,
        cmpType: 'transversal',
        id: '6141350254f83111438223cd',
        __typename: 'MacroCompetence',
        title: 'Animer, coordonner une équipe',
      },
    ],
  },
  email: 'exemple@domaine.fr',
  phone: '06 01 02 03 04',
  extraInfos: 'Permis B ; Anglais niveau B1',
  job: 'Boulanger',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus libero optio rerum. Nostrum omnis quisquam ullam.',
  formations: [{ id: 'local201', value: 'CAP Boulangerie, CFA de Laon, 2017-2019' }],
  keywords: [
    { id: 'local154', value: 'Sérieux' },
    { id: 'local155', value: 'Aimable' },
    { id: 'local156', value: 'Motivé' },
  ],
  contentOrder: [
    { visible: true, name: 'comp-transversal' },
    { visible: true, name: 'comp-technique' },
    { visible: true, name: 'experience-personal' },
    { visible: true, name: 'experience-professional' },
    { visible: false, name: 'volunteers' },
    { visible: false, name: 'experience-voluntary' },
    { visible: false, name: 'experience-sport_amateur' },
    { visible: false, name: 'experience-sport_pro' },
    { visible: false, name: 'experience-entrepreneurship' },
    { visible: false, name: 'experience-insertion' },
    { visible: false, name: 'experience-militant' },
  ],
};

export const placeholderDataV4: Partial<TemplateContentProps> = {
  ...fakeData,
  firstName: 'Prénom',
  lastName: 'NOM',
  photo: AvatarDefaultSvg,
};
