import React, { FunctionComponent } from 'react';
import { SkillCardV4TemplateInputProps } from '../types/template';

const Standard = React.lazy(() => import('./models/standard/Base'));
const TemplateTwo = React.lazy(() => import('./models/template_two/Base'));
const TemplateThree = React.lazy(() => import('./models/template_three/Base'));
const TemplateFour = React.lazy(() => import('./models/template_four/Base'));
const TemplateEight = React.lazy(() => import('./models/template_eight/Base'));
const TemplateFive = React.lazy(() => import('./models/template_five/Base'));

// Templates recommendations
const RecoStandard = React.lazy(() => import('./models/standard/components/BaseReco'));
const RecoTwo = React.lazy(() => import('./models/template_two/components/BaseReco'));
const RecoThree = React.lazy(() => import('./models/template_three/components/BaseReco'));
const RecoFour = React.lazy(() => import('./models/template_four/components/BaseReco'));
const RecoFive = React.lazy(() => import('./models/template_eight/components/BaseReco'));
const RecoEight = React.lazy(() => import('./models/template_five/components/BaseReco'));

type TemplateEntry = {
  key: string;
  component: FunctionComponent<SkillCardV4TemplateInputProps>;
  componentReco: FunctionComponent<any>;
};

export const allCVTemplates: TemplateEntry[] = [
  {
    key: 'standard',
    component: Standard,
    componentReco: RecoStandard,
  },
  {
    key: 'template_two',
    component: TemplateTwo,
    componentReco: RecoTwo,
  },
  {
    key: 'template_three',
    component: TemplateThree,
    componentReco: RecoThree,
  },
  {
    key: 'template_four',
    component: TemplateFour,
    componentReco: RecoFour,
  },
  {
    key: 'template_five',
    component: TemplateFive,
    componentReco: RecoFive,
  },
  {
    key: 'template_eight',
    component: TemplateEight,
    componentReco: RecoEight,
  },
];

export default allCVTemplates;
