export type TemplateContentProps = {
  overrides: any;
  competences: any;
  recommendations: any;
  skills: any;
  job: string;
  description: string;
  photo: any;
  formations: any;
  keywords: any;
  firstName?: string;
  lastName?: string;
  email: string;
  phone: string;
  extraInfos: string;
  contentOrder?: { visible: boolean; name: string }[];
  volunteers: any[];
};

export type InitializeTemplateProps = {
  theme: string;
  data?: Partial<TemplateContentProps>;
  editorData?: Partial<TemplateEditorProps>;
  config?: SkillCardV4TemplateConfig;
  customizationOptions?: Partial<TemplateCustomizationOptions>;
};

export type TemplateEditorProps = {
  cvId: string;
  currentKeyStep: string;
  setCurrentStepByKey: (s: string) => void;
  showAssistant: boolean;
  showColumnEditor: boolean;
};

export type Editor = {
  showAssistant: boolean;
  showColumnEditor: boolean;
  toggleShowColumnEditor: () => void;
};

export type SkillCardV4TemplateConfig = {
  preview: boolean;
  print: boolean;
};

export type TemplateVariant = string;
export type TemplateFont = string;

export type TemplateCustomizationOptions = {
  variant: string;
  accentColor: string;
  font: string;
};

// common props passed to every CV template to be rendered
export type SkillCardV4TemplateInputProps = {
  data?: Partial<TemplateContentProps>;
  editorData?: Partial<TemplateEditorProps>;
  config?: SkillCardV4TemplateConfig;
  customizationOptions?: Partial<TemplateCustomizationOptions>;
};

export const allExperiences: { value: string; render: string }[] = [
  { value: 'personal', render: 'Expériences personnelles' },
  { value: 'professional', render: 'Expériences professionnelles' },
  { value: 'voluntary', render: 'Expériences de bénévolat' },
  { value: 'sport_amateur', render: 'Expériences de sport amateur' },
  { value: 'sport_pro', render: 'Expériences de sport professionnel et de haut niveau' },
  { value: 'entrepreneurship', render: "Expériences d'entrepreneuriat" },
  { value: 'insertion', render: 'Expériences en parcours accompagné' },
  { value: 'militant', render: "Expériences d'engagement militant / de citoyenneté" },
];

export const allCvBlocks: any = {
  'comp-transversal': 'Compétences transversales',
  'comp-technique': 'Compétences techniques',
  volunteers: 'Service Civique',
  ...allExperiences.reduce((obj, item) => Object.assign(obj, { [`experience-${item.value}`]: item.render }), {}),
};
