import moment from 'moment/moment';

export const formatDate = (date?: string) => {
  return moment(date).format('MMMM YYYY');
};

export const formatDateRange = (dateStart?: string, dateEnd?: string) => {
  if (!dateStart && !dateEnd) return '';

  return `${formatDate(dateStart)} - ${dateEnd ? formatDate(dateEnd) : 'Présent'}`;
};
