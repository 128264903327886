import { gql, QueryHookOptions } from '@apollo/client';
import { useLocalLazyQuery, useLocalQuery } from 'common/hooks/apollo';

const ListSkillsForSelfevalDocument = gql`
  query ListSkillsForSelfeval {
    me {
      skills {
        id
        domain
        intitule
        theme {
          id
          title
        }
        selfeval {
          createdAt
        }
        structure
        startDate
        endDate
      }
    }
  }
`;

export const useListSkillsForSelfevalLazyQuery = (options: QueryHookOptions<any, void> = {}) =>
  useLocalLazyQuery(ListSkillsForSelfevalDocument, options);
