import React from 'react';
import { formatDateRange } from 'utils/formatDate';

const DynamicHeader = ({ experience }: any) => (
  <div className="bg-lena-lightgray p-8">
    <div className="container">
      <div className="flex w-full justify-between">
        <div className="inline-flex gap-2 items-center">
          <h2 className="text-xl font-bold">Expérience évaluée :</h2>

          <div
            style={{
              marginTop: '2px',
            }}
            className="p-2 px-4 inline-flex gap-4"
          >
            <p className="font-bold">{experience?.intitule || experience?.theme?.title}</p>
            <p> {formatDateRange(experience?.startDate, experience?.endDate)}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default DynamicHeader;
