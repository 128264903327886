import React from 'react';
import { Link } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';
import TopJobSvg from 'assets/svg/navigation/top_job.svg';
import StarSvg from 'assets/svg/navigation/star_m.svg';
import HeartSvg from 'assets/svg/navigation/heart_m.svg';
import UserSvg from 'assets/svg/navigation/my_exps.svg';
import CVSvg from 'assets/svg/navigation/cv_m.svg';
import SettingsSvg from 'assets/svg/navigation/settings_m.svg';
import LogoutSvg from 'assets/svg/navigation/logout.svg';
import classNames from 'common/utils/classNames';
import { ReactComponent as UserProfileIcon } from 'assets/svg/navigation/profile.svg';
import { AppFlavor } from 'contexts/AppFlavorContext';
import useAppFlavor from 'hooks/useAppFlavor';

type Props = {
  hasLabel?: boolean;
};
export const AppUserMenu = ({ hasLabel }: Props) => {
  const { flavor } = useAppFlavor();

  const links = [
    flavor !== AppFlavor.Olympics
      ? [
          {
            label: 'Métiers recommandés',
            icon: TopJobSvg,
            link: '/metiers/metiers-recommandes',
          },
          {
            label: 'Métiers favoris',
            icon: StarSvg,
            link: '/metiers/metiers-favoris',
          },
        ]
      : [],
    [
      {
        label: 'Mes expériences',
        icon: UserSvg,
        link: '/profil/mes-experiences',
      },

      ...(flavor !== AppFlavor.Olympics
        ? [
            {
              label: "Mes centres d'intérêt",
              icon: HeartSvg,
              link: '/profil/mes-centres-d-interet',
            },
            {
              label: 'Mes CV',
              icon: CVSvg,
              link: '/mes-cv',
            },
          ]
        : []),
    ],
    [
      {
        label: 'Mes informations',
        icon: SettingsSvg,
        link: '/profil/reglages',
      },
    ],
    [
      {
        label: 'Déconnexion',
        icon: LogoutSvg,
        link: '/deconnexion',
      },
    ],
  ];

  const link = (icon: any, label: string, url: string) => {
    return (
      <Link to={url}>
        <div
          className={classNames(
            'flex flex-row items-center space-x-4 text-lena-blue-dark',
            'hover:bg-lena2021-gray-lightest active:bg-lena2021-gray-light rounded px-4 py-2',
          )}
        >
          {icon && <img className="object-fit" src={icon} alt="" height={16} width={16} />}
          <span>{label}</span>
        </div>
      </Link>
    );
  };

  return (
    <Popover className="relative">
      <Popover.Button className={`flex items-center ${hasLabel ? 'gap-2' : ''}`} aria-label="Mon compte">
        <span aria-hidden="true">{hasLabel && 'MON COMPTE'}</span>
        <UserProfileIcon />
      </Popover.Button>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform opacity-0"
      >
        <Popover.Panel className="absolute right-0 z-10 bg-white rounded-md w-max filter drop-shadow-md mt-2">
          <div className="divide-y divide-lena-lightgray2">
            <div className="divide-y divide-lena-lightgray2">
              {links.map((v) => {
                if (v.length <= 0) return <></>;
                return (
                  <div key={`key${v.length}`} className="py-2">
                    {v.map((w) => (
                      <div key={w.label} className="px-2">
                        {link(w.icon, w.label, w.link)}
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
