import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import classNames from 'common/utils/classNames';
import { useAppFlavor } from 'hooks';
import { AppFlavor } from 'contexts/AppFlavorContext';
import AppHeaderDesktop from './AppHeader.desktop';
import useMediaQuery from '../../hooks/useMediaQuery';
import AppHeaderMobile from './AppHeader.mobile';
import { Outlet } from 'react-router-dom';

const AppLayout: FunctionComponent<
  PropsWithChildren<{
    showHeader?: boolean;
    showFooter?: boolean;
    className?: string;
    title?: React.ReactNode;
    withOnlyBack?: boolean;
  }>
> = ({ showHeader = true, className, title, withOnlyBack, children }) => {
  const { flavor } = useAppFlavor();
  const isDesktop = useMediaQuery('md');
  const isXL = useMediaQuery('xl');
  const [extraViewportPX, setExtraViewportPX] = useState<number>(0);

  const showDesktopHeader = flavor === AppFlavor.Olympics ? isXL : isDesktop;

  useEffect(() => {
    setExtraViewportPX(document.body.clientHeight - window.innerHeight);
  }, [document.body]);

  return (
    <div
      style={{
        height: extraViewportPX > 0 ? `calc(100vh - ${extraViewportPX}px)` : '100vh',
        overflow: 'hidden',
      }}
      className="flex flex-col"
    >
      {showHeader &&
        (showDesktopHeader ? <AppHeaderDesktop /> : <AppHeaderMobile withOnlyBack={withOnlyBack} title={title} />)}
      <main className={classNames('flex flex-col flex-1 relative overflow-auto', className)}>
        {children || <Outlet />}
      </main>
    </div>
  );
};

export default AppLayout;
