import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ProfileIcon } from 'assets/svg/navigation/profile_colored.svg';
import TopJobSvg from 'assets/svg/navigation/top_job.svg';
import StarSvg from 'assets/svg/navigation/star_m.svg';
import HeartSvg from 'assets/svg/navigation/heart_m.svg';
import CVSvg from 'assets/svg/navigation/cv_m.svg';
import SettingsSvg from 'assets/svg/navigation/settings_m.svg';
import LogoutSvg from 'assets/svg/navigation/logout.svg';
import UserSvg from '../../assets/svg/navigation/my_exps.svg';

const ProfileSidebar: FunctionComponent = () => {
  const link = (icon: string, text: string, url: string) => {
    return (
      <Link className="text-lena-blue-dark flex items-center space-x-4" to={url}>
        {icon && <img src={icon} height={16} width={16} alt="" />}
        <span>{text}</span>
      </Link>
    );
  };

  return (
    <div className="w-64 bg-lena-lightgray flex flex-col top-0 left-0 relative scroll-thin z-10">
      <div className="flex flex-col justify-between flex-grow">
        <div className="flex flex-col">
          <div className="flex flex-col items-center justify-center space-y-2 p-8 border-b border-lena-lightgray2">
            <ProfileIcon className="w-16 h-16" />
            <div className="text-center text-lena-blue-dark font-bold md:text-md xl:text-xl">Mon compte</div>
          </div>
          <div className="flex flex-col divide-y divide-lena-lightgray2">
            <div className="flex flex-col p-6 space-y-4">
              {link(TopJobSvg, 'Métiers recommandés', '/metiers/metiers-recommandes')}
              {link(StarSvg, 'Métiers favoris', '/metiers/metiers-favoris')}
            </div>
            <div className="flex flex-col p-6 space-y-4">
              {link(UserSvg, 'Mes expériences', '/profil/mes-experiences')}
              {link(HeartSvg, "Mes centres d'intérêt", '/profil/mes-centres-d-interet')}
              {link(CVSvg, 'Mes CV', '/mes-cv')}
            </div>
            <div className="flex flex-col p-6 space-y-4">
              {link(SettingsSvg, 'Mes informations', '/profil/reglages')}
            </div>
            <div className="flex flex-col p-6 space-y-4">{link(LogoutSvg, 'Déconnexion', '/deconnexion')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSidebar;
