import { useState } from 'react';
import { User } from 'common/requests/types';
import { useDidMount } from 'common/hooks/useLifeCycle';
import startup from 'common/utils/startup';

const useRoot = () => {
  const [startupEnd, setStartupEnd] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  useDidMount(() => {
    startup().then((data) => {
      if (data) {
        setUser(data.user);
      }
      setStartupEnd(true);
    });
  });

  return {
    startupEnd,
    user,
    setUser,
  };
};

export default useRoot;
