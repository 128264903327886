import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo } from 'react';
import UserContext from 'common/contexts/UserContext';
import { tutorialStatusQuery, useTutorialStatus, useUpdateUserTutorialStatus } from 'common/requests/user';

export const enum TUTORIAL_KEYS {
  // Server flags / Account progression
  SERVER_FLAG_COMPLETED_EXPERIENCE = 'COMPLETED_EXPERIENCE',
  SERVER_FLAG_COMPLETED_INTERESTS = 'COMPLETED_INTERESTS',

  // Tutorial flags / Used for front-end
  RECOMMENDATION_INTRO = 'tuto_recommendation',
  INTERESTS_WALL_INTRO = 'tuto_interests',
  DAHSBOARD_TOP_JOBS = 'tuto_home_interets',
  DASHBOARD_JO2024_INTRO = 'tuto_home_experience',
  RECTEC_SELFEVAL_INTRO = 'tuto_selfeval_intro',
}

export const TutorialSystemContext = React.createContext<{
  hasTutorialFlag: (flag: TUTORIAL_KEYS) => boolean;
  setTutorialFlag: (flag: TUTORIAL_KEYS) => void;
  tutorialsFlags: string[];
}>({ hasTutorialFlag: () => false, setTutorialFlag: () => {}, tutorialsFlags: [] });

const TutorialSystemProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user } = useContext(UserContext);
  const [callTutorialStatus, tutorialStatusState] = useTutorialStatus({
    nextFetchPolicy: 'cache-only',
    initialFetchPolicy: 'network-only',
  });
  const [callUpdateTutorialStatus] = useUpdateUserTutorialStatus();

  const mergedFlags = useMemo(() => {
    const serverData = tutorialStatusState.data?.me.tutorialStatus;
    return serverData ? [...serverData.flags, ...serverData.keys] : [];
  }, [tutorialStatusState.data]);

  const hasTutorialFlag = useCallback(
    (flag: TUTORIAL_KEYS) => {
      return mergedFlags.includes(flag);
    },
    [mergedFlags],
  );

  const setTutorialFlag = useCallback(
    (flag: TUTORIAL_KEYS) => {
      callUpdateTutorialStatus({
        variables: { value: flag },
        refetchQueries: [{ query: tutorialStatusQuery, fetchPolicy: 'network-only' }],
      });
    },
    [callUpdateTutorialStatus],
  );

  useEffect(() => {
    if (user) callTutorialStatus();
  }, [user]);

  if (user && tutorialStatusState.loading) return null;

  return (
    <TutorialSystemContext.Provider
      value={{
        hasTutorialFlag,
        tutorialsFlags: mergedFlags,
        setTutorialFlag,
      }}
    >
      {children}
    </TutorialSystemContext.Provider>
  );
};

export default TutorialSystemProvider;
