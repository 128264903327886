import React, { FunctionComponent, PropsWithChildren } from 'react';
import classNames from 'common/utils/classNames';

const SIZES = { default: [354, 500], sm: [220, 331] };
type Props = { size?: keyof typeof SIZES; extraClasses?: string };

const CvThumbnail: FunctionComponent<PropsWithChildren<Props>> = ({ children, extraClasses, size = 'default' }) => {
  return (
    <div
      className={classNames('w-full bg-white border-2 border-transparent relative select-none', extraClasses)}
      style={{ width: SIZES[size][0], height: SIZES[size][1] }}
    >
      {children}
    </div>
  );
};

export default CvThumbnail;
