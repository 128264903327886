import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export default function useUrlQuery() {
  const { search } = useLocation();

  return useMemo(() => {
    return Object.freeze(new URLSearchParams(search));
  }, [search]);
}
