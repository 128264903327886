import { Button } from 'components/design-system';
import React, { FunctionComponent } from 'react';

interface Props {
  onAccept: () => void;
  onBack: () => void;
}

const ModalSelfevalIntro: FunctionComponent<Props> = ({ onAccept, onBack }) => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center space-y-8 p-4">
      <div className="font-bold text-xl text-center">Evaluer mes compétences transversales</div>

      <div className="text-center">
        <p>Evaluer ses compétences transversales nécessite d'avoir un peu de temps devant soi.</p>

        <br />
        <p>
          Idéalement, cet exercice se fait de manière accompagnée, par votre conseiller Pôle emploi, votre conseiller en
          Mission Locale, etc...
          <br />
        </p>
        <br />

        <p>
          Dans tous les cas, installez-vous confortablement, dans un endroit calme, et si possible depuis un ordinateur.
        </p>
      </div>
      <div className="flex gap-4 mt-3">
        <Button variant="ghost" onClick={() => onBack?.()}>
          Pas maintenant
        </Button>

        <Button variant="positive" onClick={() => onAccept?.()}>
          C'est parti
        </Button>
      </div>
    </div>
  );
};

export default ModalSelfevalIntro;
