import React, { useEffect, useMemo, useState } from 'react';

import { Button, Checkbox, TextField } from 'components/design-system';

const ReactionsCheckbox = ({ questionId, suggestions, handleClick }: any) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [customUserInput, setCustomUserInput] = useState<string>();

  useEffect(() => setSelected([]), [questionId]);

  const transformSelectedAnswers = useMemo(() => {
    return selected.includes('__userinput__')
      ? [...selected.filter((a) => a !== '__userinput__'), customUserInput]
      : [...selected];
  }, [selected, customUserInput]);

  return (
    <div>
      <div className="flex flex-col gap-2">
        {suggestions?.map((choiceLabel: string, i: number) => {
          const isCustomUserInputChoice = choiceLabel === '__userinput__';
          const id = i.toString();

          return (
            <div key={choiceLabel}>
              <div className="flex items-center cursor-pointer">
                <Checkbox
                  checked={selected.includes(choiceLabel)}
                  id={id}
                  name="reaction"
                  onClick={() =>
                    setSelected(
                      selected.includes(choiceLabel)
                        ? selected.filter((item) => item !== choiceLabel)
                        : [...selected, choiceLabel],
                    )
                  }
                />
                <label className="cursor-pointer ml-2" htmlFor={id}>
                  {isCustomUserInputChoice ? 'Autre' : choiceLabel}
                </label>
              </div>
              {isCustomUserInputChoice && selected.includes(choiceLabel) && (
                <div className="pt-2">
                  <TextField
                    id="structure"
                    name="structure"
                    type="text"
                    label=""
                    value={customUserInput}
                    onChange={(e) => {
                      setCustomUserInput(e.currentTarget.value);
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className="flex mt-8 mb-2">
        <Button
          variant="secondary"
          fullWidth
          disabled={selected.length <= 0 || (selected.includes('__userinput__') && !customUserInput)}
          onClick={() => {
            handleClick({ answer: transformSelectedAnswers });
            setSelected([]);
          }}
        >
          Suivant
        </Button>
      </div>
    </div>
  );
};

export default ReactionsCheckbox;
