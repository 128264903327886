import { gql, MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { CvNew, CvTemplate, PaginatedResult } from 'common/requests/types';
import { useLocalMutation, useLocalQuery } from 'common/hooks/apollo';
import { ALL_CV_DATA } from 'common/requests/__fragments';

export const getCvTemplates = gql`
  query ListCvTemplates {
    cvTemplates {
      data {
        id
        title
        templateKey
        image
        variants {
          name
          key
        }
        new
      }
    }
  }
`;

type CvTemplateListPayload = { cvTemplates: PaginatedResult<CvTemplate> };

export const useListCvTemplates = (options: QueryHookOptions<CvTemplateListPayload> = {}) =>
  useLocalQuery<CvTemplateListPayload>(getCvTemplates, options);

export const deleteCvNewMutation = gql`
  mutation DeleteCV($id: ObjectID!) {
    removeCvNew(id: $id)
  }
`;

export const useDeleteCvNew = (
  options?: MutationHookOptions<{ removeCvNew: string }, { id?: string; ids?: string[] }>,
) => useLocalMutation(deleteCvNewMutation, options);

export const updateCvNewMutation = gql`
  ${ALL_CV_DATA}
  mutation UpdateCV($id: ObjectID!, $input: UpdateCvInput) {
    updateCvNew(id: $id, input: $input) {
      ...CvNewAllData
    }
  }
`;

export const useUpdateCvNew = (options?: MutationHookOptions<CvNew, { id: string; input: any }>) =>
  useLocalMutation(updateCvNewMutation, options);

export const duplicateCvNewMutation = gql`
  mutation DuplicateCV($id: String!) {
    duplicateCvNew(parentId: $id) {
      id
    }
  }
`;

export const useDuplicateCvNew = (options?: MutationHookOptions<{ duplicateCvNew: { id: string } }, { id: string }>) =>
  useLocalMutation(duplicateCvNewMutation, options);
