const DEFAULT_COLORS = [
  { label: 'Par défaut', code: '#1E2E58' },
  { label: 'Rouge', code: '#E32C2C' },
  { label: 'Orange', code: '#FF705D' },
  { label: 'Jaune', code: '#F1E154' },
  { label: 'Vert', code: '#25CB68' },
  { label: 'Bleu', code: '#5ABFE4' },
  { label: 'Violet', code: '#7E5AE4' },
  { label: 'Noir', code: '#121C38' },
];

export default DEFAULT_COLORS;
