import React, { useEffect, useRef, useMemo } from 'react';
import { toast } from 'react-toastify';
import posthog from 'posthog-js';

import { Button } from 'components/design-system';
import ReactionsEmojiWithIntensity from 'components/FeedbackQuestions/components/ReactionsEmojiWithIntensity';

import useBoolean from 'common/hooks/useBoolean';
import useOnClickOutside from 'common/hooks/useOnClickOutside';

import { ReactComponent as CrossSvg } from 'assets/svg/generic/cross.svg';

import ReactionsCheckbox from './components/ReactionsCheckbox';
import ReactionsRadio from './components/ReactionsRadio';
import { useFeedbackQuestions } from 'hooks';

type FeedBackQuestionsProps = {
  children: React.ReactNode;
  group: string;
  pollInterval?: number;
  // We fetch 6 questions, users have to answer at most 3, and can skip 3
  maxQuestions?: number;
  numQuestionsToFetch?: number;
};
const FeedbackQuestions = ({
  children,
  group,
  pollInterval,
  maxQuestions = 3,
  numQuestionsToFetch = 6,
}: FeedBackQuestionsProps) => {
  const showPopover = useBoolean(false);
  const showButton = useBoolean(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(wrapperRef, showPopover.off);

  const { responses, data, currentQuestion, handleSubmit } = useFeedbackQuestions({
    group: group,
    ...(pollInterval && {
      pollInterval: pollInterval,
    }),
    maxQuestions: maxQuestions,
    numQuestionsToFetch: numQuestionsToFetch,
    handleSuccess: () => {
      showPopover.off();
      showButton.off();

      return toast.success('Merci pour vos réponses !');
    },
  });

  const availableQuestionsCount = useMemo(() => {
    return Math.min(maxQuestions, data?.userFeedbackQuestions?.length || 1);
  }, [maxQuestions, data?.userFeedbackQuestions]);

  useEffect(() => {
    if (data?.userFeedbackQuestions && data.userFeedbackQuestions.length > 0) showButton.on();
    else showButton.off();
  }, [data]);

  const questionComponent = () => {
    if (!data || !data.userFeedbackQuestions) return null;

    const typeResponse = currentQuestion?.reactionGroup.type;

    const renderResponseComponent = (type: string | undefined) => {
      switch (type) {
        case 'emoji_with_intensity':
          return (
            <ReactionsEmojiWithIntensity
              questionId={currentQuestion?.id}
              suggestions={currentQuestion?.reactionGroup.reactions}
              handleClick={(value: any) => handleSubmit(value)}
            />
          );
        case 'checkbox':
          return (
            <ReactionsCheckbox
              questionId={currentQuestion?.id}
              suggestions={currentQuestion?.reactionGroup.reactions}
              handleClick={(value: any) => handleSubmit(value)}
            />
          );
        case 'radio':
          return (
            <ReactionsRadio
              questionId={currentQuestion?.id}
              suggestions={currentQuestion?.reactionGroup.reactions}
              handleClick={(value: any) => handleSubmit(value)}
            />
          );
        default:
          return <p>Composant manquant</p>;
      }
    };

    return (
      <div>
        <p className="font-bold mb-4">{currentQuestion?.text}</p>

        <div className="mt-4">{renderResponseComponent(typeResponse)}</div>

        <div className="flex w-full justify-end">
          <Button variant="ghost" size="sm" padding="sm" onClick={() => handleSubmit(null)}>
            Passer
          </Button>
        </div>
      </div>
    );
  };

  const handleTogglePopup = () => {
    posthog.capture(showPopover.value ? 'user_feedback.close_popup' : 'user_feedback.open_popup');
    showPopover.toggle();
  };

  if (!showButton.value) return null;

  return (
    <div ref={wrapperRef}>
      {showButton.value && (
        <button type="button" className="relative flex" onClick={handleTogglePopup} aria-label="Questions">
          <span aria-hidden="true">
            <div className={`${showPopover.value ? 'hidden' : 'show'}`}>
              <div className="pulse" />
              <div className="marker" />
            </div>

            {children}
          </span>
        </button>
      )}

      {showPopover.value && (
        <div
          ref={popoverRef}
          className="absolute bg-white rounded-md shadow-lg top-16 right-2 w-[28rem] divide-solid divide-y divide-gray-200"
        >
          <div className="flex p-4 items-center justify-between">
            <h2>QUESTIONS</h2>

            <button type="button" onClick={showPopover.toggle}>
              <CrossSvg height={24} width={24} />
            </button>
          </div>

          <div>
            <div className="p-4">{questionComponent()}</div>
            <div
              style={{
                background: '#4DC5AF',
                width: `${String((responses.length / availableQuestionsCount) * 100)}%`,
                height: 4,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: responses.length === availableQuestionsCount ? 5 : 0,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedbackQuestions;
