import React, { ReactNode } from 'react';

export const HELPERS: any = {
  default: {
    text: 'Un message !',
    color: 'text-lena-gray',
  },
  warning: {
    text: 'Attention !',
    color: 'text-amber-500',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  success: {
    text: 'Bravo !',
    color: 'text-green-500',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  error: {
    text: 'Erreur !',
    color: 'text-lena2021-corail-dark',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  info: {
    text: 'Information !',
    color: 'text-blue-500',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
};

export type HelperTextConfig = {
  type: 'default' | 'success' | 'warning' | 'error' | 'info';
  withIcon?: boolean;
  icon?: ReactNode;
  text: any;
};

export type HelperTextProps = {
  id: string;
  helperText?: string | HelperTextConfig;
};

const HelperText = ({ id, helperText }: HelperTextProps) => {
  const isObject = typeof helperText === 'object';

  const color = HELPERS[isObject ? helperText.type : 'default'].color;

  return (
    <div id={`helperText.${id}`} className="flex mt-1">
      <div className={`flex items-center mr-1 fill-current ${color}`}>
        {HELPERS[isObject ? helperText?.type : 'success'].icon}
      </div>

      <div className={`text-md ${color}`}>{isObject ? helperText?.text : helperText}</div>
    </div>
  );
};

export default HelperText;
