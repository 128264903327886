import LogoXpPro from '../assets/svg/modules/parcours/logo_xp_pro.svg';
import LogoXpPro1 from '../assets/svg/modules/parcours/logo_xp_pro1.svg';
import LogoXpPerso from '../assets/svg/modules/parcours/logo_xp_perso.svg';
import LogoXpPerso1 from '../assets/svg/modules/parcours/logo_xp_perso1.svg';
import LogoXpBenevolat from '../assets/svg/modules/parcours/logo_xp_benevolat.svg';
import LogoXpCivic from '../assets/svg/modules/parcours/logo_xp_civic.svg';
import LogoXpCivic1 from '../assets/svg/modules/parcours/logo_xp_civic1.svg';
import LogoXpSportPro from '../assets/svg/modules/parcours/logo_xp_sport_pro.svg';
import LogoXpSport from '../assets/svg/modules/parcours/logo_xp_sport.svg';
import LogoXpSport1 from '../assets/svg/modules/parcours/logo_xp_sport1.svg';
import LogoXpAccompagne from '../assets/svg/modules/parcours/logo_xp_accompagne.svg';
import LogoXpEngagementMilitant from '../assets/svg/modules/parcours/logo_xp_engagement_militant.svg';
import LogoEntrepreneuriat from '../assets/svg/modules/parcours/logo_entreprenariat.svg';

export type ParcoursExpConfigBase = {
  key?: string;
  icons?: {
    vector: string;
    vectorLight?: string;
  };
  labels: {
    singular: string;
    plural?: string;
    Preposition?: string;
  };
  description: string;
  order: {
    parcours?: number;
    profile: number;
  };
  settings: {
    parcoursCreationUrl?: string;
    showInParcours: boolean;
    onlyOnePerAccount: boolean;
  };
  style?: {
    backgroundColor?: string;
    textColor?: string;
  };
};

export type ParcoursExpConfig = ParcoursExpConfigBase & { overrides?: Partial<ParcoursExpConfigBase> };

export const staticThemeConfigs: Record<string, ParcoursExpConfig> = {
  professional: {
    icons: {
      vector: LogoXpPro,
      vectorLight: LogoXpPro1,
    },
    labels: {
      singular: 'Professionnelle',
      plural: 'Professionnelles',
    },
    description:
      'Vous avez réalisé une mission, un stage, une immersion professionnelle... En intérim, en CDI, dans une start up, un grand groupe, un petit commerce...',
    order: {
      profile: 1,
    },
    settings: {
      parcoursCreationUrl: '/experience/theme-pro',
      showInParcours: true,
      onlyOnePerAccount: false,
    },
    style: {
      backgroundColor: 'bg-parcours-pro',
    },
  },
  personal: {
    icons: {
      vector: LogoXpPerso,
      vectorLight: LogoXpPerso1,
    },
    labels: {
      singular: 'Personnelle',
      plural: 'Personnelles',
    },
    description:
      "Vous gardez des enfants ou des animaux, faites du jardinage, jouez d'un instrument, faites du bricolage, de la couture, de la photographie...",
    order: {
      profile: 2,
    },
    settings: { showInParcours: true, onlyOnePerAccount: false },
    style: {
      backgroundColor: 'bg-parcours-perso',
    },
  },
  voluntary: {
    icons: {
      vector: LogoXpBenevolat,
    },
    labels: {
      singular: 'Bénévolat & volontariat',
      Preposition: 'de bénévolat et volontariat',
    },
    description:
      'Vous aidez une association sur votre temps libre, vous vous impliquez dans la vie de votre quartier, vous aidez au dépouillement électoral... ',
    order: {
      profile: 3,
    },
    settings: { showInParcours: true, onlyOnePerAccount: false },
    style: {
      backgroundColor: 'bg-parcours-voluntary',
    },
  },
  engagement: {
    icons: {
      vector: LogoXpCivic,
      vectorLight: LogoXpCivic1,
    },
    labels: {
      singular: 'Service Civique',
      Preposition: 'de Service Civique',
    },
    description:
      "Vous avez réalisé une mission en Service Civique au sein d'une association, d'une entreprise, d'une collectivité...",
    order: {
      profile: 6,
    },
    settings: { showInParcours: true, onlyOnePerAccount: true },
    style: {
      backgroundColor: 'bg-parcours-civic',
    },
  },
  sport_pro: {
    icons: {
      vector: LogoXpSportPro,
    },
    labels: {
      singular: 'Sport de haut niveau',
      Preposition: 'de sport de haut niveau',
    },
    description:
      'Vous pratiquez une activité physique de haut niveau, gérez votre préparation physique et mentale avant une compétition, analysez vos performances, gérez vos contrats de sponsors...',
    order: {
      profile: 4,
    },
    settings: { showInParcours: true, onlyOnePerAccount: false },
    style: {
      backgroundColor: 'bg-parcours-shn',
    },
  },
  sport_amateur: {
    icons: {
      vector: LogoXpSport,
      vectorLight: LogoXpSport1,
    },
    labels: {
      singular: 'Sport amateur',
      Preposition: 'de sport amateur',
    },
    description:
      'Vous pratiquez une activité physique pour le loisir, aidez à la gestion de votre association sportive, participez à la vie de votre club, arbitrez des matchs amateurs...',
    order: {
      profile: 5,
    },
    settings: { showInParcours: true, onlyOnePerAccount: false },
    style: {
      backgroundColor: 'bg-parcours-sport',
    },
  },
  entrepreneurship: {
    icons: {
      vector: LogoEntrepreneuriat,
      vectorLight: LogoXpSport1,
    },
    labels: {
      singular: 'Entrepreneuriat',
      Preposition: "d'entrepreneuriat",
    },
    description:
      'Vous avez une expérience d’auto-entrepreneur, de mini-entreprise, vous avez créé une association ou êtes intrapreneur...',
    order: {
      profile: 5,
    },
    settings: { showInParcours: true, onlyOnePerAccount: false },
    style: {
      backgroundColor: 'bg-lena-corail-dark',
    },
  },
  militant: {
    icons: {
      vector: LogoXpEngagementMilitant,
      vectorLight: LogoXpSport1,
    },
    labels: {
      singular: 'Engagement militant / Citoyenneté',
      Preposition: "d'engagement militant / de citoyenneté",
    },
    description:
      'Vous avez une expérience d’élu-e local, de vie syndicale, parti politique ou d’engagement dans un conseil municipal jeunes',
    order: {
      profile: 5,
    },
    settings: { showInParcours: true, onlyOnePerAccount: false },
    style: {
      backgroundColor: 'bg-lena-pink',
    },
  },
  insertion: {
    icons: {
      vector: LogoXpAccompagne,
      vectorLight: LogoXpAccompagne,
    },
    labels: {
      singular: 'Parcours accompagné',
      Preposition: 'en parcours accompagné',
    },
    description:
      'Dans le cadre du CEJ, de votre scolarité ou en formation vous avez participé à des stages ou des immersions en entreprise, des projets de mobilité internationale, vous avez été délégué-e de groupe...',
    order: {
      profile: 5,
    },
    settings: { showInParcours: true, onlyOnePerAccount: false },
    style: {
      backgroundColor: 'bg-lena-lightgray2',
    },
  },
};

export const getParcoursConfig = (experienceType?: string): ParcoursExpConfig | null => {
  if (!experienceType) return null;

  if (!Object.keys(staticThemeConfigs).includes(experienceType))
    throw new Error(`Unknown experience type: ${experienceType}`);
  return staticThemeConfigs[experienceType];
};
