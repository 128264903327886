import React, { useContext } from 'react';
import classNames from 'common/utils/classNames';
import AvatarDefaultSvg from 'assets/svg/modules/cv/avatar-default.svg';
import { SkillCardV4TemplateConfig, TemplateEditorProps } from 'containers/SkillCardV4Container/types/template';
import ContentBlockSmartV4 from 'containers/SkillCardV4Container/templates/components/ContentBlockGenericV4/ContentBlockSmartV4';
import CvRenderContext from 'containers/SkillCardV4Container/contexts/CvRenderContext';

type Props = {
  data: any;
  editorData?: Partial<TemplateEditorProps>;
  config?: Partial<SkillCardV4TemplateConfig>;
  customizationOptions: any;
};

const SideBar: React.FC<Props> = ({ data = {}, editorData = {}, customizationOptions }) => {
  const renderCtx = useContext(CvRenderContext);
  const { photo, firstName, lastName } = data;
  const { setCurrentStepByKey, currentKeyStep, showAssistant } = editorData;

  return (
    <>
      {(!renderCtx.config.print || photo) && (
        <div
          onClick={(e) => {
            setCurrentStepByKey?.('picture');
            e.stopPropagation();
          }}
        >
          <img
            src={photo || AvatarDefaultSvg}
            alt="Avatar CV"
            className={classNames(
              'rounded-full',
              'border-2 border-dashed',
              showAssistant && 'hover:border-lena2021-corail-dark hover:cursor-pointer',
              showAssistant && currentKeyStep === 'picture' ? 'border-lena2021-corail-dark' : 'border-transparent',
            )}
            style={{
              background: `${customizationOptions?.accentColor}32`,
              width: '100%',
              objectFit: 'cover',
              aspectRatio: '1 / 1',
            }}
          />
        </div>
      )}

      <div className="space-y-4">
        <h2 className="text-2xl font-semibold mt-8">
          {firstName} {lastName}
        </h2>

        <ContentBlockSmartV4 sourceCvData={data} contentKey={'job'} />
        <ContentBlockSmartV4 sourceCvData={data} contentKey={'description'} />
        <ContentBlockSmartV4 sourceCvData={data} contentKey={'contact'} />
        <ContentBlockSmartV4 sourceCvData={data} contentKey={'formations'} />
        <ContentBlockSmartV4 sourceCvData={data} contentKey={'extras'} />
        <ContentBlockSmartV4 sourceCvData={{ ...data, customizationOptions }} contentKey={'keywords'} />
      </div>
    </>
  );
};

export default SideBar;
