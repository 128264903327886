import { TemplateFont } from 'containers/SkillCardV4Container/types/template';

type Font = {
  key: TemplateFont;
  title: string;
  description: string;
  family: string;
};

const DEFAULT_FONTS: Font[] = [
  {
    key: 'atkinson',
    title: 'Atkinson',
    description: 'Facile à lire.',
    family: 'Atkinson Hyperlegible, sans-serif',
  },
  {
    key: 'montserrat',
    title: 'Montserrat',
    description: 'Idéal pour du texte court.',
    family: 'Montserrat, sans-serif',
  },
  {
    key: 'bitter',
    title: 'Bitter',
    description: 'Idéal pour du texte long.',
    family: 'Bitter, serif',
  },
];

export default DEFAULT_FONTS;
