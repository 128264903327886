import React, { Dispatch, useMemo } from 'react';
import EvaluationCompetences from '../components/EvaluationCompetences';
import { Button } from 'components/design-system';
import { Competence } from 'common/requests/types';

type Props = {
  competenceJson: Competence[];
  criteresRatings: Record<string, number>;
  selectedCompetencesIds: string[];
  competenceRatings: Record<string, number>;
  setCompetenceRatings: Dispatch<React.SetStateAction<Record<string, number>>>;
  onSubmit: () => void;
};

const EvalCompetences: React.FC<Props> = ({
  competenceJson,
  criteresRatings,
  selectedCompetencesIds,
  competenceRatings,
  setCompetenceRatings,
  onSubmit,
}) => {
  const handleSetCompetenceLevel = (competenceId: string, level: number) => {
    setCompetenceRatings((current) => ({ ...current, [competenceId]: level }));
  };

  const canSubmit = useMemo(() => {
    return Object.keys(competenceRatings).every((cid) => selectedCompetencesIds.includes(cid));
  }, [competenceRatings]);
  const competencesToEval = competenceJson.filter((v) => selectedCompetencesIds.includes(v.id));

  return (
    <div className="flex flex-col items-center space-y-24 py-12">
      <div className="container">
        <EvaluationCompetences
          competenceJson={competenceJson}
          competencesToEval={competencesToEval}
          competenceRatings={competenceRatings}
          onUpdateCompetenceRating={handleSetCompetenceLevel}
          criteresRatings={criteresRatings}
        />
      </div>

      <Button variant="secondary" size="lg" onClick={onSubmit} disabled={!canSubmit}>
        Suivant
      </Button>
    </div>
  );
};

export default EvalCompetences;
