import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SelectCompetences from './SelectCompetences';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import AppLoader from 'components/ui/AppLoader';
import { useToastContext } from 'hooks';

// Steps
import EvalCompetences from './EvalCompetences';
import EvalCriteres from './EvalCriteres';
import DynamicHeader from '../components/DynamicHeader';

const SelfevalAllInOne = () => {
  const { skillId } = useParams();
  const [step, setStep] = useState(0);

  const navigate = useNavigate();

  const [criteresRatings, setCriteresRatings] = useState<Record<string, number>>({});
  const [competenceRatings, setCompetenceRatings] = useState<Record<string, number>>({});
  const [selectedCompetences, setSelectedCompetences] = useState<string[]>([]);
  const { sendToast } = useToastContext();

  const [callCompetencesAndCriteres, competencesAndCriteresState] = useLazyQuery(gql`
    query ListCompetencesAndCriteresForSelfeval($competenceReferenceId: ObjectID) {
      competences(reference: $competenceReferenceId, sort: "order") {
        data {
          id
          title
          type
          levels {
            title
            subTitle
            level
          }
        }
      }
      criteres(sort: "order") {
        data {
          id
          title
          subtitle
          levels {
            level
            content
          }
        }
      }
    }
  `);

  const [callMutation] = useMutation(
    gql`
      mutation UpdateSelfeval($skillId: ObjectID!, $input: SkillSelfevalInput!) {
        updateSkillSelfeval(skillId: $skillId, inputData: $input) {
          id
          theme {
            id
          }
          recommendations {
            id
          }
          selfeval {
            criteres {
              critere {
                id
              }
              level
            }
            competences {
              competence {
                id
              }
              level
            }
            createdAt
          }
        }
      }
    `,
    {
      onCompleted: (response) => {
        sendToast('Votre carte de compétences transversales a été mise à jour !', 'success');

        const skillInfo = response.updateSkillSelfeval;
        if (!skillInfo || skillInfo.recommendations?.length > 0) navigate('/evaluer-mes-competences/');
        else navigate(`/experience/${skillInfo.theme?.id}/recommendation/${skillInfo.id}?parcours_scope=selfeval`);
      },
    },
  );

  const [callSkill, skillState] = useLazyQuery(gql`
    query GetSkillSelfevalData($skillId: ObjectID!) {
      skill(id: $skillId) {
        id
        domain
        intitule
        theme {
          id
          title
        }
        selfeval {
          createdAt
        }
        structure
        startDate
        endDate
        selfeval {
          competences {
            competence {
              id
            }
            level
          }
          criteres {
            critere {
              id
            }
            level
          }
          createdAt
        }
      }
    }
  `);

  useEffect(() => {
    callSkill({ variables: { skillId }, notifyOnNetworkStatusChange: true });
  }, [skillId]);

  useEffect(() => {
    if (skillState.data) {
      // HACK ! We need to use a fixed reference for selfeval
      // TODO: attach the reference id to the theme and use it there
      callCompetencesAndCriteres({ variables: { competenceReferenceId: '632499a56381ad34d5b6d320' } });
    }
  }, [skillState.data]);

  useEffect(() => {
    const selfEval = skillState.data?.skill?.selfeval;

    if (selfEval) {
      setSelectedCompetences(selfEval.competences.map((c: any) => c.competence.id));
      setCriteresRatings(Object.fromEntries(selfEval.criteres.map((c: any) => [c.critere.id, c.level])));
      setCompetenceRatings(Object.fromEntries(selfEval.competences.map((c: any) => [c.competence.id, c.level])));
    }
  }, [skillState.data]);

  const handleSubmitFinished = () => {
    callMutation({
      variables: {
        skillId,
        input: {
          criteres: Object.entries(criteresRatings).map(([critere, level]) => ({ critere, level })),
          competences: Object.entries(competenceRatings).map(([competence, level]) => ({ competence, level })),
        },
      },
    });
  };

  if (
    !skillState.called ||
    skillState.loading ||
    !competencesAndCriteresState.called ||
    competencesAndCriteresState.loading
  )
    return <AppLoader />;

  const getContent = () => {
    switch (step) {
      case 0:
        return (
          <EvalCriteres
            critereJson={competencesAndCriteresState.data?.criteres.data || []}
            criteriaRatings={criteresRatings}
            setCriteriaRatings={setCriteresRatings}
            onSubmit={() => setStep(1)}
          />
        );
      case 1:
        return (
          <SelectCompetences
            competenceJson={competencesAndCriteresState.data?.competences.data || []}
            selectedCompetences={selectedCompetences}
            setSelectedCompetences={setSelectedCompetences}
            onSubmit={() => setStep(2)}
          />
        );
      case 2:
        return (
          <EvalCompetences
            competenceJson={competencesAndCriteresState.data?.competences.data || []}
            competenceRatings={competenceRatings}
            setCompetenceRatings={setCompetenceRatings}
            selectedCompetencesIds={selectedCompetences}
            criteresRatings={criteresRatings}
            onSubmit={() => handleSubmitFinished()}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <DynamicHeader experience={skillState?.data?.skill} />

      {getContent()}
    </div>
  );
};

export default SelfevalAllInOne;
