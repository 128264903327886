import { gql, MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { useLocalMutation, useLocalLazyQuery } from 'common/hooks/apollo';
import { User, LocationUser } from './types';

export const updateProfilMutation = gql`
  mutation UpdateMyProfile(
    $email: String
    $currentPassword: String
    $password: String
    $firstName: String
    $lastName: String
    $location: LocationInput
    $code: String
    $jo2024ProfilePersona: String
    $gender: String
    $birthdate: Date
  ) {
    updateProfil(
      email: $email
      password: $password
      currentPassword: $currentPassword
      firstName: $firstName
      lastName: $lastName
      location: $location
      code: $code
      jo2024ProfilePersona: $jo2024ProfilePersona
      gender: $gender
      birthdate: $birthdate
    ) {
      id
      email
      firstName
      lastName
      location {
        lat
        lng
        address
      }
      gender
      birthdate
      jo2024ProfilePersona
      role
      isPasswordless
    }
  }
`;
export interface UpdateProfilArguments {
  email?: string;
  password?: string;
  currentPassword?: string;
  firstName?: string;
  lastName?: string;
  location?: LocationUser;
  structure?: string;
  code?: string;
  gender?: string;
  jo2024ProfilePersona?: string;
  birthdate?: string;
}

export const useUpdateProfil = (options: MutationHookOptions<{ updateProfil: User }, UpdateProfilArguments> = {}) =>
  useLocalMutation(updateProfilMutation, options);

export const tutorialStatusQuery = gql`
  query GetTutorialStatus {
    me {
      id
      tutorialStatus {
        flags
        keys
      }
    }
  }
`;

export type TutorialStatusResponse = {
  me: {
    tutorialStatus: {
      flags: string[];
      keys: string[];
    };
  };
};

export const useTutorialStatus = (options: QueryHookOptions<TutorialStatusResponse> = {}) =>
  useLocalLazyQuery(tutorialStatusQuery, options);

export const updateTutorialStatusMutation = gql`
  mutation UpdateTutorialStatus($value: String!) {
    updateTutorialStatus(value: $value) {
      id
      tutorialStatus {
        flags
        keys
      }
    }
  }
`;

export const useUpdateUserTutorialStatus = (options: MutationHookOptions<any, { value: string }> = {}) =>
  useLocalMutation(updateTutorialStatusMutation, options);

export const removeUserInterestMutation = gql`
  mutation RemoveUserInterest($id: ObjectID!, $idFamily: ObjectID!) {
    removeUserInterest(id: $id, idFamily: $idFamily) {
      interests {
        cursors {
          title
        }
        interest {
          title
        }
      }
    }
  }
`;

export const useRemoveUserInterest = (options: MutationHookOptions<any, { id: string; idFamily: string }> = {}) =>
  useLocalMutation(removeUserInterestMutation, options);

export const updateJobsSettingsAccessLevelMutation = gql`
  mutation UpdateMyJobsSettings($value: String!) {
    updateJobsSettingsAccessLevel(value: $value) {
      id
      jobsSettings {
        accessLevel
      }
    }
  }
`;

export const useUpdateUserJobsSettingsAccessLevel = (options: MutationHookOptions<any, { value: string }> = {}) =>
  useLocalMutation(updateJobsSettingsAccessLevelMutation, options);

export type UserJobsSettings = {
  me: {
    jobsSettings: {
      accessLevel: string;
    };
  };
};

export const getUserJobsSettings = gql`
  query MyUserJobsSettings {
    me {
      jobsSettings {
        accessLevel
      }
    }
  }
`;
export const useUserJobsSettings = (options: QueryHookOptions<UserJobsSettings> = {}) =>
  useLocalLazyQuery(getUserJobsSettings, options);

export const deleteAccountMutation = gql`
  mutation deleteUser($password: String!) {
    deleteUser(password: $password)
  }
`;

export const useDeleteMyAccount = (options: MutationHookOptions<any, { password?: string }> = {}) =>
  useLocalMutation(deleteAccountMutation, options);

export const publicUserProfileQuery = gql`
  query PublicUserProfile($token: String!) {
    publicProfile(token: $token) {
      id
      firstName
      lastName
      skills {
        id
        intitule
        domain
        theme {
          id
          image
          title
        }
        activities {
          id
          title
          competences {
            id
            title
          }
        }
        startDate
        endDate
        structure
        extraActivity
      }
      volunteers {
        id
        intitule
        theme {
          id
          image
          title
        }
        activities {
          id
          options {
            id
            title
          }
        }
        competences {
          id
          title
        }
        extraOption
        startDate
        endDate
        structure
      }
      interests {
        cursors {
          id
          title
        }
        interest {
          id
          title
        }
      }
      favorites {
        id
        tag {
          id
          title
        }
      }
      topJobs {
        id
        title
      }
    }
  }
`;

export const usePublicUserProfile = (options: QueryHookOptions<any> = {}) =>
  useLocalLazyQuery(publicUserProfileQuery, options);
