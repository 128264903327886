import React from 'react';
import { Routes, Route } from 'react-router-dom';
import createLazyComponent from 'utils/createLazyComponent';

const SelectionTheme = createLazyComponent(() => import('./CommonContainers/SelectionTheme'));
const Experience = createLazyComponent(() => import('./CommonContainers/Experience'));
const SelectionThemePro = createLazyComponent(() => import('./XPPro/SelectionThemePro'));

const ExperienceRoute = () => {
  return (
    <Routes>
      <Route path="/theme" element={<SelectionTheme />} />
      <Route path="/theme-pro" element={<SelectionThemePro />} />
      <Route path="/:id/*" element={<Experience />} />
    </Routes>
  );
};

export default ExperienceRoute;
