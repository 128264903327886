import { gql, MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { useLocalMutation, useLocalLazyQuery } from 'common/hooks/apollo';
import { PaginatedResult } from 'common/requests/types';
import { ALL_CV_DATA } from 'common/requests/__fragments';
import { CVBase } from '../types/dataCV';

export const createCvMutation = gql`
  mutation CreateCV($input: CreateCvInput!) {
    createCvNew(input: $input) {
      id
      title
      template {
        id
        title
        templateKey
      }
      customizationOptions {
        accentColor
        variant
        font
      }
    }
  }
`;

interface CreateCvVariables {
  input: {
    title: string;
    template: string;
    accentColor?: string;
    variant?: string;
    avatar?: File;
  };
}

interface CreateCvPayload {
  createCvNew: {
    id: string;
  };
}

export const useCreateCvNew = (options: MutationHookOptions<CreateCvPayload, CreateCvVariables> = {}) =>
  useLocalMutation<CreateCvPayload, CreateCvVariables>(createCvMutation, options);

export const updateCvMutation = gql`
  ${ALL_CV_DATA}
  mutation UpdateCV($id: ObjectID!, $input: UpdateCvInput) {
    updateCvNew(id: $id, input: $input) {
      ...CvNewAllData
    }
  }
`;

type UpdateCvVariables = {
  id: string;
  input: {
    cvName?: string;
    title?: string;
    theme?: any;
    info?: {
      firstName?: string;
      lastName?: string;
      email?: string;
      description?: string;
    };
    training?: string[];
    transversal?: CVBase[];
    technical?: CVBase[];
    personal?: CVBase[];
    professional?: CVBase[];
    voluntary?: CVBase[];
    sport_amateur?: CVBase[];
    sport_pro?: CVBase[];
    civic?: CVBase[];
  };
};

type UpdateCvPayload = {
  updateCvNew: {
    id: string;
    title?: string;
  };
};

export const useUpdateCvNew = (options: MutationHookOptions<UpdateCvPayload, UpdateCvVariables> = {}) =>
  useLocalMutation<UpdateCvPayload, UpdateCvVariables>(updateCvMutation, options);

export const getCvQuery = gql`
  ${ALL_CV_DATA}
  query GetCvNew($id: ObjectID, $publicToken: String) {
    getCvNew(id: $id, publicToken: $publicToken) {
      ...CvNewAllData
    }
  }
`;

interface GetCvResponse {
  getCvNew: {
    id: string;
    title: string;
    publicToken: string;
    avatar: string;
    contentOrder: string[];
    user: {
      firstName: string;
      lastName: string;
    };
    template: {
      id: string;
      templateKey: string;
    };
  };
}

export const useGetCv = (options: QueryHookOptions<GetCvResponse, { id?: string; publicToken?: string }> = {}) =>
  useLocalLazyQuery<GetCvResponse, { id?: string; publicToken?: string }>(getCvQuery, options);

export const getAllCvQuery = gql`
  ${ALL_CV_DATA}
  query GetMyCVs {
    getAllCvNew {
      count
      data {
        ...CvNewAllData
      }
    }
  }
`;

type GetAllCvResponse = {
  getAllCvNew: PaginatedResult<{
    id: string;
    title: string;
    publicToken: string;
    template: {
      id: string;
      templateKey: string;
    };
  }>;
};

export const useGetAllCv = (options: QueryHookOptions<GetAllCvResponse> = {}) =>
  useLocalLazyQuery<GetAllCvResponse>(getAllCvQuery, options);

export const downloadCvQuery = gql`
  mutation downloadCvNew($id: ObjectID!) {
    downloadCvNew(id: $id) {
      isInProgress
      error
      title
      base64
    }
  }
`;

type DownloadCvVariables = {
  id: string;
};

interface DwonloadCvResponse {
  downloadCvNew: {
    error?: string;
    isInProgress: boolean;
    title?: string;
    base64?: string;
  };
}

export const useDownloadCv = (options: MutationHookOptions<DwonloadCvResponse, DownloadCvVariables> = {}) =>
  useLocalMutation<DwonloadCvResponse, DownloadCvVariables>(downloadCvQuery, options);

export const uploadAvatarCvMutation = gql`
  mutation UploadAvatarForCV($avatar: Upload, $id: ObjectID!) {
    uploadAvatarCvNew(avatar: $avatar, cvId: $id)
  }
`;

interface UploadAvatarCvVariables {
  avatar: File;
  id: string;
}

interface UploadAvatarCvPayload {
  uploadAvatarCvNew: string;
}

export const useUploadAvatarCvNew = (
  options: MutationHookOptions<UploadAvatarCvPayload, UploadAvatarCvVariables> = {},
) => useLocalMutation<UploadAvatarCvPayload, UploadAvatarCvVariables>(uploadAvatarCvMutation, options);

export const removeAvatarCvMutation = gql`
  mutation RemoveAvatarForCV($id: ObjectID!) {
    removeAvatarCvNew(cvId: $id)
  }
`;

interface RemoveAvatarCvVariables {
  id: string;
}

interface RemoveAvatarCvPayload {
  removeAvatarCvNew: string;
}

export const useRemoveAvatarCvNew = (
  options: MutationHookOptions<RemoveAvatarCvPayload, RemoveAvatarCvVariables> = {},
) => useLocalMutation<RemoveAvatarCvPayload, RemoveAvatarCvVariables>(removeAvatarCvMutation, options);
