import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAppFlavor } from 'hooks';

const PublicLayout = React.lazy(() => import('layouts/PublicLayout/PublicLayout'));
const PublicLayout1J1S = React.lazy(() => import('layouts/PublicLayout/PublicLayout.1j1s'));

const Login = React.lazy(() => import('./containers/Login'));
const Register = React.lazy(() => import('./containers/Register'));
const ForgotPassword = React.lazy(() => import('containers/AuthContainer/containers/ForgotPassword'));
const PasswordReset = React.lazy(() => import('containers/AuthContainer/containers/PasswordReset'));
const PoleEmploiCallback = React.lazy(() => import('./containers/PoleEmploiCallback'));

const AuthRoute = () => {
  const { is1J1S } = useAppFlavor();

  const FlavoredLayoutComponent = is1J1S ? PublicLayout1J1S : PublicLayout;

  return (
    <Routes>
      <Route path="/*" element={<FlavoredLayoutComponent />}>
        <Route path="connexion" element={<Login />} />
        <Route path="inscription" element={<Register />} />
        <Route path="reinitialisation-mot-de-passe" element={<ForgotPassword />} />
        <Route path="reinitialisation-mot-de-passe/:token" element={<PasswordReset />} />
        <Route path="auth/pole-emploi/callback/" element={<PoleEmploiCallback />} />
      </Route>
    </Routes>
  );
};

export default AuthRoute;
