import { useCallback, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import appEnvironment from 'config/environment';
import posthog from 'posthog-js';

const useGdprConsent = () => {
  const [cookies, setCookie] = useCookies(['consent']);

  const status = useMemo(() => {
    return cookies.consent === undefined ? 'UNKNOWN' : cookies.consent === 'true';
  }, [cookies]);

  const cookieRootDomain = useMemo(() => {
    return appEnvironment.REACT_APP_GDPR_DOMAIN_BASE || '';
  }, []);

  const allow = useCallback(() => {
    setCookie('consent', true, { domain: cookieRootDomain });
    posthog?.opt_in_capturing();
  }, [setCookie, cookieRootDomain]);

  const deny = useCallback(() => {
    setCookie('consent', false, { domain: cookieRootDomain });
    posthog?.opt_out_capturing();
  }, [setCookie, cookieRootDomain]);

  return { allow, deny, status };
};

export default useGdprConsent;
