import React from 'react';
import { Button, Pill } from 'components/design-system';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { formatDateRange } from 'utils/formatDate';

type Props = {
  skillId: string;
  title: string;
  startDate?: string;
  endDate?: string;
  structure?: string;
  selfevalAt?: string;
};

const CardExperienceForSelfeval: React.FC<Props> = ({ skillId, startDate, endDate, title, structure, selfevalAt }) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white shadow p-4 rounded-md">
      <div className="flex flex-row items-center justify-between">
        <div>
          <h3 className="font-bold">{title}</h3>
          <div className="text-sm">
            {startDate && <p>Période: {formatDateRange(startDate, endDate)}</p>}
            {structure && <p>Structure: {structure}</p>}
            <div className="flex flex-wrap gap-1">
              {selfevalAt && (
                <Pill variant="orange-light" extraClassName="mt-1">
                  Evaluée le {moment(selfevalAt).format('DD/MM/YYYY')}
                </Pill>
              )}
            </div>
          </div>
        </div>
        <div className="flex-shrink-0">
          <Button
            variant={selfevalAt ? 'ghost' : 'secondary'}
            size="sm"
            padding="sm"
            onClick={() => navigate(`/evaluer-mes-competences/${skillId}`)}
          >
            {selfevalAt ? 'Refaire une évaluation' : 'Evaluer maintenant'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CardExperienceForSelfeval;
