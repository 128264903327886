import React, { FunctionComponent, PropsWithChildren } from 'react';
import classNames from 'common/utils/classNames';

export type SelectorTestProps = {
  checked?: boolean;
  onClick?: (value: boolean) => void;
  withCheckBox?: boolean;
  defaultColor?: string;
  checkedColor?: string;
};

const SelectorTest: FunctionComponent<PropsWithChildren<SelectorTestProps>> = ({
  checked = false,
  onClick,
  withCheckBox,
  children,
  defaultColor = 'bg-lena-lightgray',
  checkedColor = 'bg-lena-corail-lightest',
  ...rest
}) => {
  return (
    <div
      onClick={() => onClick?.call(null, !checked)}
      className={classNames(
        'group px-6 rounded-md flex items-center border-2 cursor-pointer w-full py-4',
        checked ? 'border-transparent' : 'border-transparent',
        checked ? checkedColor : defaultColor,
      )}
    >
      <div className="flex flex-row items-start space-x-4 w-full">
        {withCheckBox && (
          <input
            type="checkbox"
            readOnly
            checked={checked}
            className={classNames(
              'border border-lena-lightgray2 checked:border-lena-lightgray2',
              'rounded-md h-6 w-6 cursor-pointer focus:outline-none focus:ring-0 text-lena-blue-dark',
              !checked && 'group-hover:border-lena2021-corail-dark group-hover:border-2',
            )}
            {...rest}
          />
        )}
        <div className="w-full">{children}</div>
      </div>
    </div>
  );
};

export default SelectorTest;
