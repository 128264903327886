import { useEffect, useState } from 'react';

const DEFAULT_OPTIONS = {
  config: { attributes: true, childList: true, subtree: true },
};

/**
 * This custom hooks abstracts the usage of the Mutation Observer with React components.
 * Watch for changes being made to the DOM tree and trigger a custom callback.
 * @param {Element} targetEl DOM element to be observed
 * @param {Function} cb callback that will run when there's a change in targetEl or any
 * child element (depending on the provided options)
 * @param {Object} options
 * @param {Object} options.config
 * @param {number} [options.debounceTime=0] a number that represents the amount of time in ms
 * that you which to debounce the call to the provided callback function
 */
const useMutationObservable = (targetEl: any, cb: any, options = DEFAULT_OPTIONS) => {
  const [observer, setObserver] = useState<any>(null);

  useEffect(() => {
    const obs = new MutationObserver(cb);
    setObserver(obs);
  }, [cb, options]);

  useEffect(() => {
    if (!observer || !targetEl) return () => {};

    observer.observe(targetEl, options.config);

    return () => {
      if (observer) observer.disconnect();
    };
  }, [observer, targetEl, options]);
};

export default useMutationObservable;
