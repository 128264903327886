import React, { ButtonHTMLAttributes, FunctionComponent } from 'react';
import classNames from 'common/utils/classNames';
import { LoaderOval } from 'components/design-system';

export type ButtonProps = {
  centered?: boolean;
  size?: 'sm' | 'normal' | 'md' | 'lg';
  variant?:
    | 'primary'
    | 'secondary'
    | 'white'
    | 'white-shadow'
    | 'positive'
    | 'negative'
    | 'orange'
    | 'orange-light'
    | 'assistant'
    | 'ghost';
  padding?: 'sm' | 'normal';
  fullWidth?: boolean;
  uppercase?: boolean;
  bold?: boolean;
  iconLeft?: React.ReactNode;
  loading?: boolean;
  mobileStacked?: boolean;
  extraClasses?: any;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const variants = {
  primary: [
    'bg-lena-pink-dark hover:bg-lena-pink-darkest border border-transparent text-white',
    'disabled:hover:bg-lena-pink-dark',
  ],
  secondary: 'bg-lena2021-blue-dark border border-transparent text-white disabled:hover:bg-lena2021-blue-dark',
  white: ['bg-white text-lena-blue-dark hover:bg-lena-lightgray'],
  'white-shadow': ['bg-white text-lena-blue-dark hover:bg-lena-lightgray shadow'],
  negative: 'bg-negative hover:bg-negative-dark border border-transparent text-white',
  positive: 'bg-positive hover:bg-positive-dark border border-transparent text-white',
  orange: 'bg-lena2021-corail hover:bg-lena2021-corail-dark border border-transparent text-lena-blue-dark',
  assistant: 'bg-lena-corail border border-transparent text-white disabled:hover:bg-lena-corail',
  ghost: 'bg-transparent text-lena-blue-dark disabled:text-lena-lightgray2',
  'orange-light':
    'bg-lena2021-corail-light border border-transparent text-lena-blue-dark  disabled:hover:bg-lena2021-blue-dark',
};

const sizes = {
  sm: 'text-sm',
  normal: '',
  md: 'md:px-24 text-lg md:text-md',
  lg: 'md:px-32 text-lg',
};

const padding = {
  sm: 'px-4 py-2',
  normal: 'px-4 py-3',
};

const Button: FunctionComponent<ButtonProps> = ({
  variant,
  centered = true,
  size = 'normal',
  padding: paddingSize = 'normal',
  fullWidth = false,
  uppercase = false,
  bold = true,
  iconLeft,
  loading = false,
  mobileStacked = false,
  children,
  extraClasses,
  ...rest
}) => {
  const classes = classNames([
    'disabled:cursor-default disabled:opacity-50',
    variant && variants[variant],
    loading && 'bg-opacity-50',
    sizes[size],
    padding[paddingSize],
    fullWidth && 'w-full',
    uppercase && 'uppercase',
    bold && 'font-bold',
    mobileStacked && 'fixed bottom-0 left-0 right-0 w-full md:w-auto md:static md:rounded',
    !mobileStacked && 'rounded',
    extraClasses,
  ]);
  return (
    <button disabled={rest.disabled || loading} className={classes} {...rest}>
      <div
        className={classNames('flex flex-row items-center space-x-2', centered ? 'justify-center' : 'justify-between')}
      >
        {loading && (
          <div className="mr-1">
            <LoaderOval size={20} color={variant === 'white-shadow' ? '#1E2E58' : '#fff'} />
          </div>
        )}
        <div className="flex items-center gap-2">
          {iconLeft && iconLeft}
          {children}
        </div>
      </div>
    </button>
  );
};

export default Button;
