import React, { FC, useMemo } from 'react';
import translateExperienceType from 'utils/translateExperienceType';
import { ReactComponent as LogoDiagorienteSvg } from 'assets/logos/diagoriente.svg';
import * as _ from 'lodash-es';

export type Domain = 'personal' | 'professional' | 'voluntary' | 'engagement' | 'sport_pro' | 'sport_amateur';

type Experience = {
  id: string;
  domain: Domain | string;
  title: string;
  activities: string[];
};

type RecommendationType = {
  id: string;
  experience: { title: string };
  recommendations: {
    firstName: string;
    lastName: string;
    message: string;
    appreciation: string;
    city: string;
    organization: string;
  };
};

type Props = {
  globalCompetences: { id: string; title: string; level: number; levelDescription: string }[];
  experiences: Experience[];
  user: {
    firstName: string;
    lastName: string;
  };
  recommendations?: RecommendationType[];
};

export const SelfevalCard: FC<Props> = ({ globalCompetences, experiences, user, recommendations }) => {
  const data = _.groupBy(experiences, 'domain');

  const experiencesRemap = useMemo(() => {
    return Object.keys(data).map((key) => ({
      type: key,
      data: data[key].map((d: any) => d),
    }));
  }, [experiences]);

  const sortedCompetenceGlobales = useMemo(() => {
    return _.orderBy(globalCompetences, ['level'], ['desc']);
  }, [globalCompetences]);

  return (
    <>
      <div>
        <div className={'flex flex-col items-center gap-4 border-b pb-4 mb-4'}>
          <LogoDiagorienteSvg />
          <div className={'text-center'}>
            <span className={'font-bold'}>Carte de compétences transversales</span>
            <div className={'text-sm'}>
              {user?.firstName} {user?.lastName}
            </div>
          </div>
        </div>
        <div className={'grid grid-cols-2 gap-4'}>
          <div>
            {experiencesRemap &&
              experiencesRemap.length > 0 &&
              experiencesRemap?.map((exp) => (
                <div key={exp?.type} className={'mb-6'}>
                  <div className={'font-bold text-blue-400 uppercase mb-2'}>
                    Expériences {translateExperienceType(exp?.type).plural}
                  </div>
                  {exp?.data?.map((d: any) => (
                    <div key={d?.id} className={'mb-1 pageBreak'}>
                      <div className={'text-sm font-bold'}>{d?.title}</div>
                      {d?.activities.map((act: any) => (
                        <li key={act} className={'list-inside text-sm'}>
                          {act}
                        </li>
                      ))}
                      {d?.recommended && (
                        <div className={'flex mt-2'}>
                          <div className={'text-sm border px-2 py-1 rounded-md uppercase'}>Recommandé</div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
          </div>
          {sortedCompetenceGlobales && sortedCompetenceGlobales.length > 0 && (
            <div>
              <div className={'font-bold text-blue-400 uppercase mb-2'}>Compétences transversales</div>
              <div className={'border p-4 rounded-md flex flex-col gap-4'}>
                {sortedCompetenceGlobales.map((v) => {
                  return (
                    <div key={v.id} className={'flex gap-2 justify-between items-center pageBreak'}>
                      <div className={'text-sm'}>
                        <div className={'font-bold'}>{v.title}</div>
                        <div>{v.levelDescription}</div>
                      </div>
                      <div className={'flex-grow-0 ml-4'}>
                        <div className="h-6 w-6 rounded-full text-[12px] font-bold text-white flex justify-center items-center bg-lena-blue-dark">
                          {v.level}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      {recommendations && (
        <div className={'nextLine hidden print:block'}>
          <div className={'font-bold uppercase mb-6 border-b pb-6 text-lg'}>Recommandations</div>
          <div className={'flex flex-col gap-2'}>
            {recommendations.map((e, i) => (
              <div key={i.toString()} className={'bg-grayLight p-4 rounded-md'}>
                <div className={'font-bold mb-1'}>{e.experience.title}</div>
                <div>{e.recommendations.appreciation}</div>
                <div className={'mt-2'}>
                  {e.recommendations.city} - {e.recommendations.organization}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
