import React, { FunctionComponent } from 'react';
import { ModalProps } from 'components/design-system';

export type ModalInitParams<SubComponentProps> = {
  key: string;
  component: FunctionComponent<SubComponentProps & { onRequestClose: () => void }>;
  componentProps?: SubComponentProps;
  modalProps?: Partial<ModalProps>;
  onClose?: () => void;
};

type ModalContextData = {
  addModal: <SubComponentProps>(params: ModalInitParams<SubComponentProps>) => void;
  closeModalByKey?: (key: string) => void;
  closeAllModals?: () => void;
};

const ModalContext = React.createContext<ModalContextData>({ addModal: () => {} });

export default ModalContext;
