import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import EditorModeProvider from 'contexts/EditorModeProvider';
import createLazyComponent from 'utils/createLazyComponent';
import CVProvider from 'contexts/CVProvider';
import SkillCardV4CreateContainer from 'containers/SkillCardV4Container/SkillCardV4CreateContainer';

const SkillCardV4Container = createLazyComponent(() => import('./SkillCardV4Container'));
const SkillCardV4EditorContainer = createLazyComponent(() => import('./SkillCardV4EditorContainer'));

const SkillCardV4Route = () => {
  return (
    <Routes>
      <Route path="/" element={<SkillCardV4Container />} />
      <Route path="/creer" element={<SkillCardV4CreateContainer />} />
      <Route
        element={
          <EditorModeProvider>
            <CVProvider>
              <Outlet />
            </CVProvider>
          </EditorModeProvider>
        }
      >
        <Route path="/editeur/:cvId" element={<SkillCardV4EditorContainer />} />
      </Route>
    </Routes>
  );
};

export default SkillCardV4Route;
