import React, { useEffect, useMemo, useState } from 'react';
import Button from 'components/design-system/Button/Button';
import useGdprConsent from 'hooks/useGdprConsent';

const CookieBanner = () => {
  const gdprConsent = useGdprConsent();
  const [show, setShow] = useState(false);

  const shouldBypassConsent = useMemo(() => {
    return window.location.href.includes('__disable_gdpr_consent__');
  }, [window.location]);

  useEffect(() => {
    setShow(gdprConsent.status === 'UNKNOWN');
  }, [gdprConsent.status]);

  const handleAccept = () => {
    gdprConsent.allow();
    setShow(false);
  };

  const handleDenied = () => {
    gdprConsent.deny();
    setShow(false);
  };

  return (
    <>
      {show && !shouldBypassConsent && (
        <div
          className={'fixed bottom-3 left-3 right-3 lg:right-0 bg-white lg:w-[600px] p-3 rounded-md shadow-lg'}
          style={{ zIndex: 9999 }}
        >
          <div className={'font-bold mb-1'}>Utilisation des cookies</div>
          <p>
            Diagoriente utilise des cookies indispensables pour le bon fonctionnement du service, améliorer votre
            expérience ainsi que la mesure de performance.
          </p>
          <div className={'flex gap-4 mt-4'}>
            <Button onClick={handleDenied}>Tout refuser</Button>
            <Button variant={'secondary'} onClick={handleAccept}>
              Accepter et fermer
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBanner;
