import React, { FunctionComponent, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import UserContext from 'common/contexts/UserContext';
import 'cropperjs/dist/cropper.min.css';

// components assistant
import {
  Picture as PictureAssistant,
  Job as JobAssistant,
  Description as DescriptionAssistant,
  General as GeneralAssistant,
  Formations as FormationsAssistant,
  Keywords as KeywordsAssistant,
  ExperiencePro as ExperienceProAssistant,
  ExperiencePersonal as ExperiencePersonalAssistant,
  ExperienceSportAmateur as ExperienceSportAmateurAssistant,
  TransversalSkills as TransversalSkillsAssistant,
  TechnicalSkills as TechnicalSkillsAssistant,
  Informations as InformationsAssistant,
  Color as ColorAssistant,
  // Download as DownloadAssistant,
} from 'containers/SkillCardV4Container/components/assistant';

// components steps
import {
  // layout
  StepAvatar,
  StepFontSelect,
  StepTemplates,
  StepColors,
  StepContentOrder,
  // content
  StepExperience,
  StepCompetence,
  StepJobDesc,
  StepBio,
  StepExtraInfo,
  StepFormation,
  StepKeywords,
  StepVolunteers,
} from 'containers/SkillCardV4Container/components/steps';

import { staticThemeConfigs } from 'utils';
import { useMediaQuery } from 'hooks';

import { ThemeDomain } from 'common/requests/types';

import { allExperiences } from 'containers/SkillCardV4Container/types/template';

import { ReactComponent as PencilSvg } from 'assets/svg/modules/cv/picto_pencil.svg';
import { ReactComponent as EditSvg } from 'assets/svg/modules/cv/picto_edit.svg';
import { ReactComponent as DownloadSvg } from 'assets/svg/modules/cv/picto_download.svg';
import { ReactComponent as FileDownloadPdf } from 'assets/svg/modules/cv/downloadPdf.svg';
import { ReactComponent as FileDownloadTxt } from 'assets/svg/modules/cv/downloadTxt.svg';

import { EditorModeContext, EditorTab, ActiveStep, STEP } from './EditorModeContext';

const EditorModeProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { user } = useContext(UserContext);
  const isDesktop = useMediaQuery('lg');

  const [activeStep, setActiveStep] = useState<ActiveStep>({ tab: null, step: null });
  const [steps, setSteps] = useState<STEP[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [currentKeyStep, setCurrentKeyStep] = useState<string>('');
  const [showColumnEditor, toggleShowColumnEditor] = useState<boolean>(false);
  const [hiddenWarningHeightMax, setHiddenWarningHeightMax] = useState(false);

  const prevEditorPage = () => setCurrentStep((prevState) => prevState - 1);
  const nextEditorPage = () => setCurrentStep((prevState) => prevState + 1);

  const skills: any[] = useMemo(() => {
    return user?.skills || [];
  }, [user]);

  const volunteers: any[] = useMemo(() => {
    return user?.volunteers || [];
  }, [user]);

  // get the dynamic steps for the step `contents`
  const stepsDynamicContents = useMemo<STEP[]>(() => {
    return [
      {
        key: 'general',
        label: 'Comment faire un CV réussi ?',
        children: () => <GeneralAssistant />,
      },
      {
        key: 'job',
        label: 'Métiers, poste',
        assistant: <JobAssistant />,
        children: () => <StepJobDesc />,
      },
      {
        key: 'description',
        label: 'Description',
        assistant: <DescriptionAssistant />,
        children: () => <StepBio />,
      },
      {
        key: 'informations',
        label: 'Informations',
        assistant: <InformationsAssistant />,
        children: () => <StepExtraInfo />,
      },
      {
        key: 'formations',
        label: 'Formations',
        assistant: <FormationsAssistant />,
        children: () => <StepFormation />,
      },
      {
        key: 'keywords',
        label: 'Mots clés',
        assistant: <KeywordsAssistant />,
        children: () => <StepKeywords />,
      },
      ...Object.keys(staticThemeConfigs).map((key) => {
        const getAttributes = () => {
          const experience = allExperiences.find((_) => _.value === key);

          if (!key || !experience) return null;

          const assistants = {
            professional: <ExperienceProAssistant />,
            personal: <ExperiencePersonalAssistant />,
            sport_amateur: <ExperienceSportAmateurAssistant />,
          };

          return {
            label: experience?.render,
            assistant: (assistants as any)[key] || null,
          };
        };

        const attributes = getAttributes();

        if (!attributes) return null;

        return {
          key: `experience-${key}`,
          children: () => <StepExperience type={key as ThemeDomain} />,
          canActivate: () => skills.some((sk) => sk.domain === key),
          ...attributes,
        };
      }),
      {
        key: 'volunteers',
        label: 'Service Civique',
        canActivate: () => volunteers.length > 0,
        children: () => <StepVolunteers />,
      },
      {
        key: 'comp-technique',
        label: 'Compétences techniques',
        assistant: <TechnicalSkillsAssistant />,
        children: () => <StepCompetence type="technique" />,
      },
      {
        key: 'comp-transversal',
        label: 'Compétences transversales',
        assistant: <TransversalSkillsAssistant />,
        children: () => <StepCompetence type="transversal" />,
      },
    ]
      .filter(Boolean)
      .filter((v) => v?.canActivate === undefined || v.canActivate()) as STEP[];
  }, [skills, volunteers]);

  // generate the editorsTabs
  const editorTabs = useMemo<EditorTab[]>(() => {
    return [
      {
        key: 'design',
        type: 'step',
        label: isDesktop ? 'Mise en page' : 'Design',
        icon: PencilSvg,
        items: [
          {
            key: 'picture',
            label: 'Votre photo',
            assistant: <PictureAssistant />,
            children: () => <StepAvatar />,
          },
          {
            key: 'content_order',
            label: 'Réorganiser',
            children: () => <StepContentOrder />,
          },
          {
            key: 'templates',
            label: 'Modèles',
            children: () => <StepTemplates />,
          },
          {
            key: 'colors',
            label: 'Couleurs',
            assistant: <ColorAssistant />,
            children: () => <StepColors />,
          },
          {
            key: 'font',
            label: "Police d'écriture",
            children: () => <StepFontSelect />,
          },
        ],
      },
      {
        key: 'content',
        type: 'step',
        label: 'Contenu',
        icon: EditSvg,
        items: stepsDynamicContents,
      },
      {
        key: 'share',
        type: 'dropdown',
        label: isDesktop ? 'Téléchargement' : 'Partage',
        icon: DownloadSvg,
        items: [
          { key: 'pdf', label: 'Télécharger en PDF', icon: FileDownloadPdf },
          // { key: 'txt', label: 'Format TXT', icon: FileDownloadTxt },
        ],
      },
    ];
  }, [stepsDynamicContents, isDesktop]);

  const setCurrentStepByKey = (s: string) => {
    toggleShowColumnEditor(true);

    const selectedTab = editorTabs.filter((_) => _.items).find((_) => _.items?.find((_) => _.key === s));
    const indexStep = selectedTab?.items?.findIndex((e) => e.key === s) || 0;

    // console.log({ selectedTab, indexStep });
    setActiveStep({ tab: selectedTab?.key || '', step: s });

    if (indexStep >= 0) setCurrentStep(indexStep);
  };

  useEffect(() => {
    if (!isDesktop) return;

    toggleShowColumnEditor(true);

    if (activeStep.tab === null) {
      setActiveStep({ tab: 'content', step: '' });
    }
  }, [isDesktop]);

  useEffect(() => {
    setCurrentKeyStep(steps[currentStep]?.key);
  }, [steps, currentStep]);

  useEffect(() => {
    setSteps(editorTabs?.find((_) => _.key === activeStep?.tab)?.items || []);
  }, [activeStep, editorTabs]);

  const handleActiveStep = (key: string) => {
    setActiveStep({
      tab: key,
      step: null,
    });
  };

  return (
    <EditorModeContext.Provider
      value={{
        activeStep,
        setActiveStep,
        handleActiveStep,
        editorTabs,
        steps,
        showColumnEditor,
        toggleShowColumnEditor,
        currentStep,
        currentKeyStep,
        setCurrentStep,
        prevEditorPage,
        nextEditorPage,
        setCurrentStepByKey,
        hiddenWarningHeightMax,
        setHiddenWarningHeightMax,
      }}
    >
      {children}
    </EditorModeContext.Provider>
  );
};

export default EditorModeProvider;
