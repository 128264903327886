import React, { Suspense } from 'react';
import { Routes, useLocation, Route } from 'react-router-dom';
import UserContext from 'common/contexts/UserContext';
import useRoot from 'common/hooks/useRoot';
import { decodeUri } from 'common/utils/url';
import localforage from 'localforage';
import HomeContainer from './HomeContainer';
import PageNotFoundContainer from './PageNotFoundContainer';
import ExperienceRoute from './ExperienceContainer/ExperienceRoute';
import JO2024 from './JO2024Container/JO2024Route';
import CenterInterestRoute from './CenterInterestContainer/CenterInterestRoute';
import ProfileRoute from './ProfileContainer/ProfileRoute';
import JobsRoute from './JobsContainer/JobsRoute';
import AdvertsRoute from './AdvertsContainer/AdvertsRoute';
import RecommendationContainer from './RecommendationContainer';
import BilanReviewContainer from './BilanReviewContainer';
import RouteWrapper6 from 'components/ui/RouteWrapper6';
import AuthRoute from 'containers/AuthContainer/AuthRoute';
import SelfevalRoute from 'containers/SelfevalContainer/SelfevalRoutes';
import TutorialSystemProvider from 'contexts/TutorialSystemProvider';
import SkillCardV4Route from 'containers/SkillCardV4Container/SkillCardV4Route';

const PublicProfile = React.lazy(() => import('./PublicProfileContainer/PublicProfile'));
const BridgeContainer = React.lazy(() => import('./BridgeContainer/BridgeContainer'));
const LogoutContainer = React.lazy(() => import('./LogoutContainer/LogoutContainer'));
const ExperienceIntroContainer = React.lazy(() => import('./ExperienceContainer/ExperienceIntroContainer'));
const SkillCardV4PrintContainer = React.lazy(() => import('./SkillCardV4Container/SkillCardV4PrintContainer'));
const PublicSelfevalPrint = React.lazy(() => import('./SelfevalContainer/routes/PublicSelfevalPrint'));

const RootContainer = () => {
  const { startupEnd, user, setUser } = useRoot();
  const location = useLocation();

  // Do not remove ! UTM implementation
  const { utm_source: utmSource, utm_medium: utmMedium, utm_campaign: utmCampaign } = decodeUri(location.search);
  if (utmSource || utmMedium || utmCampaign) {
    localforage.setItem('utm', { source: utmSource, medium: utmMedium, campaign: utmCampaign });
  }

  if (!startupEnd) {
    return <div />;
  }

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <TutorialSystemProvider>
        <Suspense fallback={<div />}>
          <Routes>
            {/* Protected routes, user must be logged in */}
            <Route element={<RouteWrapper6 protect={true} authorizedRole={'user'} />}>
              <Route path="/" element={<HomeContainer />} />
              <Route path="/profil/*" element={<ProfileRoute />} />
              <Route path="/experience/*" element={<ExperienceRoute />} />
              <Route path="/jo-2024/*" element={<JO2024 />} />
              <Route path="/metiers/*" element={<JobsRoute />} />
              <Route path="/opportunites/*" element={<AdvertsRoute />} />
              <Route path="/centres_interet/*" element={<CenterInterestRoute />} />
              <Route path="/mes-cv/*" element={<SkillCardV4Route />} />
              <Route path="/ajout-exp" element={<ExperienceIntroContainer />} />
              <Route path="/evaluer-mes-competences/*" element={<SelfevalRoute />} />
              <Route path="/deconnexion" element={<LogoutContainer />} />
            </Route>

            {/* Unauthenticated routes : Login, forgot PW... if user is logged in, redirect to home */}
            <Route element={<RouteWrapper6 protect={false} unauthenticatedOnly={true} />}>
              <Route path="/*" element={<AuthRoute />} />
            </Route>

            {/* Routes accessible to anyone */}
            <Route element={<RouteWrapper6 protect={false} unauthenticatedOnly={false} />}>
              <Route path="/bridge" element={<BridgeContainer />} />
              <Route path="/profil-public/:token" element={<PublicProfile />} />
              <Route path="/recommendation" element={<RecommendationContainer />} />
              <Route path="/bilan-service-civique" element={<BilanReviewContainer />} />
            </Route>

            {/* Server routes */}
            <Route path="/_server/print-cv/:cvId/:publicToken" element={<SkillCardV4PrintContainer />} />
            <Route path="/_server/selfeval-card/:secret/:userId" element={<PublicSelfevalPrint />} />

            <Route path={'/404'} element={<PageNotFoundContainer />} />
            <Route path={'*'} element={<PageNotFoundContainer />} />
          </Routes>
        </Suspense>
      </TutorialSystemProvider>
    </UserContext.Provider>
  );
};

export default RootContainer;
