import AppLayout from 'layouts/AppLayout/AppLayout';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import AppFooter from 'layouts/AppLayout/AppFooter';

const CvLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <AppLayout>
      <div className="bg-lena-lightgray flex flex-col flex-1 overflow-auto">
        <div className="w-full flex flex-col md:relative overflow-auto scroll-thin flex-1">{children}</div>
        <AppFooter />
      </div>
    </AppLayout>
  );
};

export default CvLayout;
