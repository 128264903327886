import React, { useMemo, Fragment, useState, useRef, useEffect } from 'react';
import * as lodash from 'lodash-es';

import { Competence } from 'common/requests/types';
import { getCompetenceGroup } from 'containers/SelfevalContainer/utils';

import { ReactComponent as Check } from 'assets/svg/generic/checkmark.svg';
import classNames from 'common/utils/classNames';

type Props = {
  competenceJson: Competence[];
  competencesToEval: any[];
  competenceRatings: Record<string, number>;
  criteresRatings: Record<string, number>;
  onUpdateCompetenceRating: (competenceId: string, level: number) => void;
};

const EvaluationCompetences: React.FC<Props> = ({
  competenceJson,
  competencesToEval,
  competenceRatings,
  onUpdateCompetenceRating,
  criteresRatings,
}) => {
  const inited = useRef(false);

  const [rowsHidden, setRowsHidden] = useState<string[]>([]);
  const [rowsChecked, setRowsChecked] = useState<string[]>([]);

  const groupedCompetences = useMemo(() => {
    return lodash.groupBy(competenceJson, 'type');
  }, [competenceJson]);

  // // default hide rows except the first row
  useEffect(() => {
    if (!competencesToEval || inited.current) return;

    const countCompetencesToEval = competencesToEval.flatMap((item) => item.id);
    const criteriaformatted = countCompetencesToEval.slice(1, countCompetencesToEval.length);

    setRowsHidden(criteriaformatted);
  }, [competencesToEval]);

  useEffect(() => {
    if (!inited.current) {
      setRowsChecked(Object.keys(competencesToEval));
      if (Object.keys(competenceRatings).length > 0) {
        setRowsHidden([]);
      }

      inited.current = true;
    }
  }, [competencesToEval]);

  const colsHidden = useMemo(() => {
    const values = Object.values(criteresRatings);

    return {
      min: Math.min(...values) - 1,
      max: Math.max(...values) + 1,
    };
  }, [criteresRatings]);

  const handleClickItem = () => {
    const rowsHiddenDraft = rowsHidden;
    const withoutFirstId = rowsHiddenDraft.filter((_, i) => i !== 0);

    setRowsHidden(withoutFirstId);
  };

  const renderCompetenceItemsByType = (type: string) => {
    const groupConfig = getCompetenceGroup(type);
    if (!groupConfig) return null;

    const competencesToEvalInGroup = competencesToEval.filter((item) => item.type === type);
    if (competencesToEvalInGroup.length <= 0) return null;

    const Picto = groupConfig?.picto;

    return (
      <>
        <div className={`col-span-9 ${groupConfig.color} py-2 px-4 rounded-md inline-flex gap-4`}>
          <Picto width={25} height={25} />
          <h2 className="font-bold uppercase">{groupConfig.label}</h2>
        </div>

        {competencesToEvalInGroup.map((_) => {
          const selectedLevel = Object.keys(competenceRatings).includes(_.id);

          return (
            <>
              <div className="relative flex rounded-md items-center justify-center">
                <div className="px-2 py-8 text-sm self-center text-center">
                  <div className="font-bold uppercase text-sm">{_.title}</div>
                </div>
              </div>

              {_?.levels?.map((level: any, i: number) => {
                const isHighlighted = level.level >= colsHidden.min && level.level <= colsHidden.max;
                const isSelected = selectedLevel && competenceRatings[_.id] === level.level;

                if (rowsHidden.includes(_.id))
                  return (
                    <button
                      className={classNames(
                        `rounded-md p-2 cursor-not-allowed`,
                        isHighlighted ? 'bg-lena-lightgray2 bg-opacity-30' : 'bg-lena-lightgray bg-opacity-50',
                      )}
                    />
                  );

                return (
                  <button
                    key={i}
                    onClick={() => {
                      onUpdateCompetenceRating?.(_.id, level.level);
                      setRowsChecked(lodash.xor(rowsChecked, [_.id]));

                      handleClickItem();
                    }}
                    className={classNames(
                      `rounded-md text-sm p-2 hover:bg-lena-lightgray2 hover:bg-opacity-60`,
                      !isHighlighted ? 'text-lena-blue-dark bg-lena-lightgray' : 'bg-lena-lightgray2',
                      !isHighlighted && !isSelected && 'bg-opacity-50 text-opacity-70',
                      isHighlighted && !isSelected && 'bg-opacity-30',
                      isSelected && 'bg-lena2021-corail-light',
                    )}
                  >
                    {rowsChecked.includes(_.id) ? (
                      <div className="flex justify-center items-center">
                        {selectedLevel && competenceRatings[_.id] === level.level && <Check width={22} />}
                      </div>
                    ) : (
                      level.title
                    )}
                  </button>
                );
              })}
            </>
          );
        })}
      </>
    );
  };

  if (!inited.current) return null;

  return (
    <div className="space-y-8 flex flex-col w-full">
      <div>
        <div className="font-bold text-xl">Maintenant graduez chaque compétence en sélectionnant votre niveau</div>
      </div>

      <div className="w-full">
        <div className="grid grid-cols-9 gap-2 w-full m-auto">
          <div></div>

          {competencesToEval[0]?.levels.map((_: any, i: number) => (
            <div
              key={i}
              className="rounded-md bg-lena2021-blue-dark text-white bg-opacity-80 py-1 text-center uppercase font-bold"
            >
              Niveau {i + 1}
            </div>
          ))}

          {Object.keys(groupedCompetences).map((competenceGroupKey) => (
            <Fragment key={competenceGroupKey}>{renderCompetenceItemsByType(competenceGroupKey)}</Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EvaluationCompetences;
